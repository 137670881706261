import parse from 'html-react-parser';
import React from 'react';
import { Content } from '@next-app/interface/Common';
import classes from './view-more-text.module.scss';
import Button from '@next-app/components/elements/Button/Button';

interface HTMLProps {
  contentItem: Content;
}

const ViewMoreText = ({ contentItem }: HTMLProps) => {
  const { visibleText, hiddenText } = contentItem;

  return (
    <div className="container-fluid">
      <div
        className={`d-flex justify-content-center align-items-center flex-column ${classes['view-text-wrap']}`}
      >
        <div className={`${classes['text-content']}`}>
          <p>{visibleText}</p>
          <div
            className={`${classes['hidden-text']} collapse`}
            id="collapseViewMore"
          >
            {parse(hiddenText)}
          </div>
        </div>
        <Button
          data-testid="button-view-more"
          customClass={`${classes['view-text-button']}`}
          variant="secondary"
          data-bs-toggle="collapse"
          data-bs-target={`#collapseViewMore`}
          aria-controls={`collapseViewMore`}
        ></Button>
      </div>
    </div>
  );
};

export default ViewMoreText;
