'use client';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    s: any;
  }
}

const getCtaElem = (elem: HTMLElement | Element) => {
  if (elem.tagName === 'A' || elem.tagName === 'BUTTON') {
    return elem;
  } else {
    const closestElem = elem.closest('a, button, [s_objectid]');
    return closestElem ? closestElem : null;
  }
};

export const setActivityMapLink = (
  clickedElement: Element | HTMLElement,
  data?: {
    eVar?: string;
    events?: string;
    list1?: string;
    eVarValue?: [string];
    products?: any;
  },
  targetUrl?: string,
  isExitLink?: boolean,
  preventPinWindow?: boolean,
) => {
  // Get the closest valid CTA element (Anchor or Button)
  const clickedElem = getCtaElem(clickedElement);
  const type = targetUrl ? (isExitLink ? 'e' : 'd') : 'o';
  const s_objectid = clickedElem?.getAttribute('s_objectid');

  // Check if `s` is defined in global scope
  if (typeof window.s !== 'undefined' && typeof s_objectid !== 'undefined') {
    const s = window.s;
    s.s_objectID = s_objectid;
    // Added logic to handle prop24 and eVars
    s.prop24 = `${s_objectid}|${data?.eVar?.replace(/,/g, '|') || ''}`;

    if (data && data?.eVar) {
      const eVars = data?.eVar?.split(',');
      const linkTrackVars = s?.linkTrackVars?.split(',') || [];

      eVars.forEach((value: string, index: number) => {
        if (!linkTrackVars?.includes(value)) {
          s.linkTrackVars = `${s?.linkTrackVars},${value}`;
        }
        s[value] =
          data?.list1 ||
          (data?.eVarValue && data?.eVarValue[index]) ||
          s_objectid;
      });
    }

    if (data && data?.events) {
      if (s?.linkTrackVars && !s?.linkTrackVars?.includes('events')) {
        s.linkTrackVars = `${s.linkTrackVars},events`;
      }
      s.events = data.events;
      s.linkTrackEvents = data.events;
    }

    if (data) {
      s.list1 = data.list1 || '';
      s.products = data.products;
    }

    // Fire the tracking link (Adobe Analytics link tracking)
    setTimeout(() => {
      s.tl(clickedElem, type, s_objectid);
    }, 0);
  }

  // PDP - pinterest social share window dimensions
  if (targetUrl && !preventPinWindow) {
    if (s_objectid && s_objectid === 'pinterest') {
      window.open(targetUrl, 'signin', 'height=600,width=800');
      return false;
    } else {
      window.open(targetUrl, '_blank');
    }
  }
};
