import React, { useState, useEffect } from 'react';

export interface WithDeviceProps {
  isMobile: boolean;
  isTab: boolean;
  isWeb: boolean;
}

const getDevice = <P extends WithDeviceProps>(
  WrappedComponent: React.ComponentType<P>,
) => {
  const ResponsiveState = (props: Omit<P, keyof WithDeviceProps>) => {
    const [isMobile, setIsMobile] = useState(false);
    const [isTab, setIsTab] = useState(false);
    const [isWeb, setIsWeb] = useState(true);

    useEffect(() => {
      const handleResize = () => {
        const innerWidth = window.innerWidth;
        if (innerWidth < 450) {
          setIsMobile(true);
          setIsTab(false);
          setIsWeb(false);
        } else if (innerWidth >= 450 && innerWidth < 992) {
          setIsMobile(false);
          setIsTab(true);
          setIsWeb(false);
        } else {
          setIsMobile(false);
          setIsTab(false);
          setIsWeb(true);
        }
      };

      window.addEventListener('resize', handleResize);
      handleResize(); // Initial check on component mount

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    return (
      <WrappedComponent
        {...(props as P)}
        isMobile={isMobile}
        isTab={isTab}
        isWeb={isWeb}
      />
    );
  };

  return ResponsiveState;
};

export default getDevice;
