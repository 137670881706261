interface IRouter {
  replace: Function;
}

function redirectionPath(router: IRouter, routingPath: string) {
  window.history.pushState(null, '', routingPath);
  setTimeout(() => {
    router.replace(routingPath);
  }, 0);
}

export function windowScroll() {
  window.scroll(0, 0);
}

export default redirectionPath;
