'use client';

import Conditional from '@next-app/components/Conditional';
import { MenuItemProps } from '@next-app/interface/Common';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DesktopMenuItem from './DesktopMenuItem';
import MobileMenuItem from './MobileMenuItem';

const MenuItemHtml = (props: MenuItemProps) => {
  const {
    itemHeader,
    itemHeaderUrl,
    menuItems,
    columnMaxSize,
    showInMobileNav,
    itemType,
    parentCategoryId,
  } = props.contentItem;
  const { mobileMenuItemsArr, tabHeader } = props;

  const { deviceType } = useSelector((state: any) => state.init);

  const [isMobile, setIsMobile] = useState(false);
  const [isTab, setIsTab] = useState(false);
  const [isWeb, setIsWeb] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 450) {
        setIsMobile(true);
        setIsTab(false);
        setIsWeb(false);
      }
      if (window.innerWidth >= 450 && window.innerWidth < 992) {
        setIsMobile(false);
        setIsTab(true);
        setIsWeb(false);
      }
      if (window.innerWidth > 992) {
        setIsMobile(false);
        setIsTab(false);
        setIsWeb(true);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const mobile = deviceType && deviceType === 'mobile' ? true : false;
    setIsMobile(mobile);
  }, [deviceType]);

  const levelOneItems: any = [];
  const levelTwoItems: any = [];

  menuItems.map((item: any) => {
    if (item.type === 'allRootCategories') {
      levelOneItems.push(item);
    }

    if (item.type === 'fixedChildCategories') {
      levelTwoItems.push(item);
    }
  });

  let menuItemOne = [];
  let menuItemTwo = [];

  if (columnMaxSize && levelOneItems.length > 0) {
    menuItemOne = levelOneItems.slice(0, columnMaxSize);
    menuItemTwo = levelOneItems.slice(columnMaxSize);
  }

  const handleMenuItemOpen = (item: string) => {
    const menuItems = document.getElementById(item);
    menuItems && menuItems.classList.add('active');
  };

  const handleMenuItemOpenThree = (parentCategoryId: string) => {
    const menuItemsThree = document.getElementById(parentCategoryId);
    menuItemsThree && menuItemsThree.classList.add('show');
  };

  const handleCloseMobileSecond = (parentCategoryId: string) => {
    const menuItemsCloseButton = document.getElementById(parentCategoryId);
    menuItemsCloseButton && menuItemsCloseButton.classList.remove('show');
  };

  const handleCloseMobileThird = (level2Item: any) => {
    const menuItemsCloseIcon = document.getElementById(level2Item);
    menuItemsCloseIcon && menuItemsCloseIcon.classList.remove('active');
  };

  return (
    <>
      <Conditional if={isWeb}>
        <DesktopMenuItem
          menuItemOne={menuItemOne}
          menuItemTwo={menuItemTwo}
          menuItems={menuItems}
          itemHeader={itemHeader}
          itemHeaderUrl={itemHeaderUrl}
          columnMaxSize={columnMaxSize}
          tabHeader={tabHeader}
        />
      </Conditional>
      <Conditional if={isMobile || isTab}>
        <MobileMenuItem
          itemType={itemType}
          levelOneItems={levelOneItems}
          handleMenuItemOpen={handleMenuItemOpen}
          handleCloseMobileThird={handleCloseMobileThird}
          levelTwoItems={levelTwoItems}
          mobileMenuItemsArr={mobileMenuItemsArr}
          showInMobileNav={showInMobileNav}
          handleMenuItemOpenThree={handleMenuItemOpenThree}
          parentCategoryId={parentCategoryId}
          itemHeader={itemHeader}
          handleCloseMobileSecond={handleCloseMobileSecond}
          itemHeaderUrl={itemHeaderUrl}
          menuItems={menuItems}
          tabHeader={tabHeader}
        />
      </Conditional>
    </>
  );
};

export default MenuItemHtml;
