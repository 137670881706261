'use client';

import { MutableRefObject } from 'react';
import Modal from '../elements/Modal/Modal';
import parse from 'html-react-parser';
import { decodeHTMLEntities } from '../../utils/decodeHTMLEntities';
import classes from './success.module.scss';

interface SuccessModalProps {
  isSuccessContent: string;
  showRef: MutableRefObject<string | Element>;
}

const SuccessModal = ({ showRef, isSuccessContent }: SuccessModalProps) => {
  const successMessageContent = decodeHTMLEntities(isSuccessContent);
  return (
    <Modal
      showHeader={true}
      id="successModal"
      dialogClass={`modal-dialog ll-modal-top md ${classes['registering-success-modal']}`}
      contentClass={`modal-content ll-modal-md ${classes['modalContent']}`}
      headingTagName="4"
      modalTitle="Thank you for Registering with Lakeshore!"
      ref={showRef}
      headerClass={`${classes['modalHeader']}`}
    >
      <div>{parse(successMessageContent)}</div>
    </Modal>
  );
};

export default SuccessModal;
