import Conditional from '@next-app/components/Conditional';
import ResponsiveImage from '@next-app/components/elements/ResponsiveImage/ResponsiveImage';
import parse from 'html-react-parser';

const CarouselContentMedia = ({ contentItem, contentAlign, lazyload }: any) => {
  const isFullUrl = contentItem.mobileMediaSourceURL.indexOf('https://') < 0;
  const elemAttributes = {
    s_objectid: contentItem?.s_objectId || '',
  };

  return (
    <div {...{ s_regionid: contentItem?.s_regionId }}>
      <Conditional
        if={contentItem.promoTerms.length && contentAlign === 'above'}
      >
        <div className="col-xs-12"> {parse(contentItem.promoTerms)}</div>
      </Conditional>
      <div className="col-xs-12 pr0 pl0">
        <a
          tabIndex={0}
          className="display-block no-decor"
          id={contentItem?.s_objectId || ''}
          href={contentItem.mediaFollowURL || '#'}
          {...elemAttributes}
        >
          <ResponsiveImage
            mobileImagePath={contentItem.mobileMediaSourceURL}
            tabletImagePath={contentItem.mediaSourceURL}
            desktopImagePath={contentItem.mediaSourceURL}
            altText={contentItem.mediaAlternative || ''}
            isFullUrl={isFullUrl}
            lazyload={lazyload}
            width={1500}
            height={170}
          />
        </a>
      </div>
      <Conditional
        if={contentItem.promoTerms.length && contentAlign === 'underneath'}
      >
        <div className="col-xs-12">{parse(contentItem.promoTerms)}</div>
      </Conditional>
      <Conditional if={contentAlign === 'over'}>
        <div className="col-xs-12">{parse(contentItem.promoTerms)}</div>
      </Conditional>
    </div>
  );
};

export default CarouselContentMedia;
