import CarouselPricingDisplay from './CarouselPricingDisplay';
import Heading from '@next-app/components/elements/Heading/Heading';
import Image from 'next/image';
import RatingRecs from './RatingRecs';
import parse from 'html-react-parser';
import constants from '@next-app/constants/constants';
import { ProductRecsContentItemData } from '@next-app/interface/PageContent';
import classes from './product-recs-HTML.module.scss';
import Conditional from '@next-app/components/Conditional';

interface MobileHTMLRecommendationsProps {
  s_regionId?: string;
  atcRecsEnabled: boolean;
  gsaUser: boolean;
  productDataCarousel: any;
}

const MobileHTMLRecommendations = ({
  atcRecsEnabled,
  gsaUser,
  productDataCarousel,
  s_regionId,
}: MobileHTMLRecommendationsProps) => {
  const { STORE_PICKUP_ONLY, STORE_SPECIAL_LABEL } = constants.LLConstants;

  if (!productDataCarousel || !productDataCarousel.recsProductInfo) {
    return;
  }

  const elementAttribute = {
    s_regionid: s_regionId,
  };

  return (
    <div className={classes['slider-items']} {...elementAttribute}>
      <ul className={`${classes['item']} ${classes['show-on-mobile']}`}>
        <Conditional if={productDataCarousel?.recsProductInfo.length > 0}>
          {productDataCarousel?.recsProductInfo.map(
            (item: ProductRecsContentItemData, index: number) => {
              return (
                <li
                  className={classes.cardItem}
                  key={`mobile-rec-item-${index}`}
                >
                  <div className="">
                    <a href={item?.seoURL} className={classes['product-img']}>
                      <Image
                        src={item?.imageURL}
                        alt={item?.altText}
                        fill={true}
                        quality={100}
                        className="position-static"
                        {...{ s_objectid: item?.name }}
                      />
                      {item.storeOnly && (
                        <div className={classes['new-item-label']}>
                          <Conditional if={item.isNew && item.storeOnly}>
                            <span className={classes['new-icon']}>New</span>
                          </Conditional>
                          <Conditional if={!item.storeOnly}>
                            <span className={classes.blue}>
                              {item.storeOnly ? STORE_PICKUP_ONLY : '&nbsp;'}
                            </span>
                          </Conditional>
                        </div>
                      )}
                    </a>
                    <Heading tagName="5" customClass={classes['product-name']}>
                      <a href={item?.seoURL}>
                        {item?.isNew && !item?.storeOnly && (
                          <span className={classes['new-icon']}>New</span>
                        )}
                        {parse(item?.name)}
                      </a>
                    </Heading>
                    <CarouselPricingDisplay
                      hasSpecialPrice={item.hasSpecialPrice}
                      isOnSale={item.isOnSale}
                      hasGSAPrice={item.hasGSAPrice}
                      salePriceRange={item.salePriceRange}
                      listPriceRange={item.listPriceRange}
                      isOnGSA={item.isOnGSA}
                      atcRecsEnabled={atcRecsEnabled}
                    />
                    <Conditional if={item.specialStoreText && !gsaUser}>
                      <div className={classes['spacial-label']}>
                        <span className={`${classes.red} ${classes.fs11}`}>
                          {STORE_SPECIAL_LABEL}
                        </span>
                      </div>
                    </Conditional>
                  </div>
                  <div className={classes['rating-slider']}>
                    <RatingRecs
                      rating={item.rating}
                      reviewCount={item.reviewCount}
                      seoURL={item.seoURL}
                      atcRecsEnabled={atcRecsEnabled}
                      showReviewCount={false}
                    />
                  </div>
                </li>
              );
            },
          )}
        </Conditional>
      </ul>
    </div>
  );
};

export default MobileHTMLRecommendations;
