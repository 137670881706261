import Conditional from '@next-app/components/Conditional';
import classes from './menu-item.module.scss';
import { MobileMenuItemsInterface } from '@next-app/interface/Header';
import { resetHamburgerMenu } from '@next-app/utils/common-utils';

const MobileMenuItem = ({
  itemType,
  levelOneItems,
  handleMenuItemOpen,
  handleCloseMobileThird,
  levelTwoItems,
  mobileMenuItemsArr,
  showInMobileNav,
  handleMenuItemOpenThree,
  parentCategoryId,
  itemHeader,
  handleCloseMobileSecond,
  itemHeaderUrl,
  menuItems,
  tabHeader,
}: MobileMenuItemsInterface) => {
  return (
    <li className={`sub-menu-level-two-wrap`}>
      <Conditional if={itemType === 'all'}>
        <ul className={`${classes['sub-menu-level-two-wrap2']}`}>
          {levelOneItems.map((level2Item: any, index: number) => (
            <Conditional key={`shop-all-${index}`} if={level2Item.displayName}>
              <li className="list-item level-2-menu" key={`level-2-${index}`}>
                <a
                  onClick={() => handleMenuItemOpen(level2Item.displayName)}
                  data-testid="level-2-items"
                  className="open-menu"
                >
                  <span>{level2Item.displayName}</span>
                  <span className={classes['sprites-right-arrow-icon']}></span>
                </a>
                <div
                  id={level2Item.displayName}
                  className={`sub-menu-level-3 ${classes['mp-level']}`}
                  data-level="3"
                >
                  <div
                    className={classes['mp-back-title']}
                    onClick={() =>
                      handleCloseMobileThird(level2Item.displayName)
                    }
                  >
                    <span
                      className={classes['sprites-left-arrow-icon']}
                      data-testid="level-3-close-all"
                    ></span>
                    <span className={classes['title-text']}>
                      {level2Item.displayName}
                    </span>
                    <span
                      className={classes['close-hamburger-menu']}
                      onClick={resetHamburgerMenu}
                    >
                      <span
                        className={classes['sprites-close-icon']}
                        role="presentation"
                      ></span>
                    </span>
                  </div>

                  <ul className="mb20">
                    <li className="list-item">
                      <a href={level2Item.url} className="open-menu">
                        <span>Shop All</span>
                        <span className="sprites right-arrow-icon"></span>
                      </a>
                    </li>
                    {levelTwoItems.map((level3Item: any, index: number) => (
                      <>
                        {level3Item.parentId === level2Item.id && (
                          <li className="list-item" key={`level-3-${index}`}>
                            <a
                              href={level3Item.url}
                              className="open-menu"
                              {...{
                                s_objectid: `${tabHeader}:${level3Item.displayName}`,
                              }}
                              data-activity-map={JSON.stringify({
                                vars: {
                                  events: 'event24',
                                  eVar: 'eVar1',
                                  list1: `${tabHeader}:${level3Item.displayName}`,
                                },
                              })}
                            >
                              <span>{level3Item.displayName} </span>
                              <span className="sprites right-arrow-icon"></span>
                            </a>
                          </li>
                        )}
                      </>
                    ))}
                  </ul>
                </div>
              </li>
            </Conditional>
          ))}
        </ul>
      </Conditional>
      <Conditional if={itemType !== 'all'}>
        <>
          <Conditional if={itemType === 'age'}>
            <ul className={`${classes['sub-menu-level-two-wrap2']} mb20`}>
              {mobileMenuItemsArr.map((item: any, index: number) => {
                const text = item.displayName.split(/[()]/);
                const mainText = text[0]?.trim(); // Get the main text (before brackets)
                const subText = text[1]?.trim(); // Get the text inside the brackets

                return (
                  <li className="list-item" key={`level-3-${index}`}>
                    <a
                      href={item.url}
                      className="open-menu"
                      {...{ s_objectid: `${tabHeader}:${mainText}` }}
                      data-activity-map={JSON.stringify({
                        vars: {
                          events: 'event24',
                          eVar: 'eVar1',
                          list1: `${tabHeader}:${mainText}`,
                        },
                      })}
                    >
                      <span>
                        {mainText}
                        <span className={classes.light}> ({subText})</span>
                      </span>
                      <span className="sprites right-arrow-icon"></span>
                    </a>
                  </li>
                );
              })}
            </ul>
          </Conditional>
          <Conditional if={itemType !== 'age' && itemType !== 'grade'}>
            <>
              <Conditional if={showInMobileNav}>
                <div
                  className={`${classes['sub-menu-level-two-link-wrap']}`}
                  onClick={() => handleMenuItemOpenThree(parentCategoryId)}
                >
                  <a className="level-2-menu">
                    <span>{itemHeader} </span>
                    <span
                      className={classes['sprites-right-arrow-icon']}
                    ></span>
                  </a>
                </div>
              </Conditional>
              <div
                className={`sub-menu-third-level ${classes['sub-menu-third-level2']}`}
                id={parentCategoryId}
                data-level="3"
              >
                <div
                  className={`level-3-arrow ${classes['mp-back-title']}`}
                  onClick={() => handleCloseMobileSecond(parentCategoryId)}
                >
                  <span
                    data-testid="level-3-close"
                    className={classes['sprites-left-arrow-icon']}
                  ></span>
                  <span className={classes['title-text']}>
                    {showInMobileNav && itemHeader}
                  </span>
                  <span
                    className={classes['close-hamburger-menu']}
                    onClick={resetHamburgerMenu}
                  >
                    <span
                      className={classes['sprites-close-icon']}
                      role="presentation"
                    ></span>
                  </span>
                </div>

                <ul className={classes['level-3-ul']}>
                  <li
                    className={classes['list-item']}
                    key={`level-3-${itemHeader}`}
                  >
                    <a
                      href={itemHeaderUrl}
                      className="open-menu"
                      {...{ s_objectid: 'Shop All' }}
                      data-activity-map={JSON.stringify({
                        vars: {
                          events: 'event24',
                          eVar: 'eVar1',
                          list1: `Shop All`,
                        },
                      })}
                    >
                      <span>Shop All</span>
                      <span className="sprites right-arrow-icon"></span>
                    </a>
                  </li>
                  {menuItems.map((item: any, index: number) => (
                    <>
                      {parentCategoryId === item.parentId && (
                        <li
                          className={classes['list-item']}
                          key={`level-3-${index}`}
                        >
                          <a
                            href={item.url}
                            className="open-menu"
                            {...{
                              s_objectid: `${tabHeader}:${item.displayName}`,
                            }}
                            data-activity-map={JSON.stringify({
                              vars: {
                                events: 'event24',
                                eVar: 'eVar1',
                                list1: `${tabHeader}:${item.displayName}`,
                              },
                            })}
                          >
                            <span>{item.displayName} </span>
                            <span className="sprites right-arrow-icon"></span>
                          </a>
                        </li>
                      )}
                    </>
                  ))}
                </ul>
              </div>
            </>
          </Conditional>
        </>
      </Conditional>
    </li>
  );
};

export default MobileMenuItem;
