'use client';

import { useRouter } from 'next/navigation';
import classes from './refinement-crumbs.module.scss';
import Conditional from '@next-app/components/Conditional';
import { RefinementCrumbsProps } from '@next-app/interface/PLP';
import Link from '@next-app/components/elements/Link/Link';

const RefinementCrumbs = (props: RefinementCrumbsProps) => {
  const router = useRouter();
  const { clearAllURL = '', refinementCrumbs = [] } = props || {};

  return (
    <div className={classes['product-filter']}>
      <Conditional if={refinementCrumbs?.length > 0}>
        <span className={classes['product-filter-text']}>Filters</span>
      </Conditional>
      <div className={classes['product-filter-div-wrapper']}>
        {refinementCrumbs?.map((item: any, index: number) => (
          <div
            data-testid="product-filter-div"
            onClick={() => router.push(item?.removeAction?.link)}
            key={index}
            className={classes['product-filter-div']}
          >
            <button
              data-testid="product-filter-button"
              className={classes['product-filter-button']}
              onClick={() => router.push(item?.removeAction?.link)}
              key={index}
            >
              <Link
                dangerouslySetInnerHTML={{
                  __html: `${item?.label}`,
                }}
              />
            </button>
            <button
              data-testid="close-button"
              className={classes['product-filter-close']}
            >
              <span className={`ls-icon ${classes['icn-x-close-lgt']}`}></span>
            </button>
          </div>
        ))}
        <Conditional if={refinementCrumbs?.length > 0}>
          <Link
            className={classes['product-clear-all']}
            onClick={() => router.push(clearAllURL)}
          >
            Clear All
          </Link>
        </Conditional>
      </div>
    </div>
  );
};

export default RefinementCrumbs;
