import { findComponent, getUniqueKey } from '@next-app/utils';
import {
  IRefinementMenuCollectionProps,
  PageContentFacets,
} from '@next-app/interface/PLP';

type DynamicComponentType = React.ComponentType<{
  contentItem: PageContentFacets;
  index: number | string;
  setFilterCount?: Function;
  setUpdatedUrl?: Function;
  getFilterLabel?: Function;
  isFilter?: string;
  setIsFilter?: Function;
  setFilterLabel?: Function;
  filterLabel?: string;
  setSelectedFilter?: Function;
}>;

const RefinementMenuCollection = (props: IRefinementMenuCollectionProps) => {
  const {
    contentItem,
    setFilterCount,
    setUpdatedUrl,
    getFilterLabel,
    isFilter,
    setIsFilter,
    setFilterLabel,
    filterLabel,
    setSelectedFilter,
  } = props;

  const ComponentList = contentItem?.contents?.map(
    (item: PageContentFacets, index: number) => {
      const DynamicComponent = findComponent(item) as DynamicComponentType;
      return (
        <DynamicComponent
          contentItem={item}
          index={index}
          key={getUniqueKey(index, item.name)}
          setFilterCount={setFilterCount}
          setUpdatedUrl={setUpdatedUrl}
          getFilterLabel={getFilterLabel}
          setIsFilter={setIsFilter}
          isFilter={isFilter}
          setFilterLabel={setFilterLabel}
          filterLabel={filterLabel}
          setSelectedFilter={setSelectedFilter}
        />
      );
    },
  );

  return ComponentList;
};

export default RefinementMenuCollection;
