import React from 'react';
import classes from './pdp-carousels.module.scss';
import Conditional from '@next-app/components/Conditional';
import Button from '@next-app/components/elements/Button/Button';
import AddToCart from '@next-app/components/PDP/AddToCart/AddToCart';
import { isPresellDateInFuture } from '@next-app/utils/PDPHelper';

const PDPCarouselButton = ({
  product,
  customClasses,
  recommendationTitle,
  carouselId,
  mboxid,
}: {
  product: any;
  customClasses?: string;
  recommendationTitle?: string;
  carouselId?: string;
  mboxid?: string;
}) => {
  const { productId = '', skuInfo = {} } = product || {};

  const {
    multiSku = false,
    persEnabled = false,
    skuTypes = '',
  } = skuInfo || {};

  const handleButtonClick = (isChooseOptions: boolean) => {
    if (isChooseOptions) {
      window.location.href = product?.seoURL;
    }
  };

  const updateProductForAddToCart = (product: any) => {
    const modifiedProduct = {
      ...product,
      id: productId,
      repositoryId: productId,
    };
    return modifiedProduct;
  };

  const handleShowButton = () => {
    if (skuInfo) {
      return !(
        multiSku ||
        skuTypes.indexOf('eGiftcard') !== -1 ||
        skuTypes.indexOf('giftcard') != -1 ||
        persEnabled
      );
    }
  };

  return (
    <div
      className={`${classes.ll_btn_container} ${customClasses}`}
      key={`chooseOption${productId}`}
    >
      <Conditional if={!handleShowButton()}>
        <div className={classes['action-btn-wrp']}>
          <Button
            data-testid="choose_options_btn"
            customClass={`${classes['add-to-cart-btn']}`}
            onClick={() => handleButtonClick(true)}
          >
            Choose Options
          </Button>
        </div>
      </Conditional>

      <Conditional
        if={
          !!handleShowButton() && !isPresellDateInFuture(product?.presellDate)
        }
      >
        <div className={classes['action-btn-wrp']}>
          <AddToCart
            productId={productId}
            selectedSku={updateProductForAddToCart(product)}
            carouselsName={recommendationTitle}
            carouselId={carouselId}
            mboxid={mboxid}
          />
        </div>
      </Conditional>
    </div>
  );
};

export default PDPCarouselButton;
