'use client';

import { useEffect, useState, memo } from 'react';

import classes from './added-to-cart.module.scss';
import Link from '@next-app/components/elements/Link/Link';
import AddedToCartRecommendations from './AddedToCartRecommendations';
import { AddToCartResponse } from '@next-app/interface/PDP/PDPAddToCart';
import AddedToCartItemDetails from './AddedToCartItemDetails';
import { ShowModalProps } from '@next-app/interface/Common';
import Conditional from '@next-app/components/Conditional';
import DynamicModal from '@next-app/components/elements/DynamicModal/DynamicModal';
import {
  algonomyScript,
  fetchRecsProductInfo,
  findObject,
} from '@next-app/utils/PDPHelper';
import { isBrowser } from '@next-app/utils/common-utils';
import { useDispatch, useSelector } from 'react-redux';
import { isNotEmptyObject } from '@next-app/components/shared/ProductDetails/helper';
import { CarouselProducts } from '@next-app/interface/PDP/PDPRecentlyViewedProducts';

interface AddedToCartModalProps {
  productId: string;
  addToCartData: AddToCartResponse | undefined;
  id?: string;
  isCarousel?: string;
  openRegistryModal?: Function | undefined;
}
interface AddedToCartRecommendationsReq {
  recsProductInfo: CarouselProducts[] | [];
  title: string | '';
}

const AddedToCartModal = (props: AddedToCartModalProps & ShowModalProps) => {
  const { productId } = props;
  const dispatch = useDispatch();
  const stateData = useSelector((state: any) => state.init);
  const { sessionInfo, init } = stateData;
  const { dynSess: { dynSessConfNumber = '' } = {} } =
    sessionInfo?.sessionInfo || {};
  const { addToCartData, id, isCarousel } = props || {};
  const [isScriptLoad, setIsScriptLoad] = useState(false);
  const [scriptData, setScriptData] = useState<
    AddedToCartRecommendationsReq[] | []
  >();

  const {
    subTotalInfo,
    order: { totalCommerceItemCount = 0 } = {},
    commerceItems,
  } = addToCartData || {};

  let propsData;
  if (isBrowser()) {
    propsData = (window as any).algonomyPropsData;
  }
  const [algonomyPropsData, setAlgonomyPropsData] = useState(propsData || []);

  const RecsProduct = findObject(
    addToCartData?.addedToCartModalContent,
    'PageContent-ProductRecs',
  );

  useEffect(() => {
    if (
      Object.keys(init).length &&
      Object.keys(sessionInfo).length &&
      isBrowser()
    ) {
      const { algonomyAPIKey, algonomyBaseURL } = init.initResponse.recsInfo;
      algonomyScript(
        algonomyPropsData,
        algonomyAPIKey,
        algonomyBaseURL,
        dynSessConfNumber,
      );
    } else {
      console.error('Algonomy script is already loaded');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScriptLoad, algonomyPropsData]);

  useEffect(() => {
    const modalCarousel = async () => {
      const modalCarouselRes = await fetchRecsProductInfo(
        RecsProduct[0]?.recommendedProducts,
        RecsProduct[0]?.mboxid,
        RecsProduct[0]?.recommendationTitle,
        dispatch,
        dynSessConfNumber,
      );
      setScriptData(modalCarouselRes);
    };
    if (
      Object.keys(init).length &&
      Object.keys(sessionInfo).length &&
      RecsProduct?.length
    ) {
      modalCarousel();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, sessionInfo]);

  useEffect(() => {
    if (props.showModal) {
      window.scrollTo(0, 0);
      document.body.style.position = 'fixed';
      document.body.style.right = '0px';
      document.body.style.left = '0px';
    }
  }, [props.showModal]);

  return (
    <Conditional if={props.showModal}>
      <DynamicModal
        modalTitle={
          <div className={classes.modalHeading}>
            Added to Cart
            <span className={classes['shopping-links']}>
              <Link
                href="#"
                data-bs-dismiss="modal"
                aria-label="Close"
                className="d-none d-sm-inline"
                id="productCartModal"
              >
                {`Continue Shopping`}
              </Link>
              <span className={`d-none d-sm-inline ${classes['divider']}`}>
                |
              </span>
              <Link href="/shopping-cart/">{`View Cart (${totalCommerceItemCount})`}</Link>
            </span>
          </div>
        }
        modalContentClass={`${classes.modalContent} ll-modal-top`}
        onModalClose={() => {
          props.setShowModal(false);
          document.body.style.position = '';
        }}
      >
        <div className={classes['modal-content-wrapper']}>
          <AddedToCartItemDetails
            productId={productId}
            addedCommerceItemsInfo={commerceItems}
            subTotalInfo={subTotalInfo}
            isCarousel={isCarousel}
            addToCartData={addToCartData}
            openRegistryModal={props.openRegistryModal}
          />
          <Conditional if={isNotEmptyObject(scriptData)}>
            <AddedToCartRecommendations
              items={scriptData?.[0]}
              id={id || productId}
            />
          </Conditional>
        </div>
      </DynamicModal>
    </Conditional>
  );
};

export default memo(AddedToCartModal);
