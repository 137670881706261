import Link from '@next-app/components/elements/Link/Link';
import Modal from '@next-app/components/LoginFlyOut/LogInFlyout';
import { MenuItem } from '@next-app/interface/Navigation';
import StoreSection from './StoreSection';
import RewardSection from './RewardSection';
import CardSection from './CartSection';
import classes from './account-menu-link.module.scss';
import Conditional from '@next-app/components/Conditional';
import { addTrailingSlash } from '@next-app/utils/common-utils';

interface AccountMenuLinkProps {
  contentItem: MenuItem;
}

export default async function AccountMenuLink(props: AccountMenuLinkProps) {
  const { type, link } = props.contentItem;

  const linkUrl = addTrailingSlash(link.href);

  return (() => {
    switch (type) {
      case 'default': {
        return (
          <>
            <Conditional if={link.classAttributes === 'rewards-icon'}>
              <RewardSection
                link={link}
                linkUrl={linkUrl}
                linkClass={link.classAttributes}
              />
            </Conditional>
            <Conditional if={link.classAttributes !== 'rewards-icon'}>
              <li className="default">
                <span className={link.classAttributes}></span>
                <Link
                  tabIndex={0}
                  className="header-link"
                  data-toggle="modal"
                  aria-label={link.name}
                  href={linkUrl}
                  s_objectid={link?.s_objectId}
                >
                  {link.name}
                </Link>
              </li>
            </Conditional>
          </>
        );
      }

      case 'findAStore': {
        return (
          <li className="store-li f-enabled">
            <StoreSection link={link} linkUrl={linkUrl} type={type} />
          </li>
        );
      }

      case 'rewards': {
        return (
          <li className={`${classes['rewards-li']} rewardItem`}>
            <RewardSection link={link} linkUrl={linkUrl} />
          </li>
        );
      }

      case 'search': {
        return (
          <li className="default">
            <span className="store-icon"></span>
            <div className="link-wrapper">
              <Link tabIndex={0} href={linkUrl} s_objectid={link?.s_objectId}>
                {link.name}
              </Link>
            </div>
          </li>
        );
      }

      case 'signIn': {
        return (
          <li className={classes['signin-li']}>
            <div className={classes['link-wrapper']}>
              <Modal {...link} />
            </div>
          </li>
        );
      }

      case 'shoppingCart': {
        return (
          <li className={`${classes['cart-li']} empty-cart`} tabIndex={-1}>
            <CardSection {...link} />
          </li>
        );
      }
    }
  })();
}
