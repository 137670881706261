'use client'; // Client Component

import Link from '@next-app/components/elements/Link/Link';
import HTMLReactParser from 'html-react-parser';
import SelectedStoreComponent from './StoreModal';
import { SessionInformation } from '@next-app/interface/SessionInformation/index';
import { useSelector } from 'react-redux';
import classes from './store-sestion.module.scss';

export default function StoreSection(props: any) {
  const { type, link, linkUrl } = props;
  const popoverStoreData =
    type === 'findAStore' ? HTMLReactParser(`${link?.popoverContent}`) : '';
  const { sessionInfo } = useSelector((state: any) => state.init);

  if (!Object.keys(sessionInfo).length) {
    return;
  }

  const { profile, cartInfo, dynSess }: SessionInformation =
    sessionInfo?.sessionInfo || {};

  const { selectedStore }: any = profile;
  const { dynSessConfNumber }: any = dynSess;
  const isStoreData = Object.keys(selectedStore).length > 0;

  return (
    <>
      <span className="store-icon"></span>
      {isStoreData && <>Your Store: </>}
      <div className="link-wrapper">
        <Link
          className={`${isStoreData ? classes['header-link-store'] : ''}`}
          tabIndex={0}
          href={linkUrl}
          s_objectid={link?.s_objectId}
        >
          {isStoreData
            ? `${selectedStore.name}, ${selectedStore.state}`
            : link.name}
        </Link>
        {isStoreData && (
          <div className="storePopoverWrapper">
            <div className={classes.storePopover}>
              <div className={classes.storePopoverIcon}></div>
              <SelectedStoreComponent
                profile={profile}
                dynSessConfNumber={dynSessConfNumber}
                cartInfo={cartInfo}
                sessionInfo={sessionInfo.sessionInfo}
              />
              {popoverStoreData}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
