const getClassesByTabHeader = (label: string) => {
  switch (label) {
    case 'Shop All':
      return ['col-sm-4 col-md-5', 'col-xl-6 col-md-7'];
    case 'Shop by Age':
      return ['col-md-3 col-xl-2', 'col-xs-auto'];
    case 'Furniture':
      return ['col-md-3', 'col-sm-6 full-width-images'];
    case 'Learn':
      return ['col-md-3'];
    case 'Play':
      return ['col-md-3'];
    case 'Arts & Crafts':
      return ['col-md-3', 'col-xl-6 col-md-7'];
    case 'Teaching Supplies':
      return ['col-md-3', 'col-md-6'];
    case 'Featured':
      return ['col-md-3', 'col-xl-6 col-md-6'];
    case 'Sale':
      return ['col-md-3', 'col-xl-6 col-md-7'];
    default:
      return ['col-md-3', 'col-xl-6 col-md-7'];
  }
};

export default getClassesByTabHeader;
