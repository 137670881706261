import { useState, useEffect } from 'react';
import constants from '@next-app/constants/constants';
import classes from './product-recs-HTML.module.scss';
import Conditional from '@next-app/components/Conditional';

interface RatingRecsProps {
  rating: number;
  reviewCount: number;
  seoURL: string;
  atcRecsEnabled: boolean;
  showReviewCount: boolean;
}

const RatingRecs = ({
  rating,
  reviewCount,
  seoURL,
  atcRecsEnabled,
  showReviewCount,
}: RatingRecsProps) => {
  const [isFurnitureTab] = useState(false);
  const [ratingEnabled, setRatingEnabled] = useState(isFurnitureTab);
  const [ratings, setRatings] = useState(0);
  const [ratingWidth, setRatingWidth] = useState(0);
  const [showStarsNoReview] = useState(true);

  const { STAR_PXL_WIDTH } = constants.LLConstants;

  useEffect(() => {
    if (rating != null) {
      setRatings(rating);
    }
    if (rating > 0) {
      const width = STAR_PXL_WIDTH * rating;
      setRatingWidth(() => width);
    }
    if (ratingEnabled != null) {
      setRatingEnabled(() => !isFurnitureTab && !ratingEnabled);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Conditional if={ratingEnabled && ratings > 0}>
      <>
        {(ratings > 0 || showStarsNoReview) && (
          <span className={classes.stars}>
            <span
              className={classes.gold}
              style={{ width: `${ratingWidth}px` }}
            ></span>
          </span>
        )}
        {reviewCount !== undefined && showReviewCount && (
          <span className={classes.pdp_reviewcount}>
            <a href={seoURL}>({reviewCount})</a>
          </span>
        )}

        {atcRecsEnabled && rating == 0 && <p></p>}
      </>
    </Conditional>
  );
};

export default RatingRecs;
