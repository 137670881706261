import { isNotEmptyObject } from '@next-app/components/shared/ProductDetails/helper';

export const selectInitState = (state: any) => state.init;

export const selectSessionInfo = (state: any) => {
  return selectInitState(state)?.sessionInfo?.sessionInfo;
};

export const selectIsSessionInfoAvailable = (state: any) => {
  return isNotEmptyObject(selectSessionInfo(state));
};

export const selectUserProfile = (state: any) => {
  return selectSessionInfo(state)?.profile;
};

export const selectUserProfileId = (state: any) => {
  return selectUserProfile(state)?.id;
};

export const selectDeviceType = (state: any) => {
  return selectInitState(state).deviceType;
};

export const selectInitResponse = (state: any) => {
  return selectInitState(state).init.initResponse;
};

export const selectDynSess = (state: any) => {
  return selectSessionInfo(state)?.dynSess?.dynSessConfNumber;
};

export const selectIsGSAUser = (state: any) => {
  return selectUserProfile(state)?.gsaUser || false;
};

export const selectFeatureToggles = (state: any) => {
  return selectSessionInfo(state)?.featureToggles;
};

export const selectSelectedStore = (state: any) => {
  return selectUserProfile(state)?.selectedStore;
};

export const selectIsStoreSelected = (state: any) => {
  return isNotEmptyObject(selectSelectedStore(state));
};

export const selectScene7ImageUrl = (state: any) => {
  const { scene7BaseURL, scene7Env, sceneSevenImageUrlPrefix } =
    selectInitResponse(state) || {};
  if (scene7BaseURL && scene7Env && sceneSevenImageUrlPrefix) {
    return `${scene7BaseURL}/${sceneSevenImageUrlPrefix}/${scene7Env}`;
  } else {
    return '';
  }
};

export const selectScene7ContentUrl = (state: any) => {
  const { scene7BaseURL, scene7Env, sceneSevenContentUrlPrefix } =
    selectInitResponse(state) || {};
  if (scene7BaseURL && scene7Env && sceneSevenContentUrlPrefix) {
    return `${scene7BaseURL}/${sceneSevenContentUrlPrefix}/${scene7Env}`;
  } else {
    return '';
  }
};

export const selectLoginStatus = (state: any) => {
  return selectUserProfile(state)?.loginStatus;
};

export const selectCartInfo = (state: any) => {
  return selectSessionInfo(state)?.cartInfo;
};

export const selectPayPalInfo = (state: any) => {
  return selectInitResponse(state)?.payPalInfo;
};
