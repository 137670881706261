import GetAlgonomyData from './GetAlgonomyData';
import { ProductRecsContent } from '@next-app/interface/PageContent';
interface ProductRecsProps {
  contentItem: ProductRecsContent;
}

const ProductRecs = ({ contentItem }: ProductRecsProps) => {
  const {
    mboxid,
    recommendedProducts,
    showTitle,
    recommendationTitle,
    page,
    s_regionId,
  } = contentItem;

  const propsData = {
    placementId: mboxid,
    cartRecommProducts: recommendedProducts,
    showTitle,
    recommendationTitle,
    page,
    s_regionId,
  };

  return <GetAlgonomyData propsData={propsData} />;
};

export default ProductRecs;
