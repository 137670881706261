import { ITextProps } from '@next-app/interface/PLP';
import classes from './text.module.css';

const Text = (props: ITextProps) => {
  const {
    contentItem: { value },
  } = props;

  return (
    <p
      className={classes.seoText}
      dangerouslySetInnerHTML={{ __html: `${value}` }}
    ></p>
  );
};

export default Text;
