import { findComponent } from '@next-app/utils';
import { MenuGroupSlotProps } from '@next-app/interface/Common';

const MenuGroupSlot = (props: MenuGroupSlotProps) => {
  const { contents } = props.contentItem;
  const { tabHeader = '' } = props;

  const ComponentList = contents.map((item, index: number) => {
    const Component = findComponent(item);
    if (!Component) return null;

    return <Component contentItem={item} tabHeader={tabHeader} key={index} />;
  });

  return ComponentList;
};

export default MenuGroupSlot;
