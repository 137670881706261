'use client';

import Paginations from '../Pagination/Pagination';
import classes from './product-card.module.scss';
import { useRouter } from 'next/navigation';
import { BaseSyntheticEvent, useEffect } from 'react';
import { IProductCardHandlerProps, ISortItems } from '@next-app/interface/PLP';
import redirectionPath from '@next-app/utils/pathRedirectionAndScroll';
import { setCookie } from '@next-app/utils/cookies';
import {
  COOKIE_EXPIRY_TIME,
  RecsPerPage,
  SortBy,
} from '@next-app/constants/constants';
import { dyEvent } from '@next-app/utils/dy-event';
import CustomSelectOption from '@next-app/components/elements/CustomSelectOption/CustomSelectOption';

const ProductCardHandler = ({
  sortOptions,
  items,
  totalNumRecs,
  recsPerPage,
  lastRecNum,
  filtersLength,
  pagingActionTemplate,
  availabilityCount,
}: IProductCardHandlerProps) => {
  const router = useRouter();
  const handleRoute = (path: string) => {
    redirectionPath(router, path);
  };

  const handleSort = (selectedVal: string) => {
    const val = selectedVal;
    const properties = {
      dyType: 'sort-items-v1',
      sortBy: val,
      sortOrder: 'ASC',
    };
    dyEvent({ properties: properties, eventName: 'Sort Items' });

    setCookie('sort', SortBy[val], COOKIE_EXPIRY_TIME);
    const item = sortOptions.find((item) => item.label === val) || {
      link: '',
    };
    handleRoute(item?.link ?? '');
  };

  const handleItems = (selectedVal: string) => {
    const val = selectedVal;
    setCookie('num', RecsPerPage[val]?.toString(), COOKIE_EXPIRY_TIME);
    const item = items.find((item) => item.label === val) || { link: '' };
    handleRoute(item?.link ?? '');
  };

  const selectedSortVal = sortOptions.find((item) => item.selected);
  const selectedPageVal = items.find((item) => item.selected);

  useEffect(() => {
    setCookie('num', recsPerPage.toString(), COOKIE_EXPIRY_TIME);
    const selectedSort = sortOptions.find((item: ISortItems) => item.selected);

    if (selectedSort) {
      setCookie('grid', SortBy[selectedSort.label], COOKIE_EXPIRY_TIME);
    } else {
      setCookie('sort', 'best', COOKIE_EXPIRY_TIME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`row ${classes['product-card-handler']} ${filtersLength ? classes['active-filter'] : ''}`}
    >
      <div
        className={`col-md-4 ${classes['sort-handler']} ${availabilityCount === 0 ? classes['avaialbility-bottom'] : ''}`}
      >
        <span className={`${classes['filter-text']}`}></span>
        <CustomSelectOption
          options={sortOptions}
          defaultValue={
            selectedSortVal !== undefined
              ? selectedSortVal?.label
              : sortOptions[0]?.label
          }
          onChangeHandler={handleSort}
          customClass={classes['select-option']}
          customButtonClass={classes['select-opt-button']}
        />
      </div>
      <div className={`col-md-4 ${classes['item-handler']} text-center`}>
        <span>ITEMS:</span>
        <CustomSelectOption
          options={items}
          defaultValue={
            selectedPageVal !== undefined
              ? selectedPageVal?.label
              : items[0]?.label
          }
          onChangeHandler={handleItems}
          customClass={classes['select-option']}
          customButtonClass={classes['select-opt-button']}
        />
      </div>
      <div className={`col-md-4 ${classes['top-side-pagination']}`}>
        <Paginations
          totalNumRecs={totalNumRecs}
          recsPerPage={recsPerPage}
          lastRecNum={lastRecNum}
          pagingActionTemplate={pagingActionTemplate}
        />
      </div>
    </div>
  );
};

export default ProductCardHandler;
