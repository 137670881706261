'use client';

import Input from '../elements/Input/Input';
import classes from './create-account.module.scss';
import { useEffect, useRef, useState } from 'react';
import { createModalNames } from '@next-app/config/elementNameConfig';
import Heading from '../elements/Heading/Heading';
import { useSelector } from 'react-redux';
import Modal from '../elements/Modal/Modal';
import RadioButton from '../elements/RadioButton/RadioButton';
import Checkbox from '../elements/Checkbox/Checkbox';
import Image from 'next/image';
import logoIcon from '../../../public/assets/images/rewards-ll-logo-blue.svg';
import Button from '../elements/Button/Button';
import { decodeHTMLEntities } from '../../../app/utils/decodeHTMLEntities';
import GSAUserModal from '../GSAUserModal/GSAUserModal';
import { createAccountUser, GSAUser } from '../../utils/authenticationUser';
import parse from 'html-react-parser';
import { useDispatch } from 'react-redux';
import { setData } from '@next-app/lib/store';
import { CreateAccountDTMEvent } from '@next-app/utils/dtm-event';
import SuccessModal from '../SuccessModal/SuccessModal';
import Conditional from '@next-app/components/Conditional';
import Link from '@next-app/components/elements/Link/Link';
import { getUniqueKey } from '@next-app/utils';
import { getUnformattedNumber } from '@next-app/utils/common-utils';
import { dyEvent } from '@next-app/utils/dy-event';
import { useSearchParams, useRouter } from 'next/navigation';
import urlMapping from '@next-app/config/urlMapping';

const CreateAccountModal = () => {
  const ref = useRef<string | Element>('');
  const showRef = useRef<string | Element>('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [isEmailChecked, setIsEmailChecked] = useState(true);
  const [isSignInChecked, setIsSignInChecked] = useState(false);
  const [isGsaUser, setIsGsaUser] = useState(false);
  const [password, setPassword] = useState('');
  const [isSectionVisible, setIsSectionVisible] = useState(false);
  const [popOverStaySigned, setPopOverStaySigned] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [isSuccessContent, setIsSuccessContent] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const router = useRouter();
  const [postalCode, setPostalCode] = useState('');
  const searchParams = useSearchParams();
  const dest_url = searchParams.get('dest_url') || '';

  const [error, setErrors] = useState({
    emailError: '',
    firstNameError: '',
    lastNameError: '',
    passwordError: '',
    useShopForError: '',
    postalCodeError: '',
    phoneNumberError: '',
    termsAcceptedError: '',
    duplicateMailError: '',
    invalidDomainError: '',
  });
  const [showPopOverContent, setShowPopOverContent] = useState(false);
  const [validations, setValidations] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
    noSpaces: false,
  });

  const dispatch = useDispatch();

  const byRegistryText = createModalNames.byRegistry;
  const phoneNumberText = createModalNames.phoneNumber;

  const initial = useSelector((state: any) => state.init);
  const { sessionInfo, init } = initial;
  if (!Object.keys(sessionInfo).length || !init || !init.initResponse) {
    return;
  }

  const matchedCriteriaCount =
    Object.values(validations).filter(Boolean).length;

  const handleFocus = () => {
    setIsSectionVisible(true);
    setPopOverStaySigned(true);
    if (matchedCriteriaCount < 1 && !password) {
      document.addEventListener('mousedown', handleClickOutside);
    }
  };

  const handleClickOutside = () => {
    if (matchedCriteriaCount < 1 && !password) {
      setIsSectionVisible(false);
    } else {
      setIsSectionVisible(true);
    }
    setPopOverStaySigned(false);
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (password) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validations]);

  const handleCloseTooltip = () => {
    setIsSectionVisible(false);
  };

  const handleCheckboxChange = (checked: boolean) => {
    setIsChecked(checked);
  };

  const handleEmailCheckBoxChange = (checked: boolean) => {
    setIsEmailChecked(checked);
  };

  const handleSignInCheckboxChange = (checked: boolean) => {
    setIsSignInChecked(checked);
  };

  const handleGsaUserChange = (checked: boolean) => {
    setIsGsaUser(checked);
  };

  const handleRadioChange = (value: string) => {
    setSelectedValue(value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (e: any) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
    if (newPassword) {
      setIsSectionVisible(true);
    }
  };

  const validatePassword = (password: any) => {
    const validations = {
      length: password.length >= 10,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /\d/.test(password),
      specialChar: /[~!@#$%^*()\-_=+|;:,.?]/.test(password),
      noSpaces: password ? !/\s/.test(password) : false,
    };
    setValidations(validations);
  };

  const getPasswordStrength = () => {
    if (matchedCriteriaCount === 6) {
      return {
        text: 'Strong',
        color: 'green',
        percentage: 100,
        borderRadius: '5px',
      };
    } else if (matchedCriteriaCount >= 5) {
      return {
        text: 'Moderate',
        color: 'orange',
        percentage: 66,
        borderRadius: '5px 0 0 5px',
      };
    } else if (matchedCriteriaCount >= 2) {
      return {
        text: 'Poor',
        color: 'red',
        percentage: 33,
        borderRadius: '5px 0 0 5px',
      };
    } else {
      return { text: '', color: '#020202', percentage: 0 };
    }
  };

  const passwordStrength = getPasswordStrength();

  const showPopOverTooltip = () => {
    setShowPopOverContent(!showPopOverContent);
  };

  const closePopOverTooltip = () => {
    setShowPopOverContent(false);
  };

  const rewardsEnabled =
    sessionInfo?.sessionInfo?.featureToggles?.rewardsEnabled;

  const isAnyValidationMet = Object.values(validations).some(Boolean);

  const handleValidation = (formExceptions: any) => {
    const isEmailError = formExceptions.find(
      (el: any) =>
        el.propertyPath === 'email' || el.propertyPath === 'gsaEmail',
    );
    const isFirstNameError = formExceptions.find(
      (el: any) => el.propertyPath === 'firstName',
    );

    const isLastNameError = formExceptions.find(
      (el: any) => el.propertyPath === 'lastName',
    );

    const isPasswordError = formExceptions.find(
      (el: any) => el.propertyPath === 'password',
    );

    const isUseShopForError = formExceptions.find(
      (el: any) => el.propertyPath === 'userShopFor',
    );

    const isPostalCodeError = formExceptions.find(
      (el: any) => el.propertyPath === 'postalCode',
    );
    const isTermsAcceptedError = formExceptions.find(
      (el: any) => el.propertyPath === 'termsAccepted',
    );

    const isPhoneNumberError = formExceptions.find(
      (el: any) => el.propertyPath === 'phoneNumber',
    );

    const isDuplicateMailError = formExceptions.find(
      (el: any) => el.propertyPath === 'isDuplicated',
    );
    const isInvalidDomainError = formExceptions.find(
      (el: any) => el.propertyPath === 'gsaInvalidDomain',
    );

    if (isEmailError) {
      setErrors((prev) => ({
        ...prev,
        emailError: decodeHTMLEntities(isEmailError.localizedMessage),
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        emailError: '',
      }));
    }

    if (isFirstNameError) {
      setErrors((prev) => ({
        ...prev,
        firstNameError: isFirstNameError.localizedMessage,
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        firstNameError: '',
      }));
    }

    if (isLastNameError) {
      setErrors((prev) => ({
        ...prev,
        lastNameError: isLastNameError.localizedMessage,
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        lastNameError: '',
      }));
    }

    if (isPasswordError) {
      setErrors((prev) => ({
        ...prev,
        passwordError: isPasswordError.localizedMessage,
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        passwordError: '',
      }));
    }

    if (isUseShopForError) {
      setErrors((prev) => ({
        ...prev,
        useShopForError: isUseShopForError.localizedMessage,
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        useShopForError: '',
      }));
    }

    if (isPostalCodeError) {
      setErrors((prev) => ({
        ...prev,
        postalCodeError: isPostalCodeError.localizedMessage,
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        postalCodeError: '',
      }));
    }

    if (isPhoneNumberError) {
      setErrors((prev) => ({
        ...prev,
        phoneNumberError: isPhoneNumberError.localizedMessage,
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        phoneNumberError: '',
      }));
    }

    if (isTermsAcceptedError) {
      setErrors((prev) => ({
        ...prev,
        termsAcceptedError: isTermsAcceptedError.localizedMessage,
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        termsAcceptedError: '',
      }));
    }

    if (isDuplicateMailError) {
      setErrors((prev) => ({
        ...prev,
        duplicateMailError: decodeHTMLEntities(
          isDuplicateMailError.localizedMessage,
        ),
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        duplicateMailError: '',
      }));
    }
    if (isInvalidDomainError) {
      setErrors((prev) => ({
        ...prev,
        invalidDomainError: isInvalidDomainError.localizedMessage,
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        invalidDomainError: '',
      }));
    }
  };

  const dynSessConfNumber =
    sessionInfo?.sessionInfo?.dynSess?.dynSessConfNumber;

  const handleSubmit = async () => {
    setLoading(true);

    if (isGsaUser) {
      const gsaUserResponse: any = await GSAUser({
        email,
        password,
        firstName,
        lastName,
        emailOptIn: isEmailChecked,
        staySignedIn: false,
        userShopFor: selectedValue,
        sessionRestored: false,
        sessionConfirmationNumber: dynSessConfNumber,
      });

      if (gsaUserResponse.formError) {
        const { formExceptions } = gsaUserResponse;
        await handleValidation(formExceptions);
        setLoading(false);
      } else {
        const { message = {} } = gsaUserResponse || {};
        const { successMessage } = message || {};
        setIsSuccessContent(successMessage);
        setLoading(false);

        const modalInstance = window.bootstrap.Modal.getInstance(ref.current);
        if (modalInstance) {
          modalInstance.hide();
        }

        const newModal = new window.bootstrap.Modal(showRef.current);
        newModal.show();
        await setErrors({
          emailError: '',
          firstNameError: '',
          lastNameError: '',
          passwordError: '',
          useShopForError: '',
          postalCodeError: '',
          invalidDomainError: '',
          termsAcceptedError: '',
          phoneNumberError: '',
          duplicateMailError: '',
        });
      }
    } else {
      const authIntegrationInfoUrl: string = `${init?.initResponse?.authIntegrationInfo?.baseURL}token`;
      const createAccountUserResponse: any = await createAccountUser({
        email,
        password,
        firstName,
        lastName,
        phoneNumber: getUnformattedNumber(phoneNumber),
        emailOptIn: isEmailChecked,
        userShopFor: selectedValue,
        termsAccepted: isChecked,
        staySignedIn: isSignInChecked,
        sessionConfirmationNumber: dynSessConfNumber,
        postalCode,
        confirmPassword: '',
        authIntegrationInfoUrl: authIntegrationInfoUrl,
      });
      const pageName = sessionStorage.getItem('page');
      if (createAccountUserResponse.formError) {
        const { formExceptions } = createAccountUserResponse;
        await handleValidation(formExceptions);
      } else {
        dispatch(
          setData({
            ...initial,
            sessionInfo: createAccountUserResponse,
          }),
        );
        await setErrors({
          emailError: '',
          firstNameError: '',
          lastNameError: '',
          passwordError: '',
          useShopForError: '',
          postalCodeError: '',
          invalidDomainError: '',
          termsAcceptedError: '',
          phoneNumberError: '',
          duplicateMailError: '',
        });
        setLoading(false);
        window.bootstrap.Modal.getInstance(ref.current)?.hide();
        if (dest_url) {
          window.location.href = dest_url;
        } else if (!pageName) {
          sessionStorage.setItem('page', '');
          router.push('/my-account');
        } else if (pageName === 'addToRegistryPDP') {
          router.push(urlMapping.createRegistry);
        }
        const { sessionInfo } = createAccountUserResponse;
        const dyData = {
          eventName: 'Signup',
          type: 'signup-v1',
          email: sessionInfo?.profile?.email,
        };
        dyEvent(dyData);
        CreateAccountDTMEvent({
          lowerEnv: init?.initResponse?.lowerEnv,
          userShopFor: sessionInfo?.profile?.userShopFor,
          email: sessionInfo?.profile?.email,
          staySignedIn: sessionInfo?.staySignedIn,
          internalAPI: init?.initResponse?.internalAPI,
          furniture: sessionInfo?.furniture,
          md5Email: sessionInfo?.profile?.md5Email,
          encryptedOrderId: sessionInfo?.cartInfo?.encryptedOrderId,
          orderId: sessionInfo?.cartInfo?.cartId,
        });
      }
    }
    setLoading(false);
  };

  const addClassBootstrap = rewardsEnabled
    ? `col-md-8 order-md-1 ${classes['leftSection']}`
    : ' ';

  const disableClass = loading ? `${classes['disable']}` : '';

  const formatPhoneNumber = (input: any) => {
    const cleaned = ('' + input).replace(/\D/g, '');

    const numericInput = cleaned.slice(0, 10);

    const match = numericInput.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    return numericInput;
  };

  const changePhoneNumberFormat = (e: any) => {
    const value = e.target.value;
    const formattedValue = formatPhoneNumber(value);
    setPhoneNumber(formattedValue);
  };

  const createAccountModalBodyClass = rewardsEnabled
    ? classes['modalDialog']
    : classes['modalDialogWrapper'];

  const passwordIcons = (
    validation: boolean,
    isAnyValidationMet: boolean,
    text: string,
  ) => {
    return (
      <div
        style={{
          color: validation ? '#18b335' : '#020202',
        }}
      >
        <span
          className={`ls-icon ${
            validation
              ? 'icn-check-sld-circle'
              : isAnyValidationMet
                ? 'icn-x-close-hvy-circle'
                : 'icn-x-close-hvy-circle'
          }`}
          style={{
            color: validation
              ? '#18b335'
              : isAnyValidationMet
                ? '#020202'
                : '#adacac',
          }}
        ></span>
        <span className={classes['password-conditions-text']}>{text}</span>
      </div>
    );
  };

  return (
    <>
      <Modal
        showHeader={true}
        id="createAccountModal"
        dialogClass={`modal-dialog ll-modal-top ${createAccountModalBodyClass}`}
        contentClass={`modal-content ${classes['modal-content']}`}
        headingTagName="4"
        footerClass="modal-footer"
        modalTitle={rewardsEnabled ? 'Create Account' : 'Register'}
        wrapperClass={`${classes['createAccountModal']}`}
        showPrimaryButton={true}
        closeModalOnClick={() => {
          setErrors({
            emailError: '',
            firstNameError: '',
            lastNameError: '',
            passwordError: '',
            useShopForError: '',
            postalCodeError: '',
            invalidDomainError: '',
            termsAcceptedError: '',
            phoneNumberError: '',
            duplicateMailError: '',
          });
        }}
        showSecondaryButton={true}
        primaryButtonName="Submit"
        ref={ref}
      >
        <div className={classes['scroll-area']}>
          <div className="row">
            {error?.invalidDomainError && (
              <div className={`${classes['error-msg']} ${classes['domain']}`}>
                {parse(error?.invalidDomainError)}
              </div>
            )}
            {rewardsEnabled && (
              <div className={`col-md-4 order-md-2 ${classes['rightSection']}`}>
                <Image
                  className="mb-3"
                  width={187}
                  src={logoIcon}
                  alt="logo"
                ></Image>
                <p className={`${classes['rewards-heading']}`}>
                  Unlock VIP Benefits and start earning rewards today.
                </p>
                <ul>
                  <li>
                    <span className={`${classes['icon']} ${classes['earn']}`}>
                      {' '}
                    </span>
                    <span className={`${classes['title']}`}>EARN</span>
                    <span className={`${classes['subtitle']}`}>
                      Points every time your shop
                    </span>
                  </li>
                  <li>
                    <span className={`${classes['icon']} ${classes['redem']}`}>
                      {' '}
                    </span>
                    <span className={`${classes['title']}`}>REDEEM</span>
                    <span className={`${classes['subtitle']}`}>
                      Points for Lakeshore Bucks
                    </span>
                  </li>
                  <li>
                    <span className={`${classes['icon']} ${classes['save']}`}>
                      {' '}
                    </span>
                    <span className={`${classes['title']}`}>SAVE</span>
                    <span className={`${classes['subtitle']}`}>
                      More on what you love
                    </span>
                  </li>
                </ul>
                <span className={`${classes['signInBottom']}`}>
                  Already have an account?{' '}
                  <Link
                    className={`${classes['sign-in-text']}`}
                    data-bs-toggle="modal"
                    data-bs-target="#signInModal"
                    href=""
                  >
                    Sign In
                  </Link>
                </span>
              </div>
            )}

            <div className={`${addClassBootstrap} order-1 px-3`}>
              <div className={`${classes['inputfields-first']}`}>
                <div className={`${classes['inputWrapper']}`}>
                  <Input
                    automationId="reg-firstname"
                    data-testid="first-name-account"
                    type="text"
                    customClass={error.firstNameError ? classes.Inputerror : ''}
                    onChange={(e) => setFirstName(e.target.value)}
                    labelText="First Name"
                    id={`${getUniqueKey(1, 'first-name-account')}`}
                    maxLength={40}
                  />
                  <Conditional if={error?.firstNameError?.length > 0}>
                    <div className={`${classes['error-msg']}`}>
                      {error?.firstNameError}
                    </div>
                  </Conditional>
                </div>
                <div className={`${classes['inputWrapper']}`}>
                  <Input
                    automationId="reg-lastname"
                    data-testid="last-name-account"
                    type="text"
                    value={lastName}
                    customClass={error.lastNameError ? classes.Inputerror : ''}
                    onChange={(e) => setLastName(e.target.value)}
                    labelText="Last Name"
                    id={`${getUniqueKey(2, 'last-name-account')}`}
                    maxLength={40}
                  />
                  <Conditional if={error?.lastNameError?.length > 0}>
                    <div className={`${classes['error-msg']}`}>
                      {error?.lastNameError}
                    </div>
                  </Conditional>
                </div>
              </div>
              <Input
                automationId="email_signup_modal"
                data-testid="email-account"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                customClass={error.emailError ? classes.Inputerror : ''}
                labelText="Email"
                id={`${getUniqueKey(3, 'email')}`}
                maxLength={64}
              />
              <Conditional if={error?.duplicateMailError?.length > 0}>
                <div className={`${classes['error-msg']}`}>
                  Oops! The email address you entered is already in use. Please
                  <Link
                    className={`${classes['sign-in-text']} ${classes['duplicate']}`}
                    data-bs-toggle="modal"
                    data-bs-target="#signInModal"
                    href=""
                  >
                    sign in.
                  </Link>
                </div>
              </Conditional>

              <Conditional
                if={!error?.duplicateMailError && error?.emailError?.length > 0}
              >
                <div className={`${classes['error-msg']}`}>
                  {parse(error?.emailError)}
                </div>
              </Conditional>
              <div className={`${classes['inputPassword']}`}>
                <Input
                  automationId="reg-password"
                  type={showPassword ? 'text' : 'password'}
                  labelText="Password"
                  customClass={error.passwordError ? classes.Inputerror : ''}
                  datatestid="input-pass-create"
                  onFocus={handleFocus}
                  onChange={handlePasswordChange}
                  onClickCapture={() => {
                    setIsSectionVisible(true);
                    setPopOverStaySigned(true);
                  }}
                  id={`${getUniqueKey(3, 'inputPassword')}`}
                />
                <i
                  className={
                    showPassword
                      ? `${classes['password-icon']} ${classes['hideIcon']}`
                      : `${classes['password-icon']} ${classes['showIcon']}`
                  }
                  onClick={togglePasswordVisibility}
                  role="button"
                  aria-label="Show Password"
                  data-testid="password-eye-N"
                ></i>
              </div>

              <Conditional if={error?.passwordError?.length > 0}>
                <div className={`${classes['error-msg']}`}>
                  {error?.passwordError}
                </div>
              </Conditional>
              <div className={`${classes['inputFocusWrap']}`}>
                {popOverStaySigned && (
                  <div className={`${classes['popOverCreateStaySigned']}`}>
                    <span
                      onClick={handleCloseTooltip}
                      className={`${classes['password-tooltip-close-icon']}`}
                    ></span>
                    <span className={`${classes['password-tooltip-text']}`}>
                      For security purposes, we strongly recommend creating a
                      password that is unique to Lakeshore.
                    </span>
                  </div>
                )}

                <div
                  className={`${classes.passwordConditionsWrapperCreate} ${isSectionVisible ? classes['open'] : classes['collapsed']}`}
                >
                  <ul>
                    {/* Password validations */}
                    <li
                      style={{
                        color: validations.length ? 'green' : '#020202',
                      }}
                    >
                      {passwordIcons(
                        validations.length,
                        isAnyValidationMet,
                        'Minimum 10 characters',
                      )}
                    </li>
                    <li
                      style={{
                        color: validations.length ? 'green' : '#020202',
                      }}
                    >
                      {passwordIcons(
                        validations.uppercase,
                        isAnyValidationMet,
                        'Minimum one uppercase letter',
                      )}{' '}
                    </li>
                    <li
                      style={{
                        color: validations.length ? 'green' : '#020202',
                      }}
                    >
                      {passwordIcons(
                        validations.lowercase,
                        isAnyValidationMet,
                        'Minimum one lowercase letter',
                      )}{' '}
                    </li>
                    <li
                      style={{
                        color: validations.length ? 'green' : '#020202',
                      }}
                    >
                      {passwordIcons(
                        validations.number,
                        isAnyValidationMet,
                        'Minimum one number',
                      )}{' '}
                    </li>
                    <li
                      style={{
                        color: validations.length ? 'green' : '#020202',
                      }}
                    >
                      {passwordIcons(
                        validations.specialChar,
                        isAnyValidationMet,
                        'Minimum one special character: `~!@#$%^*()-_=+|;:,.?',
                      )}{' '}
                    </li>
                    <li
                      style={{
                        color: validations.length ? 'green' : '#020202',
                      }}
                    >
                      {passwordIcons(
                        validations.noSpaces,
                        isAnyValidationMet,
                        'No spaces',
                      )}{' '}
                    </li>
                  </ul>

                  <div>
                    <h6
                      className={classes['strength-title']}
                      style={{ color: passwordStrength.color }}
                    >
                      Strength: {passwordStrength.text}
                    </h6>
                    <div className={classes.strengthBar}>
                      <div className={classes.strengthBarSeparator}></div>
                      <div
                        className={`${classes.strengthBarSeparator} ${classes.second}`}
                      ></div>
                      <div
                        style={{
                          height: '100%',
                          width: `${passwordStrength.percentage}%`,
                          backgroundColor: passwordStrength.color,
                          borderRadius: passwordStrength.borderRadius,
                          textAlign: 'center',
                          lineHeight: '20px',
                          color: 'white',
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>

              <Conditional if={rewardsEnabled}>
                <>
                  <Input
                    automationId="phone_signup_modal"
                    type="text"
                    value={phoneNumber}
                    data-testid="phone-number-account"
                    onChange={changePhoneNumberFormat}
                    labelText={parse(phoneNumberText)}
                    id={`${getUniqueKey(4, 'phone-number-account')}`}
                  />
                  <Conditional if={error?.phoneNumberError?.length > 0}>
                    <div className={`${classes['error-msg']}`}>
                      {error?.phoneNumberError}
                    </div>
                  </Conditional>

                  <div className={`mt-3 mb-3 ${classes['zip-code-wrapper']}`}>
                    <Input
                      automationId="postal_signup_modal"
                      type="Number"
                      data-testid="postal-code-account"
                      value={postalCode}
                      customClass={
                        error.postalCodeError ? classes.Inputerror : ''
                      }
                      onChange={(e) => setPostalCode(e.target.value)}
                      labelText="Zip Code"
                      id={`${getUniqueKey(5, 'postal-code-account')}`}
                    />

                    <Conditional if={error?.postalCodeError?.length > 0}>
                      <div className={`${classes['error-msg']}`}>
                        {error?.postalCodeError}
                      </div>
                    </Conditional>
                  </div>
                </>
              </Conditional>

              <div
                className={`${classes['radioWrapper']} ${rewardsEnabled ? classes['radioWrapper-rewardEnable'] : ''}`}
              >
                <Heading
                  customClass={classes['typically-options-for']}
                  tagName="6"
                >
                  {' '}
                  I typically shop for:
                </Heading>
                <div className={classes['radio-button-wrap']}>
                  <RadioButton
                    automationId="shop-for-class"
                    label="Classroom/Organization"
                    value="classroom"
                    checked={selectedValue === 'classroom'}
                    onChange={handleRadioChange}
                    datatestid="radio-button-account"
                    classname={classes['create-ac-option']}
                  />
                  <RadioButton
                    automationId="shop-for-home"
                    label="Home"
                    value="home"
                    checked={selectedValue === 'home'}
                    onChange={handleRadioChange}
                    classname={classes['create-ac-option']}
                  />
                  <RadioButton
                    automationId="shop-for-both"
                    label="Both"
                    value="all"
                    checked={selectedValue === 'all'}
                    onChange={handleRadioChange}
                    classname={classes['create-ac-option']}
                  />
                </div>

                <Conditional if={error?.useShopForError?.length > 0}>
                  <div className={`${classes['error-msg']}`}>
                    {error?.useShopForError}
                  </div>
                </Conditional>
              </div>

              <Conditional if={rewardsEnabled}>
                <>
                  <div className="mb-2 mt-2">
                    <Checkbox
                      label={parse(byRegistryText)}
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      ariaLabelledBy="checkbox-label"
                      name="checkbox"
                      ariaDescribedBy="checkbox-description"
                      datatestid="keep-signed-in-account"
                      automationId="lakeshore-rewards-terms-conditions-checkbox"
                    />
                  </div>
                  <Conditional if={error?.termsAcceptedError?.length > 0}>
                    <div className={`${classes['error-msg']}`}>
                      {parse(error?.termsAcceptedError)}
                    </div>
                  </Conditional>
                </>
              </Conditional>

              <Conditional if={!rewardsEnabled}>
                <div className="mb-3 mt-2">
                  <Checkbox
                    automationId="emailOptIn"
                    label="Yes, I would like to receive news and promotions via email."
                    checked={isEmailChecked}
                    onChange={handleEmailCheckBoxChange}
                    ariaLabelledBy="checkbox-label"
                    name="checkbox"
                    ariaDescribedBy="checkbox-description"
                    datatestid="promotional-mails"
                  />
                </div>
              </Conditional>

              <div
                className={`d-flex text-center 11 ${classes['checkBoxWrapper']}`}
              >
                <Checkbox
                  automationId="staySignedIn"
                  label="Keep me signed in"
                  checked={isSignInChecked}
                  onChange={handleSignInCheckboxChange}
                  ariaLabelledBy="checkbox-label"
                  name="checkbox"
                  ariaDescribedBy="checkbox-description"
                  datatestid="keep-signed-in-signaccount"
                />

                <span
                  data-toggle="popover"
                  className={`${classes['staySignPopOverText']}`}
                  onClick={showPopOverTooltip}
                  data-content="Popover content"
                  data-testid="popover-tool-tip-n"
                >
                  <span className={`ls-icon ${classes['icnHelpOtl']}`}></span>
                </span>

                {showPopOverContent && (
                  <div className={`${classes['popOverStaySigned']}`}>
                    <span
                      onClick={closePopOverTooltip}
                      className={`${classes['close-icon']}`}
                      data-testid="close-pop-over"
                    >
                      {' '}
                    </span>
                    {createModalNames.staySignIn}
                  </div>
                )}
              </div>

              <span className={`${classes['publicwifiText']}`}>
                {createModalNames.publicWifi}
              </span>
              <div
                className={`d-md-flex align-items-end mb-2 mt-2 gap-3 ${classes['button-wrap']} ${disableClass}`}
              >
                <div
                  className={`d-md-flex gap-3 order-md-2 ${rewardsEnabled ? 'justify-content-md-start' : 'justify-content-md-end'}`}
                >
                  <Button
                    automationId="register_button"
                    customClass="order-md-2"
                    data-testid="submit-button-create"
                    onClick={handleSubmit}
                  >
                    {' '}
                    {rewardsEnabled ? 'Sign Up' : 'Register'}
                  </Button>
                  <Button
                    automationId="cancel_button"
                    variant="ternary"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setErrors({
                        emailError: '',
                        firstNameError: '',
                        lastNameError: '',
                        passwordError: '',
                        useShopForError: '',
                        postalCodeError: '',
                        invalidDomainError: '',
                        termsAcceptedError: '',
                        phoneNumberError: '',
                        duplicateMailError: '',
                      });
                    }}
                  >
                    {' '}
                    Cancel
                  </Button>
                </div>
                {!rewardsEnabled && (
                  <div className="col-md-7">
                    <Link
                      className={`${classes['seePrivacyPolicy']}`}
                      href="/about/privacy-policy/"
                    >
                      {' '}
                      See Privacy Policy
                    </Link>
                  </div>
                )}
              </div>
              <Conditional if={rewardsEnabled}>
                <>
                  <span
                    className={`${classes['signInBottom']} ${classes['mobile']}`}
                  >
                    Already have an account?{' '}
                    <Link
                      className={`${classes['sign-in-text']}`}
                      data-bs-toggle="modal"
                      data-bs-target="#signInModal"
                      href=""
                    >
                      Sign In
                    </Link>
                  </span>
                  <Link
                    href="#gsaUserModal"
                    data-bs-toggle="modal"
                    className={`${classes['gsa-user']}`}
                    data-bs-dismiss="modal"
                  >
                    {' '}
                    {createModalNames.gsaUser}
                  </Link>
                </>
              </Conditional>
              <Conditional if={!rewardsEnabled}>
                <Checkbox
                  label="I am a GSA Member"
                  checked={isGsaUser}
                  onChange={handleGsaUserChange}
                  ariaLabelledBy="checkbox-label"
                  name="checkbox"
                  classname={`${classes['gsaUserText']}`}
                  ariaDescribedBy="checkbox-description"
                  datatestid="gsa-member"
                  variant={'lg'}
                  automationId="gsa-member"
                />
              </Conditional>
            </div>
          </div>
        </div>
      </Modal>
      <GSAUserModal />
      <SuccessModal isSuccessContent={isSuccessContent} showRef={showRef} />
    </>
  );
};

export default CreateAccountModal;
