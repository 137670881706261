'use client';

import { useRouter } from 'next/navigation';
import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import Link from '@next-app/components/elements/Link/Link';
import { debounce } from '@next-app/utils/debounce';
import {
  fetchData,
  fetchProductSuggestion,
  formatUSPrice,
  searchSubmit,
} from './helper';
import classes from './search.module.scss';
import Button from '../../../elements/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
  SearchProps,
  Suggestion,
  Product,
  TypeAheadResponse,
  IState,
  RedirectSearchSubmit,
} from '@next-app/interface/Common';
import Conditional from '@next-app/components/Conditional';
import { dyEvent } from '@next-app/utils/dy-event';
import { getCookie } from '@next-app/utils/cookies';
import { getMasterProductId } from '@next-app/lib/features/PDP/PDPSelectors';

interface ProductListProp {
  products: Product[];
  imageBaseUrl?: string;
  isMobile: boolean;
}

const ProductsList = ({ products, imageBaseUrl }: ProductListProp) => {
  const gsaUser = useSelector(
    (state: any) =>
      state?.init?.sessionInfo?.sessionInfo?.profile?.gsaUser ?? false,
  );

  const getPriceDisplay = (ele: Product, isGsaUser: boolean) => {
    const { priceMax, saleMax, priceMin, saleMin, gsa } = ele;
    const isSalePrice = priceMax !== saleMax || priceMin !== saleMin;

    switch (true) {
      case ele?.gcPriceRange && ele?.gcPriceRange !== '':
        return (
          <span
            className={`${classes.grey} ${classes['price-small']}`}
            style={{ display: 'block' }}
          >
            {ele?.gcPriceRange}
          </span>
        );
      // Case for GSA user when sale price is fixed
      case isSalePrice && isGsaUser && gsa && saleMax === saleMin:
        return (
          <span className={classes.searchGsaUser}>
            {`${formatUSPrice(saleMax)} GSA`}
          </span>
        );

      // Case for GSA user when sale price is a range
      case isSalePrice && isGsaUser && gsa && saleMax !== saleMin:
        return (
          <span className={classes.searchGsaUser}>
            {`${formatUSPrice(saleMin)} - ${formatUSPrice(saleMax)} GSA`}
          </span>
        );

      // Case for GSA item but no GSA price (Show regular price)
      case gsa && (!isSalePrice || !isGsaUser):
        return (
          <span
            className={`${classes.grey} ${classes['price-small']}`}
            style={{ display: 'block' }}
          >
            {priceMax === priceMin
              ? formatUSPrice(priceMax)
              : `${formatUSPrice(priceMin)} - ${formatUSPrice(priceMax)}`}
          </span>
        );

      // Case for non-GSA user when sale price is fixed
      case isSalePrice && !isGsaUser && !gsa && saleMax === saleMin:
        return (
          <span
            className={classes.grey}
            style={{ display: 'block', color: 'red' }}
          >
            {formatUSPrice(saleMax)}
          </span>
        );

      // Case for non-GSA user when sale price is a range
      case isSalePrice && !isGsaUser && !gsa && saleMax !== saleMin:
        return (
          <span
            className={classes.grey}
            style={{ display: 'block', color: 'red' }}
          >
            {`${formatUSPrice(saleMin)} - ${formatUSPrice(saleMax)}`}
          </span>
        );

      // Non-GSA, no sale, and priceMin is not equal to priceMax
      case !gsa && !isSalePrice && priceMin !== priceMax:
        return (
          <span
            className={`${classes.grey} ${classes['price-small']}`}
            style={{ display: 'block' }}
          >
            {`${formatUSPrice(priceMin)} - ${formatUSPrice(priceMax)}`}
          </span>
        );

      // Non-GSA, no sale, and priceMin is equal to priceMax
      case !gsa && !isSalePrice && priceMin == priceMax:
        return (
          <span
            className={`${classes.grey} ${classes['price-small']}`}
            style={{ display: 'block' }}
          >
            {`${formatUSPrice(priceMax)}`}
          </span>
        );

      // Default case when there is no sale price (Regular price for non-GSA and sale items)
      default:
        // Regular price should only be shown if it is not a GSA item and there is a sale price
        if (!gsa && isSalePrice) {
          return (
            <span
              className={`${classes.grey} ${isSalePrice ? classes['price-small'] : ''}`}
              style={{ display: 'block' }}
            >
              reg.{' '}
              {priceMax === priceMin
                ? formatUSPrice(priceMax)
                : `${formatUSPrice(priceMin)} - ${formatUSPrice(priceMax)}`}
            </span>
          );
        }
        return null;
    }
  };

  return (
    <div>
      {products.map((ele: Product, index: number) => {
        const { name, url, displayImage } = ele;

        return (
          <div
            key={`${name}-${index}`}
            className={`${classes['product-suggestions-mobile']} ${ele['@class']}`}
          >
            <Link data-TYPE_AHEAD_PRODUCT_CLICK={true} href={url}>
              <div className="row">
                <div className={`col-4 ${classes['product-sugg-img']}`}>
                  <Image
                    src={`${imageBaseUrl}/is/image/OCProduction/${displayImage}`}
                    className="item-img autofill position-static"
                    fill={true}
                    alt=""
                  />
                </div>
                <div className={`col-8 ${classes['product-sugg-desc']}`}>
                  <Link
                    href={url}
                    className="productSuggestion"
                    data-TYPE_AHEAD_PRODUCT_CLICK={true}
                  >
                    <div dangerouslySetInnerHTML={{ __html: name }} />
                  </Link>
                  <div className="tt-price">
                    {getPriceDisplay(ele, gsaUser)}
                  </div>
                </div>
              </div>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default function Search(props: SearchProps) {
  const { placeholder } = props.contentItem;
  const { init } = useSelector((state: any) => state.init);
  const { initResponse: { scene7BaseURL = '' } = {} } = init || {};
  const [data, setData] = useState<TypeAheadResponse>({
    '@class': '',
    products: [],
    suggestions: [],
  });
  const [inputVal, setInputVal] = useState('');
  const [inputValSec, setInputValSec] = useState('');
  const router = useRouter();
  const [isError, setError] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);
  const [isMobile, setIsMobile] = useState(false);
  const [isTab, setIsTab] = useState(false);
  const [isWeb, setIsWeb] = useState(true);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const masterProductId = useSelector(getMasterProductId);

  const products = data?.products || [];
  const suggestions = data?.suggestions || [];
  const { sessionInfo } = useSelector((state: IState) => state.init);
  const {
    sessionInfo: {
      dynSess: { dynSessConfNumber } = {},
      userSegmentsMap: { userSegments } = { userSegments: '' },
    } = {},
  } = sessionInfo;

  const dispatch = useDispatch();
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 450) {
        setIsMobile(true);
        setIsTab(false);
        setIsWeb(false);
      }
      if (window.innerWidth > 450 && window.innerWidth < 1024) {
        setIsMobile(false);
        setIsTab(true);
        setIsWeb(false);
      }
      if (window.innerWidth > 1024) {
        setIsMobile(false);
        setIsTab(false);
        setIsWeb(true);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debounce(() => {
      fetchData(e.target.value, (response: TypeAheadResponse) => {
        setData(response || []);
      });
    }, 500);
    setInputVal(e.target.value);
    setInputValSec(e.target.value);
    if (highlightedIndex !== -1) {
      setHighlightedIndex(-1);
    }
  };

  const handleProductSuggestionChange = (linkVal: string) => {
    if (linkVal) {
      debounce(
        () => fetchProductSuggestion(linkVal, data, setData, dynSessConfNumber),
        500,
      );
    }
  };

  const handleClear = () => {
    setInputVal('');
    setInputValSec('');
    setData({ '@class': '', products: [], suggestions: [] });
    if (isError) {
      setError(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const suggestionsLength = suggestions.length;
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setHighlightedIndex((prevIndex) => {
        const val = (prevIndex + 1) % suggestionsLength;
        debounce(
          () =>
            fetchProductSuggestion(
              suggestions[val]?.search,
              data,
              setData,
              dynSessConfNumber,
            ),
          300,
        );
        setInputVal(suggestions[val]?.search);
        return val;
      });
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setHighlightedIndex((prevIndex) => {
        const val =
          prevIndex === -1
            ? suggestionsLength - 1
            : (prevIndex - 1 + suggestionsLength) % suggestionsLength;
        debounce(
          () =>
            fetchProductSuggestion(
              suggestions[val]?.search,
              data,
              setData,
              dynSessConfNumber,
            ),
          300,
        );
        setInputVal(suggestions[val]?.search);
        return val;
      });
      debounce(
        () =>
          fetchProductSuggestion(
            suggestions[highlightedIndex].search,
            data,
            setData,
            dynSessConfNumber,
          ),
        500,
      );
    } else if (e.key === 'Enter' && highlightedIndex >= 0) {
      e.preventDefault();
      const selectedSuggestion = suggestions[highlightedIndex];
      if (selectedSuggestion) {
        router.push(selectedSuggestion.url);
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    sessionStorage.setItem('searchDtm', 'internalSearch');
    e.preventDefault();

    const properties = {
      dyType: 'keyword-search-v1',
      keywords: inputVal,
    };
    dyEvent({ properties: properties, eventName: 'Keyword Search' });
    setShowSuggestion(false);
    if (!inputVal.trim()) {
      setError(true);
      return;
    }
    const response: RedirectSearchSubmit | null = await searchSubmit(
      inputVal.trim(),
    );

    if (
      response &&
      response?.['@type'] == 'RedirectAwareContentInclude' &&
      response?.link
    ) {
      window.location.href = response.link;
    }
  };

  const onFocusHandler = () => {
    setShowSuggestion(true);
  };

  const onBlurHandler = () => {
    setTimeout(() => {
      setShowSuggestion(false);
    }, 200);
  };

  const classValue = !Array.isArray(data) ? (data ? data['@class'] : '') : '';

  const handleInputValidation = (e: React.FormEvent<HTMLInputElement>) => {
    const input = e.currentTarget.value;
    const sanitizedInput = input.replace(/[^a-zA-Z0-9 !&'?"\-.,]/g, '');
    if (input !== sanitizedInput) {
      e.currentTarget.value = sanitizedInput;
      setInputVal(sanitizedInput);
      setInputValSec(sanitizedInput);
    }
  };

  return (
    <div className={classes.searchFilter}>
      <form className="pc-search header-search" onSubmit={handleSubmit}>
        <label htmlFor="search" className={classes['sr-only']}>
          Search:
        </label>
        <span className="ll-typeahead">
          <input
            id="search"
            name="search"
            type="text"
            data-testid="search-product-input"
            value={inputVal}
            className={`${classes.inputSearch} ${isError ? classes.error : ''} form-control`}
            autoCapitalize="none"
            autoComplete="off"
            placeholder={placeholder}
            automation-id="search_input"
            onInput={handleInputValidation}
            onChange={(e) => handleInputChange(e)}
            onKeyDown={handleKeyDown}
            onFocus={onFocusHandler}
            onBlur={onBlurHandler}
            onPaste={() =>
              sessionStorage.setItem('searchDtm', 'internalSearchTermCopyPaste')
            }
          />
          <Conditional if={!!inputVal}>
            <Button
              customClass={`ll-typeahead-remove header-link ls-icon ${classes['icn-x-close-lgt']}`}
              aria-label="Remove search input"
              onClick={handleClear}
              variant="transparent"
            ></Button>
          </Conditional>
          <Button
            id="btnSearch"
            customClass={`${classes['search-icon']} ls-icon`}
            aria-live="polite"
            type="submit"
            data-testid="search-product-button"
            automation-id="search_button"
            variant="transparent"
          ></Button>
        </span>
      </form>
      <Conditional
        if={
          ((products && products.length > 0) ||
            (suggestions && suggestions.length > 0)) &&
          !!inputVal &&
          showSuggestion
        }
      >
        <div className={`${classes.searchWrapper}`}>
          <div className={`${classValue} row m-0`}>
            <div className={`col-12 col-md-6 ${classes.suggestionBorder}`}>
              <Conditional if={isTab || isWeb}>
                <h5 className="fontBold">Search Suggestions:</h5>
              </Conditional>
              <Conditional if={suggestions && suggestions.length > 0}>
                <>
                  {suggestions.map((ele: Suggestion, index: number) => {
                    const { search, url } = ele;
                    const inputLowerCase = inputValSec.toLowerCase();
                    const isInputIncluded = search.includes(inputLowerCase);
                    const parsedSearch = search.split(inputLowerCase);
                    return (
                      <div
                        key={`${search}-${index}`}
                        className={`${ele['@class']} ${index === highlightedIndex ? classes['tt-cursor'] : ''}`}
                      >
                        <Link
                          data-INTERNAL_SEARCH_TERM_TYPEAHEAD={true}
                          href={url}
                          data-redirect="false"
                          onMouseOver={() =>
                            handleProductSuggestionChange(search)
                          }
                          onKeyDown={() => {
                            handleProductSuggestionChange(search);
                          }}
                        >
                          <div className="row">
                            <div className="col-xs-8">
                              <div className={`${classes['item-title']}`}>
                                <strong>{parsedSearch[0]}</strong>
                                {isInputIncluded && inputLowerCase}
                                <strong>{parsedSearch[1]}</strong>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </>
              </Conditional>
              <Conditional
                if={
                  isMobile &&
                  suggestions &&
                  !suggestions.length &&
                  products &&
                  products.length > 0
                }
              >
                <ProductsList
                  products={products}
                  imageBaseUrl={scene7BaseURL}
                  isMobile={isMobile}
                />
              </Conditional>
            </div>
            <div
              className={`col-12 col-md-6 d-none d-md-block ${classes['product-suggestions']}`}
            >
              <h5 className="fontBold">Product Suggestions:</h5>
              <Conditional if={products && products.length > 0}>
                <ProductsList
                  products={products}
                  imageBaseUrl={scene7BaseURL}
                  isMobile={isMobile}
                />
              </Conditional>
            </div>
          </div>
        </div>
      </Conditional>
    </div>
  );
}
