import { findComponent, getUniqueKey } from '@next-app/utils';

const FeaturedSlot = (props: any) => {
  if (!props?.contentItem?.content?.length) {
    return;
  }

  const FeaturedSlotList = props?.contentItem?.content?.map(
    (item: any, index: number) => {
      const DynamicComponent: any = findComponent(item);
      return (
        <DynamicComponent
          contentItem={item}
          key={getUniqueKey(index, item.name)}
        />
      );
    },
  );

  return <>{FeaturedSlotList}</>;
};

export default FeaturedSlot;
