'use client';

import Input from '../elements/Input/Input';
import classes from './gsa-user.module.scss';
import { useEffect, useRef, useState } from 'react';
import { gsaModalNames } from '@next-app/config/elementNameConfig';
import Modal from '../elements/Modal/Modal';
import { useSelector } from 'react-redux';
import Heading from '../elements/Heading/Heading';
import RadioButton from '../elements/RadioButton/RadioButton';
import Checkbox from '../elements/Checkbox/Checkbox';
import { decodeHTMLEntities } from '../../utils/decodeHTMLEntities';
import Button from '../elements/Button/Button';
import SuccessModal from '../SuccessModal/SuccessModal';
import parse from 'html-react-parser';
import { GSAUser } from '../../utils/authenticationUser';
import Link from '@next-app/components/elements/Link/Link';
import { getUniqueKey } from '@next-app/utils';

const GSAUserModal = () => {
  const ref = useRef<string | Element>('');
  const showRef = useRef<string | Element>('');

  const [showPassword, setShowPassword] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [password, setPassword] = useState('');
  const [isSectionVisible, setIsSectionVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isSuccessContent, setIsSuccessContent] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [showPopOverContent, setShowPopOverContent] = useState(false);

  const [error, setErrors] = useState({
    emailError: '',
    firstNameError: '',
    lastNameError: '',
    passwordError: '',
    useShopForError: '',
    postalCodeError: '',
    duplicateMailError: '',
    invalidDomainError: '',
  });

  const [validations, setValidations] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
    noSpaces: false,
  });

  useEffect(() => {
    if (password) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validations]);

  const { sessionInfo } = useSelector((state: any) => state.init);
  if (!Object.keys(sessionInfo).length) {
    return;
  }

  const handleCheckboxChange = (checked: boolean) => {
    setIsChecked(checked);
  };

  const matchedCriteriaCount =
    Object.values(validations).filter(Boolean).length;

  const handleFocus = () => {
    setIsSectionVisible(true);
    if (matchedCriteriaCount < 1 && !password) {
      document.addEventListener('mousedown', handleClickOutside);
    }
  };

  const handleClickOutside = () => {
    if (matchedCriteriaCount < 1 && !password) {
      setIsSectionVisible(false);
    } else {
      setIsSectionVisible(true);
    }
  };

  const handleRadioChange = (value: string) => {
    setSelectedValue(value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (e: any) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  const validatePassword = (password: any) => {
    const validations = {
      length: password.length >= 10,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /\d/.test(password),
      specialChar: /[~!@#$%^*()\-_=+|;:,.?]/.test(password),
      noSpaces: password ? !/\s/.test(password) : false,
    };
    setValidations(validations);
  };

  const getPasswordStrength = () => {
    if (matchedCriteriaCount === 6) {
      return {
        text: 'Strong',
        color: 'green',
        percentage: 100,
        borderRadius: '5px',
      };
    } else if (matchedCriteriaCount >= 5) {
      return {
        text: 'Moderate',
        color: 'orange',
        percentage: 66,
        borderRadius: '5px 0 0 5px',
      };
    } else if (matchedCriteriaCount >= 2) {
      return {
        text: 'Poor',
        color: 'red',
        percentage: 33,
        borderRadius: '5px 0 0 5px',
      };
    } else {
      return { text: '', color: '#020202', percentage: 0 };
    }
  };

  const passwordStrength = getPasswordStrength();

  const showPopOverTooltip = () => {
    setShowPopOverContent(!showPopOverContent);
  };

  const isAnyValidationMet = Object.values(validations).some(Boolean);

  const handleValidation = (formExceptions: any) => {
    const isEmailError = formExceptions.find(
      (el: any) =>
        el.propertyPath === 'email' || el.propertyPath === 'gsaEmail',
    );

    const isFirstNameError = formExceptions.find(
      (el: any) => el.propertyPath === 'firstName',
    );

    const isLastNameError = formExceptions.find(
      (el: any) => el.propertyPath === 'lastName',
    );

    const isPasswordError = formExceptions.find(
      (el: any) => el.propertyPath === 'password',
    );

    const isUseShopForError = formExceptions.find(
      (el: any) => el.propertyPath === 'userShopFor',
    );
    const isDuplicateMailError = formExceptions.find(
      (el: any) =>
        el.propertyPath === 'isDuplicated' && el.propertyPath === 'email',
    );
    const isInvalidDomainError = formExceptions.find(
      (el: any) => el.propertyPath === 'gsaInvalidDomain',
    );

    if (isEmailError) {
      setErrors((prev) => ({
        ...prev,
        emailError: decodeHTMLEntities(isEmailError.localizedMessage),
      }));
    }

    if (isFirstNameError) {
      setErrors((prev) => ({
        ...prev,
        firstNameError: isFirstNameError.localizedMessage,
      }));
    }

    if (isLastNameError) {
      setErrors((prev) => ({
        ...prev,
        lastNameError: isLastNameError.localizedMessage,
      }));
    }

    if (isPasswordError) {
      setErrors((prev) => ({
        ...prev,
        passwordError: isPasswordError.localizedMessage,
      }));
    }

    if (isUseShopForError) {
      setErrors((prev) => ({
        ...prev,
        useShopForError: isUseShopForError.localizedMessage,
      }));
    }

    if (isDuplicateMailError) {
      setErrors((prev) => ({
        ...prev,
        duplicateMailError: decodeHTMLEntities(
          isDuplicateMailError.localizedMessage,
        ),
      }));
    }

    if (isInvalidDomainError) {
      setErrors((prev) => ({
        ...prev,
        invalidDomainError: isInvalidDomainError.localizedMessage,
      }));
    }
  };

  const dynSessConfNumber =
    sessionInfo?.sessionInfo?.dynSess?.dynSessConfNumber;

  const handleSubmit = async () => {
    const gsaUserResponse: any = await GSAUser({
      email,
      password,
      firstName,
      lastName,
      emailOptIn: true,
      staySignedIn: isChecked,
      userShopFor: selectedValue,
      sessionRestored: false,
      sessionConfirmationNumber: dynSessConfNumber,
    });

    if (gsaUserResponse.formError) {
      const { formExceptions } = gsaUserResponse;
      await handleValidation(formExceptions);
    } else {
      const { message } = gsaUserResponse;
      const { successMessage } = message;
      setIsSuccessContent(successMessage);

      const modalInstance = window.bootstrap.Modal.getInstance(ref.current);
      if (modalInstance) {
        modalInstance.hide();
      }

      const newModal = new window.bootstrap.Modal(showRef.current);
      newModal.show();
    }
  };

  const passwordIcons = (validation: boolean, isAnyValidationMet: boolean) => {
    return (
      <span
        className={`ls-icon ${
          validation
            ? 'icn-check-sld-circle'
            : isAnyValidationMet
              ? 'icn-x-close-hvy-circle'
              : 'icn-x-close-hvy-circle'
        }`}
        style={{
          color: validation
            ? 'green'
            : isAnyValidationMet
              ? '#020202'
              : '#adacac',
        }}
      ></span>
    );
  };

  const restValueHandler = () => {
    setShowPassword(false);
    setIsChecked(false);
    setPassword('');
    setIsSectionVisible(false);
    setEmail('');
    setFirstName('');
    setLastName('');
    setSelectedValue('');
    setShowPopOverContent(false);
    setValidations({
      length: false,
      uppercase: false,
      lowercase: false,
      number: false,
      specialChar: false,
      noSpaces: false,
    });
    setErrors({
      emailError: '',
      firstNameError: '',
      lastNameError: '',
      passwordError: '',
      useShopForError: '',
      postalCodeError: '',
      duplicateMailError: '',
      invalidDomainError: '',
    });
  };

  let gsaModal = document.getElementById('gsaUserModal');
  gsaModal?.addEventListener('hidden.bs.modal', function () {
    restValueHandler();
  });

  return (
    <>
      <Modal
        showHeader={true}
        id="gsaUserModal"
        dialogClass={`modal-dialog md ${classes['gsa-dialog-box']}`}
        contentClass={`modal-content ${classes['modalContent']}`}
        headingTagName="4"
        modalClass="fade"
        modalTitle="Register"
        wrapperClass={`${classes['gsa-modal-wrapper']}`}
        ref={ref}
        closeModalOnClick={() => {
          setErrors({
            emailError: '',
            firstNameError: '',
            lastNameError: '',
            passwordError: '',
            useShopForError: '',
            postalCodeError: '',
            duplicateMailError: '',
            invalidDomainError: '',
          });
        }}
      >
        <div className={`${classes['gsaModalWrap']}`}>
          {error?.invalidDomainError && (
            <div className={`${classes['error-msg']} ${classes['domain']}`}>
              {parse(error?.invalidDomainError)}
            </div>
          )}
          <p> {gsaModalNames.gsaAccountHolderText}</p>
          <div className="d-flex gap-3">
            <div>
              <Input
                type="text"
                value={firstName}
                data-testid="gsa-first-name"
                onChange={(e) => setFirstName(e.target.value)}
                labelText="First Name"
                id={`${getUniqueKey(1, 'gsa-first-name')}`}
              />
              {error?.firstNameError && (
                <div className={`${classes['error-msg']}`}>
                  {error?.firstNameError}
                </div>
              )}
            </div>
            <div>
              <Input
                type="text"
                value={lastName}
                data-testid="gsa-last-name"
                onChange={(e) => setLastName(e.target.value)}
                labelText="Last Name"
                id={`${getUniqueKey(1, 'gsa-last-name')}`}
              />
              {error?.lastNameError && (
                <div className={`${classes['error-msg']}`}>
                  {error?.lastNameError}
                </div>
              )}
            </div>
          </div>
          <Input
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            data-testid="gsa-email"
            labelText="Email"
            id={`${getUniqueKey(1, 'gsa-email')}`}
            value={email}
          />
          {error?.emailError && (
            <div className={`${classes['gsa-email-error-wrapper']}`}>
              <div
                className={`${classes['error-msg']} ${classes['gsa-email-error']}`}
              >
                {parse(error?.emailError)}
              </div>
            </div>
          )}

          <div className={`${classes['inputPassword']}`}>
            <Input
              type={showPassword ? 'text' : 'password'}
              labelText="Password"
              data-testid="input=password-gsa"
              onFocus={handleFocus}
              onChange={handlePasswordChange}
              onClickCapture={() => {
                setIsSectionVisible(true);
              }}
              id={`${getUniqueKey(1, 'gsa-password')}`}
              value={password}
            />
            <i
              className={
                showPassword
                  ? `${classes['password-icon']} ${classes['hideIcon']}`
                  : `${classes['password-icon']} ${classes['showIcon']}`
              }
              onClick={togglePasswordVisibility}
              role="button"
              aria-label="Show Password"
              data-testid="password-eye"
            ></i>
          </div>
          {error?.passwordError && (
            <div className={`${classes['error-msg']}`}>
              {error?.passwordError}
            </div>
          )}
          <div className={`${classes['inputFocusWrap']}`}>
            <div
              className={`${classes.passwordConditionsWrapperCreate} ${isSectionVisible ? classes['open'] : classes['collapsed']}`}
            >
              {' '}
              <ul>
                {/* Password validations */}
                <li
                  style={{
                    color: validations.length ? 'green' : '#020202',
                  }}
                >
                  {passwordIcons(validations.length, isAnyValidationMet)}
                  <span className={classes['password-conditions-text']}>
                    Minimum 10 characters
                  </span>
                </li>
                <li
                  style={{
                    color: validations.length ? 'green' : '#020202',
                  }}
                >
                  {passwordIcons(validations.uppercase, isAnyValidationMet)}{' '}
                  <span className={classes['password-conditions-text']}>
                    Minimum one uppercase letter
                  </span>
                </li>
                <li
                  style={{
                    color: validations.length ? 'green' : '#020202',
                  }}
                >
                  {passwordIcons(validations.lowercase, isAnyValidationMet)}{' '}
                  <span className={classes['password-conditions-text']}>
                    Minimum one lowercase letter
                  </span>
                </li>
                <li
                  style={{
                    color: validations.length ? 'green' : '#020202',
                  }}
                >
                  {passwordIcons(validations.number, isAnyValidationMet)}{' '}
                  <span className={classes['password-conditions-text']}>
                    Minimum one number
                  </span>
                </li>
                <li
                  style={{
                    color: validations.length ? 'green' : '#020202',
                  }}
                >
                  {passwordIcons(validations.specialChar, isAnyValidationMet)}{' '}
                  <span className={classes['password-conditions-text']}>
                    Minimum one special character: `~!@#$%^*()-_=+|;:,.?
                  </span>
                </li>
                <li
                  style={{
                    color: validations.length ? 'green' : '#020202',
                  }}
                >
                  {passwordIcons(validations.noSpaces, isAnyValidationMet)}
                  <span className={classes['password-conditions-text']}>
                    No spaces
                  </span>
                </li>
              </ul>
              <div>
                <h6
                  className={classes['strength-title']}
                  style={{ color: passwordStrength.color }}
                >
                  {' '}
                  Strength: {passwordStrength.text}
                </h6>
                <div className={classes.strengthBar}>
                  <div className={classes.strengthBarSeparator}></div>
                  <div
                    className={`${classes.strengthBarSeparator} ${classes.second}`}
                  ></div>
                  <div
                    style={{
                      height: '100%',
                      width: `${passwordStrength.percentage}%`,
                      backgroundColor: passwordStrength.color,
                      borderRadius: passwordStrength.borderRadius,
                      textAlign: 'center',
                      lineHeight: '20px',
                      color: 'white',
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-3">
            <Heading tagName="6"> I typically shop for:</Heading>
            <div className="d-flex  gap-md-3 flex-column flex-md-row">
              <RadioButton
                label="Classroom/Organization"
                value="classroom"
                checked={selectedValue === 'classroom'}
                onChange={handleRadioChange}
              />
              <RadioButton
                label="Home"
                value="home"
                checked={selectedValue === 'home'}
                onChange={handleRadioChange}
              />
              <RadioButton
                label="Both"
                value="all"
                checked={selectedValue === 'all'}
                onChange={handleRadioChange}
              />
            </div>

            {error?.useShopForError && (
              <div className={`${classes['error-msg']}`}>
                {error?.useShopForError}
              </div>
            )}
          </div>

          <div
            className={`d-flex mb-2 mt-2 text-center ${classes['checkboxWrapper']}`}
          >
            <Checkbox
              label="Keep me signed in"
              checked={isChecked}
              onChange={handleCheckboxChange}
              ariaLabelledBy="checkbox-label"
              name="checkbox"
              ariaDescribedBy="checkbox-description"
              datatestid="keep-signed-in"
            />

            <span
              data-toggle="popover"
              className={`${classes['staySignPopOverText']}`}
              onClick={showPopOverTooltip}
              data-content="Popover content"
              data-testid="popover-tool-tip"
            >
              <span className={`ls-icon ${classes['icnHelpOtl']}`}></span>
            </span>

            {showPopOverContent && (
              <div className={`${classes['popOverStaySigned']}`}>
                {gsaModalNames.staySignIn}
              </div>
            )}
          </div>

          <span className={classes['keep-unchecked']}>
            Keep unchecked if using a public device or public Wi-Fi.
          </span>
          <div className="d-flex mb-3 mt-3 gap-3">
            <Button
              data-bs-dismiss="modal"
              variant="ternary"
              onClick={() => {
                setErrors({
                  emailError: '',
                  firstNameError: '',
                  lastNameError: '',
                  passwordError: '',
                  useShopForError: '',
                  postalCodeError: '',
                  duplicateMailError: '',
                  invalidDomainError: '',
                });
              }}
            >
              {' '}
              Cancel
            </Button>
            <Button data-testid="submit-button-gsa" onClick={handleSubmit}>
              {' '}
              Sign Up
            </Button>
          </div>
          <p className={classes['accept-policy']}>
            {' '}
            By Registering, I accept the{' '}
            <Link href="/about/privacy-policy"> Lakeshore Privacy Policy </Link>
            and agree to receive Lakeshore offers and notifications via email{' '}
          </p>
        </div>
      </Modal>
      <SuccessModal isSuccessContent={isSuccessContent} showRef={showRef} />
    </>
  );
};

export default GSAUserModal;
