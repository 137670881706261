import { PageContentButtonProps } from '@next-app/interface/Common';
import Button from '@next-app/components/elements/Button/Button';
import classes from './button.module.scss';
import Conditional from '@next-app/components/Conditional';

function PageContentButton(props: PageContentButtonProps) {
  const { contentItem, index } = props;
  const elemAttributes = {
    s_objectid: contentItem?.s_objectId || '',
    s_regionid: contentItem?.s_regionId || '',
  };

  return (
    <Conditional if={contentItem.enabled}>
      <div className={classes.buttonWrapper}>
        <Button
          link={contentItem.url || '#'}
          buttonSize="lg"
          customClass={`
              ${classes['takeItOutButton']}
              ${index > 0 ? classes['takeItOutThreeButton'] : ''}
            `}
          style={
            {
              '--webFlavorColor': contentItem?.webFlavor,
              '--textWebFlavorColor': contentItem?.textWebFlavor,
              '--mobileFlavorColor': contentItem?.mobileFlavor,
              '--textMobileFlavorColor': contentItem?.textMobileFlavor,
            } as React.CSSProperties
          }
          {...elemAttributes}
        >
          {contentItem.buttonText || contentItem.altText}
        </Button>
      </div>
    </Conditional>
  );
}

export default PageContentButton;
