import { MenuTabSlotProps } from '@next-app/interface/Common';
import { findComponent } from '@next-app/utils';

const MenuTabSlot = ({ contentItem, mobileNavigation }: MenuTabSlotProps) => {
  const { contents } = contentItem;

  const ComponentList = contents.map((item, index: number) => {
    const Component = findComponent(item);
    if (!Component) return null;

    return (
      <Component
        contentItem={item}
        key={index}
        mobileNavigation={mobileNavigation}
      />
    );
  });

  return ComponentList;
};

export default MenuTabSlot;
