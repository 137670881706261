'use client';

const CrossIcon: React.FC = () => {
  const handleReverse = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLElement;
    let parentEle: HTMLElement | null = target.closest('ul');
    let closestParent: HTMLElement | null = target.closest('li');

    if (parentEle) {
      parentEle.removeAttribute('style');
    }

    if (parentEle && parentEle.classList.contains('dummyDataAlgo')) {
      let mpLevel = parentEle.querySelector('.mp-level') as HTMLElement | null;
      let subList = closestParent?.querySelector(
        '.mp-level',
      ) as HTMLElement | null;

      if (mpLevel) {
        mpLevel.removeAttribute('style');
      }
      if (subList) {
        subList.removeAttribute('style');
      }
    }
  };

  return (
    <div className="CrossIcon" onClick={handleReverse}>
      ×
    </div>
  );
};

export default CrossIcon;
