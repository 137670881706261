'use client';

import getDevice from '@next-app/components/HOC/GetDevice';
import DesktopMenuLinkImages from './DesktopMenuLinkImage';
import DesktopMenuLinks from './DesktopMenuLinks';
import MobileMenuLinks from './MobileMenuLinks';

interface MenuLinkImageProps {
  contentItem: {
    imageAltText: string;
    imageBottomText: string;
    imageLink: string;
    imageURL: string;
    isHeaderText: string;
    linkType: string;
    parentCategoryId: string;
    textLink: string;
    name: string;
    textLinkLabel: string;
    imageDecorations: string;
    s_objectId: string;
  };
  displayInMobile: boolean;
  tabHeader: string;
  isMobile: boolean;
  isTab: boolean;
  isWeb: boolean;
}

const MenuLinkImage = ({
  contentItem,
  displayInMobile,
  isMobile,
  isTab,
  isWeb,
  tabHeader,
}: MenuLinkImageProps) => {
  const {
    imageLink,
    imageAltText,
    imageBottomText,
    imageDecorations,
    textLinkLabel,
    textLink,
    linkType,
    s_objectId,
  } = contentItem;
  const imageURL = contentItem?.imageURL?.replace('&amp;', '&');
  return (
    <>
      <DesktopMenuLinkImages
        isWeb={isWeb}
        linkType={linkType}
        imageLink={imageLink}
        imageDecorations={imageDecorations}
        imageURL={imageURL}
        imageAltText={imageAltText}
        imageBottomText={imageBottomText}
        s_objectId={s_objectId}
      />
      <DesktopMenuLinks
        isWeb={isWeb}
        linkType={linkType}
        textLinkLabel={textLinkLabel}
        textLink={textLink}
        tabHeader={tabHeader}
      />
      <MobileMenuLinks
        isMobile={isMobile}
        isTab={isTab}
        displayInMobile={displayInMobile}
        imageLink={imageLink}
        imageDecorations={imageDecorations}
        imageURL={imageURL}
        imageAltText={imageAltText}
        imageBottomText={imageBottomText}
        textLink={textLink}
        textLinkLabel={textLinkLabel}
        tabHeader={tabHeader}
      />
    </>
  );
};

export default getDevice(MenuLinkImage);
