export const extractDimensionsFromUrl = (partialUrl: any) => {
  if (partialUrl !== null) {
    const queryString = partialUrl?.split('?')[1];
    const params = new URLSearchParams(queryString);

    const width = params?.get('wid');
    const height = params?.get('hei');
    return {
      width: width ? parseInt(width, 10) : 0,
      height: height ? parseInt(height, 10) : 100,
    };
  }
  return {
    width: 100,
    height: 100,
  };
};
