'use client';

import React from 'react';
import { useSelector } from 'react-redux';
import styles from './GSAnav.module.scss';
import Conditional from '@next-app/components/Conditional';
import { isEmptyObject } from '../../ProductDetails/helper';
const GSANavLink = () => {
  const { sessionInfo, headerFooter } = useSelector((state: any) => state.init);

  if (isEmptyObject(sessionInfo)) {
    return;
  }
  const isGSAUser = sessionInfo?.sessionInfo?.profile?.gsaUser;
  const GSATabs =
    headerFooter?.navigation?.contents[0]?.navigationLeft?.tabs?.filter(
      (item: any) => item?.name === 'Featured Slot',
    );
  const GSALink =
    GSATabs &&
    GSATabs[0]?.contents[0]?.tabData[0].contents[0]?.groupData
      ?.slice(-1)
      ?.pop();
  return (
    <Conditional if={GSALink?.name === 'GSA Contracted Items' && isGSAUser}>
      <a
        data-testid="link"
        className={styles['menu-link']}
        href={GSALink?.textLink}
      >
        {GSALink?.name}
      </a>
    </Conditional>
  );
};

export default GSANavLink;
