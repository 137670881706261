import Input from '@next-app/components/elements/Input/Input';
import { IContactFormFieldProps } from '@next-app/interface/ContactForm';
import classes from './contact-form-field.module.scss';

const ContactFormField = ({
  contentItem,
  handleOnChange,
  formData,
}: IContactFormFieldProps) => {
  return (
    <Input
      data-testid="input"
      type={contentItem.type}
      onChange={(e) => handleOnChange(e.target.value, contentItem.field)}
      labelText={contentItem.name}
      maxLength={parseInt(contentItem.maxLength)}
      disabled={!contentItem.enabled}
      value={formData[`${contentItem.field}`]}
      customClass={classes['inputField']}
    />
  );
};

export default ContactFormField;
