import Conditional from '@next-app/components/Conditional';
import classes from './menu-link.module.scss';

const DesktopMenuLinks = ({
  isWeb,
  linkType,
  textLinkLabel,
  textLink,
  tabHeader,
}: any) => {
  return (
    <Conditional if={isWeb && linkType == 'text'}>
      <div className={`${classes['menu-link']}`}>
        <a
          automation-id={`${tabHeader}-${textLinkLabel}`}
          href={textLink}
          {...{
            s_objectid: `${tabHeader}:${textLinkLabel}`,
          }}
          data-activity-map={JSON.stringify({
            vars: {
              events: 'event24',
              eVar: 'eVar1',
              list1: `${tabHeader}:${textLinkLabel}`,
            },
          })}
        >
          <span>{textLinkLabel}</span>
        </a>
      </div>
    </Conditional>
  );
};

export default DesktopMenuLinks;
