import React from 'react';
import { findComponent } from '@next-app/utils';
import classes from './product-listing.module.scss';
import PLPBreadcrumbs from '@next-app/components/Breadcrumbs/Breadcrumb';
import LocationRefinementMenu from '../../Facets/SelectStore';
import GuidedNavigation from '../GuidedNavigation/GuidedNavigation';
import StrictCategoryListingTitle from '../StrictCategoryListing/StrictCategoryListingTitle';
import ResultsList from '../ResultList/ResultsList';
import { IProductListingProps } from '@next-app/interface/PLP/ProductListPage';
import {
  IPageContentSlot,
  PageContentFacets,
  PageContentStrictCategoryListingTitle,
  ProductResultsList,
} from '@next-app/interface/PLP';
import Conditional from '@next-app/components/Conditional';
import Button from '@next-app/components/elements/Button/Button';
import ProductCheckBalanceModal from '@next-app/components/CheckBalanceModal/CheckBalanceModal';
import ImageBanner from '../ImageBanner/ImageBanner';

type DynamicComponentType = React.ComponentType<{
  contentItem: PageContentStrictCategoryListingTitle | IPageContentSlot;
}>;

const ProductsListing = (props: IProductListingProps) => {
  const imageBannerData = props.contentItem['PageContent-ImageBanner'];
  const productListInfoData =
    props?.contentItem['PageContent-ProductListing']?.productListingInfo || [];

  const resultListData = props?.contentItem['PageContent-ProductListing']
    ?.resultList || [{ mainDimension: '' }];

  const metaTag = props?.contentItem['PageContent-ProductListing']?.metaTag;

  const guidedNavigationData = props?.contentItem['PageContent-ProductListing']
    ?.guidedNavigation || [{ facets: [] }];
  resultListData[0].mainDimension = productListInfoData[0]?.mainDimension;

  const headerData =
    props?.contentItem['PageContent-ProductListing']?.header || [];

  const ProductListInfoComponent = productListInfoData?.map(
    (item: PageContentStrictCategoryListingTitle | IPageContentSlot) => {
      const DynamicComponent = findComponent(item) as DynamicComponentType;
      return <DynamicComponent contentItem={item} key={item['@type']} />;
    },
  );

  const HeaderBannerComponent = headerData?.map((item: IPageContentSlot) => {
    const DynamicComponent = findComponent(item) as DynamicComponentType;
    return <DynamicComponent contentItem={item} key={item['@type']} />;
  });

  const Section = ProductListInfoComponent.find(({ key }: React.JSX.Element) =>
    key?.includes('Section'),
  );

  const ContactForm = ProductListInfoComponent?.find(
    ({ key }: React.JSX.Element) => key?.includes('PageContent-ContactForm'),
  );

  const PLPBreadcrumb = props?.contentItem[
    'PageContent-ProductListing'
  ]?.resultList.map((e: ProductResultsList) => {
    return (
      <PLPBreadcrumbs
        key="PLPBreadcrumbs"
        data={e.selectedNavigationBreadcrumbs}
      />
    );
  });

  const GuidedNavigationLocation = guidedNavigationData[0]?.facets?.map(
    (item: PageContentFacets) => {
      if (item?.['@type'] === 'PageContent-LocationRefinementMenu') {
        return (
          <LocationRefinementMenu
            key={'LocationRefinementMenu'}
            contentItem={item}
            isMobileLocationNav
          />
        );
      }
    },
  );

  return (
    <div className={classes['plp-container']}>
      <div className="breadcrumb">{PLPBreadcrumb}</div>
      <Conditional
        if={
          resultListData &&
          (resultListData[0]['hasEGiftCardRecords'] ||
            resultListData[0]['hasGiftCardRecords'])
        }
      >
        <div className={classes['gift-card-banner']}>
          <ProductCheckBalanceModal />
          {HeaderBannerComponent[0]}
          <Button
            data-bs-toggle="modal"
            data-bs-target="#checkBalanceModal"
            data-testid="handle-show"
            type="button"
            customClass={classes['gift-card-btn']}
          >
            Check Your Balance
          </Button>
        </div>
      </Conditional>
      <div className={classes['plp-container-box']}>
        <div className={classes['product-guided-nav']}>
          {
            <GuidedNavigation
              key="GuidedNav"
              contentItem={guidedNavigationData[0]}
            />
          }
        </div>
        <div className={classes['product-layout']}>
          <Conditional if={imageBannerData}>
            <ImageBanner contentItem={imageBannerData} />
          </Conditional>
          <div className={classes['product-list']}>
            <Conditional
              if={
                !(
                  resultListData &&
                  (resultListData[0]['hasEGiftCardRecords'] ||
                    resultListData[0]['hasGiftCardRecords'])
                ) && productListInfoData[0]?.mainDimension
              }
            >
              <>
                <StrictCategoryListingTitle
                  contentItem={productListInfoData[0]}
                />
                <Conditional
                  if={
                    ProductListInfoComponent &&
                    ProductListInfoComponent[1]?.type
                  }
                >
                  <div
                    className={`${classes['plp-seoData']} d-none d-md-block d-lg-none`}
                  >
                    {ProductListInfoComponent[1]}
                  </div>
                </Conditional>
              </>
            </Conditional>

            <div className={classes['store-result-list']}>
              <Conditional
                if={
                  productListInfoData[0]?.mainDimension &&
                  resultListData &&
                  (resultListData[0]['hasEGiftCardRecords'] ||
                    resultListData[0]['hasGiftCardRecords'])
                }
              >
                <StrictCategoryListingTitle
                  contentItem={productListInfoData[0]}
                />
              </Conditional>
              {GuidedNavigationLocation}
              <Conditional if={Section}>{Section}</Conditional>
              <Conditional if={ContactForm}>{ContactForm}</Conditional>
              <div className="d-none d-md-block">
                <hr />
              </div>
              <div className={`${classes['plp-seoData']} d-none d-lg-block`}>
                <Conditional
                  if={
                    ProductListInfoComponent &&
                    ProductListInfoComponent[1]?.type
                  }
                >
                  <>{ProductListInfoComponent[1]}</>
                </Conditional>
              </div>
              <ResultsList
                isGSAUser={props.isGSAUser}
                view={props.view}
                contentItem={resultListData[0]}
                guidedNavigation={guidedNavigationData}
                metaTag={metaTag}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsListing;
