'use client';

import { BREAKPOINTS } from '@shared/constants';
import Image from 'next/image';
import { useSelector } from 'react-redux';

interface ResponsiveImageProps {
  mobileImagePath: string;
  tabletImagePath: string;
  desktopImagePath: string;
  imagePath?: string;
  altText: string;
  className?: string;
  isFullUrl: boolean;
  imgAssetFolder?: string;
  [key: string]: any;
}

/**
 * Renders a responsive image component based on the provided image paths and other props.
 *
 * @param {ResponsiveImageProps} props - The props object containing the following properties:
 *   - mobileImagePath: The path to the mobile image.
 *   - tabletImagePath: The path to the tablet image.
 *   - desktopImagePath: The path to the desktop image.
 *   - imagePath: The path to the default image.
 *   - altText: The alternative text for the image.
 *   - className: The CSS class name for the image.
 *   - otherProps: Any other additional props to be spread onto the Image component.
 * @return {JSX.Element} The JSX element representing the responsive image component.
 */
export default function ResponsiveImageClient({
  mobileImagePath,
  tabletImagePath,
  desktopImagePath,
  altText,
  className,
  isFullUrl = false,
  imgAssetFolder,
  ...otherProps
}: ResponsiveImageProps) {
  const { init } = useSelector((state: any) => state.init);
  if (!init.initResponse) return;
  let mobileUrl = mobileImagePath;
  let tabUrl = tabletImagePath;
  let webUrl = desktopImagePath;

  let assetFolder;
  let scene7Env;
  let scene7BaseURL;

  if (init.initResponse && isFullUrl) {
    assetFolder = init.initResponse.sceneSevenImageUrlPrefix;
    scene7Env = init.initResponse.scene7Env;
    scene7BaseURL = init.initResponse.scene7BaseURL;

    const imgBaseUrl = `${scene7BaseURL}${imgAssetFolder ? imgAssetFolder : assetFolder}/${scene7Env}`;
    mobileUrl = `${imgBaseUrl}/${mobileImagePath}`;
    tabUrl = `${imgBaseUrl}/${tabletImagePath}`;
    webUrl = `${imgBaseUrl}/${desktopImagePath}`;
  }

  return (
    <picture>
      <source
        media={`(min-width: ${BREAKPOINTS.DESKTOP})`}
        srcSet={webUrl}
      ></source>

      <source
        media={`(min-width: ${BREAKPOINTS.TABLET})`}
        srcSet={tabUrl}
      ></source>

      <source
        media={`(min-width: ${BREAKPOINTS.MOBILE})`}
        srcSet={mobileUrl}
      ></source>

      <Image
        src={webUrl}
        alt={altText}
        className={className}
        loading="lazy"
        quality={100}
        {...otherProps}
      />
    </picture>
  );
}
