'use client';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ProductRecsHTML from './ProductRecsHTML';
import { IProductDataCarouselData } from '@next-app/interface/PageContent';
import Conditional from '@next-app/components/Conditional';
import SkeletonUI from '@next-app/components/Skeleton/Skeleton';
import { isBrowser } from '@next-app/utils/common-utils';

interface GetAlgonomyDataProps {
  propsData: {
    placementId: string;
    cartRecommProducts: string;
    showTitle: boolean;
    recommendationTitle: string;
    page: string;
    s_regionId?: string;
  };
}

const GetAlgonomyData = ({ propsData }: GetAlgonomyDataProps) => {
  const { placementId, page, s_regionId } = propsData;

  const [title, setTitle] = useState('');
  const stateData = useSelector((state: any) => state.init);
  const { sessionInfo, productRecsData } = stateData;

  const [showSkeleton, setShowSkeleton] = useState(true);

  const [productDataCarouselData, setProductDataCarouselData] = useState<
    IProductDataCarouselData | undefined
  >();

  const sessionId = '';

  useEffect(() => {
    if (isBrowser()) {
      if ((window as any).algonomyPropsData?.indexOf(placementId) === -1) {
        (window as any).algonomyPropsData.push(propsData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (productRecsData && productRecsData.length > 0) {
      productRecsData.map((data: any) => {
        if (data.placementId === placementId) {
          setTitle(data.title);
          setProductDataCarouselData({ recsProductInfo: data.recsProductInfo });
          setShowSkeleton(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productRecsData]);

  return (
    <>
      <Conditional if={showSkeleton}>
        <>
          <SkeletonUI
            width={'30%'}
            height={45}
            containerClass={'w-100 d-block d-flex justify-content-center'}
          />
          <br />
          <SkeletonUI
            width={'100%'}
            height={265}
            count={5}
            containerClass={'d-flex gap-1'}
          />
        </>
      </Conditional>
      <Conditional
        if={
          productDataCarouselData !== undefined &&
          Object.keys(productDataCarouselData).length > 0
        }
      >
        <ProductRecsHTML
          titleHeading={title}
          sessionId={sessionId}
          productDataCarousel={productDataCarouselData}
          addToCartFromRecommendation={
            sessionInfo?.sessionInfo?.featureToggles
              ?.addToCartFromRecommendation
          }
          gsaUser={sessionInfo?.sessionInfo?.profile?.gsaUser}
          page={page}
          s_regionId={s_regionId}
        />
      </Conditional>
    </>
  );
};

export default GetAlgonomyData;
