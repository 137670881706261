import { MenuLinkProps } from '@next-app/interface/Common';
import MenuLinkImage from './MenuLinkImages';

const MenuLink = ({
  contentItem,
  tabHeader,
  displayInMobile,
}: MenuLinkProps) => {
  return (
    <MenuLinkImage
      contentItem={contentItem}
      displayInMobile={displayInMobile}
      tabHeader={tabHeader}
    />
  );
};

export default MenuLink;
