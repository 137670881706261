'use client';

import React, { useState, useEffect, useRef } from 'react';
import Link from '@next-app/components/elements/Link/Link';
import HTMLReactParser from 'html-react-parser';
import {
  setStoreLocation,
  getStoresByZip,
  getTomtomData,
} from '@next-app/utils/get-store-data';
import { ISkuDetails, Stores } from '@next-app/interface/StoreList';
import {
  locationError,
  buttonElementNames,
  linkElementNames,
  anchorElementName,
  headingElementName,
} from '@next-app/config/elementNameConfig';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../elements/Modal/Modal';
import Button from '@next-app/components/elements/Button/Button';
import Input from '@next-app/components/elements/Input/Input';
import Classes from './store-card.module.scss';
import { setData } from '@next-app/lib/store';
import Conditional from '@next-app/components/Conditional';
import { MakeStoreDTMEvent } from '@next-app/utils/dtm-event';
import constants, { StorePickupType } from '@next-app/constants/constants';
import { SeoItem } from '@next-app/interface/Common';
import { getUniqueKey } from '@next-app/utils';
import {
  getMasterProductId,
  selectAllPDPData,
  selectPdpMetaTagValues,
} from '@next-app/lib/features/PDP/PDPSelectors';
import { PDPState } from '@next-app/lib/features/PDP/PDPSlice.interface';
import { updateStoreOnlyStoreSelection } from '@next-app/lib/features/PDP/ProductSlice';

interface CardProps {
  portalId: string;
  cartId: string | null;
  profileId: string | null;
  zipCode: string;
  country: string;
  setZipcode: any;
  setShow?: any;
  setData: any;
  data: any;
  isMobileView?: boolean;
  showFindStoreModal?: boolean | any;
  setStoreUserMessage?: any;
  handleClose?: () => void;
  storeUserMessage: { [key: string]: string };
  userLatitude?: string | number;
  userLongitude?: string | number;
  setShowFindStoreModal?: any;
  plpModalTitle?: boolean;
  blueHeader?: boolean;
  isPDPModal?: boolean;
  handlePDPStoreModal?: Function;
}

const Card = (props: CardProps) => {
  const [error, setError] = useState<string>();
  const [findStoreError, setFindStoreError] = useState<string>();
  const [isSearchAttempt, setSearchAttempt] = useState<boolean>(false);
  const [makeYourStoreError, setMakeYourStoreError] = useState('');
  const [attemptStore, setAttemptStore] = useState({});

  const [expandedItems, setExpandedItems] = useState<{
    [key: number]: boolean;
  }>({});

  const [isMobileTab, setIsMobileTab] = useState(false);
  const ref = useRef<string | Element>('');

  const initial = useSelector((state: any) => state.init);

  const masterProductId = useSelector(getMasterProductId);
  const pdpMetaTagValues = useSelector((state: PDPState) =>
    selectPdpMetaTagValues(state, masterProductId),
  );
  const allPDPData = useSelector((state: PDPState) =>
    selectAllPDPData(state, masterProductId),
  );

  const { init, sessionInfo, seoData } = initial;
  const dispatch = useDispatch();
  const {
    initResponse: {
      tomTomInfo: { tomTomURL = '', tomTomKey = '' } = {},
      internalAPI = '',
    } = {},
  } = init || {};

  const {
    portalId,
    cartId,
    profileId,
    setShow = () => {},
    setZipcode,
    zipCode,
    country,
    setData: setModalData,
    data,
    showFindStoreModal,
    setStoreUserMessage,
    storeUserMessage,
    userLatitude,
    userLongitude,
    setShowFindStoreModal,
    plpModalTitle,
    blueHeader,
    handlePDPStoreModal,
    isPDPModal = false,
  } = props;

  const { dynSess: { dynSessConfNumber = '' } = {} } =
    sessionInfo.sessionInfo || {};

  const handleMakeStore = async (value: any) => {
    const { id, stateAddress, name, updateConfirmed = false } = value;
    const body = {
      ...(cartId && { cartId }),
      ...(profileId && { profileId }),
      storeId: id,
      storeName: name,
      storeState: stateAddress,
      successURL: '',
      errorURL: '',
      _dynSessConf: dynSessConfNumber,
      ...(updateConfirmed && { updateConfirmed: true }),
    };

    const makeStoreApiRes = await setStoreLocation(body);

    if (makeStoreApiRes?.formError) {
      const error = makeStoreApiRes?.formExceptions[0]?.localizedMessage || '';
      setMakeYourStoreError(error);
    } else {
      if (makeStoreApiRes?.stringSessionConfirmationNumber) {
        const updatedSessionInfo = {
          ...sessionInfo,
          sessionInfo: {
            ...sessionInfo.sessionInfo,
            dynSess: {
              dynSessConfNumber:
                makeStoreApiRes.stringSessionConfirmationNumber,
            },
          },
        };
        dispatch(
          setData({
            ...initial,
            sessionInfo: updatedSessionInfo,
          }),
        );
        await setStoreLocation({
          ...body,
          _dynSessConf: makeStoreApiRes.stringSessionConfirmationNumber,
        });
      } else {
        setMakeYourStoreError('');
        setAttemptStore({});
        const updateStoreInSess = {
          ...sessionInfo,
          sessionInfo: {
            ...sessionInfo.sessionInfo,
            profile: {
              ...sessionInfo.sessionInfo.profile,
              selectedStore: makeStoreApiRes,
            },
            userSegmentsMap: makeStoreApiRes.userSegmentsMap,
          },
        };

        dispatch(
          setData({
            ...initial,
            sessionInfo: updateStoreInSess,
          }),
        );
        setShowFindStoreModal && setShowFindStoreModal(false);
        setSearchAttempt(false);
        setFindStoreError('');
        setError('');
        setModalData([]);
        setZipcode('');

        setShow && setShow(false);

        const allSeoData: SeoItem[] = seoData;
        const page = allSeoData.find((item) => item.url === '/');

        if (isPDPModal) {
          const { productId, product: { displayName = '' } = {} } =
            allPDPData[1];
          dispatch(updateStoreOnlyStoreSelection({ productId, status: false }));
          MakeStoreDTMEvent({
            id: makeStoreApiRes?.selectedStoreId,
            city: makeStoreApiRes?.city,
            state: makeStoreApiRes?.state,
            internalAPI,
            isFurniture: sessionInfo?.sessionInfo?.furniture,
            pageName: pdpMetaTagValues?.title,
            product: productId,
            productName: displayName,
            from: 'pdp',
            name: 'selectStoreEvent',
            pageSection: 'products',
            pageType: 'product',
          });
        } else {
          MakeStoreDTMEvent({
            id: makeStoreApiRes?.selectedStoreId,
            city: makeStoreApiRes?.city,
            state: makeStoreApiRes?.state,
            internalAPI,
            isFurniture: sessionInfo?.sessionInfo?.furniture,
            pageName: page?.pageName || '',
            from: 'Header',
          });
        }
        window.bootstrap.Modal.getInstance(ref.current)?.hide();
      }
    }
  };

  const handleSearchPostalCode = async () => {
    setSearchAttempt(true);

    if (zipCode) {
      await setModalData([]);
      setFindStoreError('');
      const tomtomData = await getTomtomData({
        countryCode: country || 'US',
        postalCode: zipCode,
        key: tomTomKey,
        tomTomURL,
      });

      let longValue = '';
      let latValue = '';

      if (tomtomData?.results?.length) {
        const { lon, lat } = tomtomData.results[0].position;
        latValue = lat;
        longValue = lon;
      }

      const storeData = await getStoresByZip(
        {
          longitude: longValue,
          latitude: latValue,
          internalAPI,
          dynSessConfNumber: dynSessConfNumber,
        },
        zipCode,
      );

      if (storeData.formError) {
        setFindStoreError(storeData?.formExceptions[0]?.localizedMessage);
        setModalData([]);
      } else {
        let storeList: Stores[] = storeData?.stores;
        if (isPDPModal && handlePDPStoreModal) {
          storeList = await handlePDPStoreModal(storeList);
        }
        setFindStoreError('');
        setModalData(storeList);
        setStoreUserMessage(storeData?.userMessages);
      }
    } else {
      setModalData([]);
      setFindStoreError('Please enter a valid zip code.');
    }
  };

  const handleGetLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          if (position.coords.latitude && position.coords.longitude) {
            setError('');
            const storeList = await getStoresByZip({
              internalAPI,
              longitude: position.coords.longitude,
              latitude: position.coords.latitude,
              dynSessConfNumber: dynSessConfNumber,
            });
            if (storeList.formError) {
              setFindStoreError(storeList.formExceptions[0].localizedMessage);
              setModalData([]);
            } else {
              const storeData: Stores[] | any = storeList.stores;
              setFindStoreError('');
              setModalData(storeData);
              setStoreUserMessage(storeList.userMessages);
            }
          }
        },
        () => {
          setError(locationError);
        },
      );
    } else {
      setError(locationError);
    }
  };

  const toggleExpand = (index: number) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const checkInStock = (storePickupDetails: ISkuDetails) => {
    if (
      storePickupDetails.isInStorePickupEligible &&
      storePickupDetails.stockLevel === 'Available'
    ) {
      return false;
    }
    return true;
  };

  const getInStockValue = (storePickupDetails: ISkuDetails) => {
    if (!storePickupDetails?.isInStorePickupEligible) {
      return (
        <span className={Classes['stock-inEligible']}>
          Not Eligible for In‑Store Pickup
        </span>
      );
    } else if (
      storePickupDetails?.isInStorePickupEligible &&
      storePickupDetails?.stockLevel === 'Available'
    ) {
      return <span className={Classes['stock-available']}>In Stock</span>;
    } else if (storePickupDetails?.stockLevel === 'Unavailable') {
      return <span className={Classes['stock-unavailable']}>Sold Out</span>;
    } else if (storePickupDetails?.stockLevel === 'LimitedStock') {
      return (
        <>
          <span className={Classes['stock-unavailable']}>Limited Stock</span>
          <br />
          <span>(call store for details)</span>
        </>
      );
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsMobileTab(true);
      } else {
        setIsMobileTab(false);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobileTab]);

  useEffect(() => {
    if (!Object.keys(sessionInfo).length) {
      return;
    }
    async function fetchData() {
      try {
        if (userLongitude && userLatitude) {
          setError('');
          const storeList = await getStoresByZip({
            internalAPI,
            longitude: userLongitude,
            latitude: userLatitude,
            dynSessConfNumber: dynSessConfNumber,
          });
          if (storeList.formError) {
            setFindStoreError(storeList.formExceptions[0].localizedMessage);
            setModalData([]);
          } else {
            let storeData: Stores[] | any = storeList.stores;
            if (isPDPModal && handlePDPStoreModal) {
              storeData = await handlePDPStoreModal(storeData);
            }
            setFindStoreError('');
            setModalData(storeData);
            setStoreUserMessage(storeList.userMessages);
          }
        }
      } catch (error) {
        console.error('Error fetching store data:', error);
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userLongitude,
    userLatitude,
    error,
    dynSessConfNumber,
    isMobileTab,
    setStoreUserMessage,
    sessionInfo,
    internalAPI,
    setModalData,
    showFindStoreModal,
  ]);

  const handleModalClose = () => {
    setModalData([]);
    setFindStoreError('');
    setError('');
    setSearchAttempt(false);
    setZipcode('');
    setMakeYourStoreError('');
    setAttemptStore({});
    setShow && setShow(false);
  };

  let modalTitle = '';

  if (plpModalTitle) {
    modalTitle = headingElementName.selectStore;
  } else {
    if (isMobileTab) {
      if (showFindStoreModal) {
        modalTitle = headingElementName.findStore;
      } else {
        modalTitle = headingElementName.chooseStore;
      }
    } else if (plpModalTitle) {
      modalTitle = headingElementName.selectStore;
    } else {
      modalTitle = headingElementName.findAnotherStore;
    }
  }

  const isSelectedStore =
    sessionInfo?.sessionInfo?.profile?.selectedStore &&
    Object.keys(sessionInfo?.sessionInfo?.profile?.selectedStore).length > 0;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearchPostalCode();
    }
  };

  let gsaModal = document.getElementById(portalId);
  gsaModal?.addEventListener('hidden.bs.modal', function () {
    handleModalClose();
  });

  const validateHandler = (e: any) => {
    const value = e.target.value;
    const isNumber = value === '' || /^[0-9-]+$/.test(value);
    if (isNumber && !value.toLowerCase().includes('e')) {
      setZipcode(value);
    } else {
      setZipcode(zipCode);
    }
  };

  return (
    <Modal
      id={portalId}
      modalTitle={modalTitle}
      showFooterSection={false}
      showHeader={true}
      headerClass={`modal-header ${blueHeader ? Classes['blue-header'] : ''}`}
      titleClass="modal-title"
      dialogClass={`modal-dialog ${Classes['store-modal-dialog']} ${isPDPModal ? Classes['store-pdp-modal-dailog'] : ''}`}
      wrapperClass={Classes['store-modal-wrapper']}
      contentClass={`${Classes.modalContainer} ${blueHeader ? Classes['selected-store-modal'] : ''} ${plpModalTitle ? Classes.plpModalContainer : ''} modal-content find-store-modal`}
      headingTagName="3"
      modalClass={`${Classes.mainModalClass}`}
      centerClass="modal-dialog-centered"
      closeModalOnClick={handleModalClose}
      ref={ref}
    >
      <Conditional if={!isMobileTab || isPDPModal}>
        <h4 className={Classes.subHeading}>
          {headingElementName.chooseLocation}
        </h4>
      </Conditional>
      <Conditional if={!!error && error?.length > 0 && error !== 'undefined'}>
        <p className={Classes.errorMsg}>{error}</p>
      </Conditional>
      <Conditional if={isMobileTab && !isPDPModal}>
        <div className={Classes['locationIconWrp']}>
          <a
            className="text-decoration-underline link-dark link-underline-opacity-100"
            aria-label="Use My Current Location"
            onClick={handleGetLocation}
            data-testid="current-location"
          >
            <span className={Classes.locationIcon} />
            {anchorElementName.currentLocation}
          </a>
        </div>
      </Conditional>

      <div className={`row g-3 ${Classes.inputContainer}`}>
        <div className={Classes['store-search-input-wrapper']}>
          <div className={`col-7 pr0 ${isPDPModal ? 'col-sm-3' : 'col-sm-5'}`}>
            <Input
              customClass={Classes['inputClass']}
              type="text"
              placeholder="Enter Zip Code"
              automation-id="search_input"
              data-testid="search-postal-code-input"
              id={`${getUniqueKey(1, 'search_input')}`}
              onKeyDown={handleKeyDown}
              maxLength={10}
              onChange={(e: {
                target: { value: React.SetStateAction<string> };
              }) => validateHandler(e)}
              value={zipCode}
            />
          </div>
          <div className="col-5">
            <Button
              type="button"
              onClick={handleSearchPostalCode}
              datatestid="search-postal-code"
              automation-id="search_button"
            >
              {buttonElementNames.search}
            </Button>
          </div>
        </div>
        <Conditional
          if={
            !!findStoreError &&
            findStoreError?.length > 0 &&
            findStoreError !== 'undefined'
          }
        >
          <p className={Classes.errorMsg}>{findStoreError}</p>
        </Conditional>
      </div>
      <Conditional if={!!makeYourStoreError}>
        <div className={Classes['change-store']}>
          <div className="change-store-msg">
            {HTMLReactParser(`${HTMLReactParser(`${makeYourStoreError}`)}`)}
          </div>
          <div className={Classes['change-store-wrapper']}>
            <Button
              onClick={() =>
                handleMakeStore({ ...attemptStore, updateConfirmed: true })
              }
              datatestid={`change-store-button`}
              customClass={`order-md-2 ${Classes['make-your-store-button']}`}
            >
              Change Store
            </Button>

            <Button
              customClass={`  ${Classes['cancel-change-store']}`}
              data-bs-dismiss="modal"
              variant="ternary"
              onClick={() => handleModalClose()}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Conditional>

      <Conditional if={data?.length > 0}>
        <div
          className={`${Classes.listingSection} ${makeYourStoreError ? Classes['change-store-list'] : ''}`}
        >
          {data.map((el: any, i: number) => {
            const {
              city,
              stateAddress,
              address1,
              hours,
              postalCode,
              phoneNumber,
              pickupType,
              seoUrl,
              storePickupDetails,
              id,
              name,
              messageKey,
            } = el;

            const hoursData = hours && HTMLReactParser(hours);
            const storePickup =
              storeUserMessage?.[StorePickupType?.[pickupType]];
            const isSame =
              isSelectedStore &&
              sessionInfo?.sessionInfo?.profile?.selectedStore?.id === id;

            return (
              <div className={Classes['pannelList']} key={i}>
                <div>
                  <h4 className="font-bold hover:text-zinc-300">{`${name}`}</h4>
                  <p>{address1}</p>
                  <p>{`${city}, ${stateAddress} ${postalCode}`}</p>
                </div>

                <div className="collapse" id={`collapse${i}`}>
                  <p>
                    <strong>Phone:</strong> {phoneNumber}
                  </p>
                  {hoursData}
                </div>

                <div className="flex space-x-2">
                  <Conditional if={isMobileTab}>
                    <>
                      <div className="icon-text d-flex align-items-center pt-1 mb-3">
                        <span className="bopis-icons d-flex align-items-center">
                          <span className={Classes['instoreIcon']}></span>
                          <span className={Classes['plusIcon']}></span>
                          <span className={Classes['curbsideIcon']}></span>
                        </span>
                        <span className={Classes['msgText']}>
                          {storePickup}
                        </span>
                      </div>
                      <Conditional if={isPDPModal}>
                        <div className="pb-2">
                          {getInStockValue(storePickupDetails)}
                        </div>
                      </Conditional>
                    </>
                  </Conditional>

                  <div
                    className={`d-flex align-items-center justify-content-between ${Classes.ctaSectionModal}`}
                  >
                    <Button
                      disabled={
                        isSame ||
                        (isPDPModal && checkInStock(storePickupDetails))
                      }
                      onClick={() => {
                        handleMakeStore(el);
                        setAttemptStore(el);
                      }}
                      datatestid={`card-store-button-${i}`}
                      variant="secondary"
                      customClass={Classes['make-your-store-button']}
                    >
                      {isSame
                        ? buttonElementNames.yourStore
                        : buttonElementNames.makeYourStore}
                    </Button>
                    <div className="viewInfoCta">
                      <a
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${i}`}
                        aria-controls={`collapse${i}`}
                        className={Classes.btnLink}
                        onClick={() => toggleExpand(i)}
                      >
                        {expandedItems[i]
                          ? anchorElementName.lessInfo
                          : anchorElementName.moreInfo}
                      </a>
                      {' | '}
                      <Link className={Classes.btnLink} href={seoUrl}>
                        {linkElementNames.viewMap}
                      </Link>
                    </div>
                  </div>

                  <Conditional if={!isMobileTab}>
                    <>
                      <div
                        className={`pb-2 col-sm-3 ${Classes['stock-eligibility']}`}
                      >
                        <Conditional if={isPDPModal}>
                          <div>{getInStockValue(storePickupDetails)}</div>
                        </Conditional>
                      </div>
                      <div className="icon-text d-flex align-items-center pb-2 pt-1">
                        <span className="bopis-icons d-flex align-items-center">
                          <span className={Classes['instoreIcon']}></span>
                          <span className={Classes['plusIcon']}></span>
                          <span className={Classes['curbsideIcon']}></span>
                        </span>
                        <span className={Classes['msgText']}>
                          {storePickup}
                        </span>
                      </div>
                    </>
                  </Conditional>
                </div>
                <Conditional
                  if={messageKey === 'ROPIS_STORES_CLOSE_UNEXPECTED'}
                >
                  <div className={Classes['ropis-store-close']}>
                    {constants.ROPIS_STORES_CLOSE_UNEXPECTED}
                  </div>
                </Conditional>
              </div>
            );
          })}
        </div>
      </Conditional>
    </Modal>
  );
};

export default Card;
