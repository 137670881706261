type ConditionalProps = {
  if: boolean;
  children: React.JSX.Element;
};

const Conditional = (props: ConditionalProps): React.JSX.Element | null => {
  return !!props.if && props.children ? props.children : null;
};

export default Conditional;
