import { useState, useEffect } from 'react';
import Heading from '@next-app/components/elements/Heading/Heading';
import MobileHTMLRecommendations from './MobileHTMLRecommendations';
import HTMLRecommendations from './HTMLRecommendations';
import classes from './product-recs-HTML.module.scss';
import { IProductDataCarouselData } from '@next-app/interface/PageContent';
import Conditional from '@next-app/components/Conditional';

interface ProductRecsHTMLProps {
  titleHeading: string;
  productDataCarousel: IProductDataCarouselData | undefined;
  sessionId: string;
  addToCartFromRecommendation: boolean;
  page: string;
  gsaUser: boolean;
  s_regionId?: string;
}

const ProductRecsHTML = (props: ProductRecsHTMLProps) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTab, setIsTab] = useState(false);
  const [isWeb, setIsWeb] = useState(true);
  const {
    titleHeading,
    sessionId,
    productDataCarousel,
    addToCartFromRecommendation,
    page,
    gsaUser,
    s_regionId,
  } = props;

  const end = 5;

  const [sliceData, setSliceData] = useState([]);

  useEffect(() => {
    if (
      productDataCarousel &&
      productDataCarousel.recsProductInfo &&
      productDataCarousel.recsProductInfo.length > 0
    ) {
      const slicedData: any = sliceDataHandler(
        productDataCarousel?.recsProductInfo,
        end,
      );
      setSliceData(slicedData);
    }
  }, [productDataCarousel]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 415) {
        setIsMobile(true);
        setIsTab(false);
        setIsWeb(false);
      }
      if (window.innerWidth > 415 && window.innerWidth < 1024) {
        setIsMobile(false);
        setIsTab(true);
        setIsWeb(false);
      }
      if (window.innerWidth > 1024) {
        setIsMobile(false);
        setIsTab(false);
        setIsWeb(true);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const sliceDataHandler = (array: any, sliceSize: number) => {
    const slicedArr = [];
    for (let i = 0; i < array.length; i += sliceSize) {
      slicedArr.push(array.slice(i, i + sliceSize));
    }
    return slicedArr;
  };

  const atcRecsEnabled = addToCartFromRecommendation && page == 'AddedToCart';

  return (
    <div className="container">
      <section className={`row ${classes.productSection}`}>
        <div className="col-12">
          <div className="row">
            <div className="col-12">
              <div className="text-center">
                <Heading customClass={classes.productHeading} tagName="2">
                  {titleHeading}
                </Heading>
              </div>
              <Conditional if={isMobile || isTab}>
                <MobileHTMLRecommendations
                  {...{
                    atcRecsEnabled,
                    gsaUser,
                    productDataCarousel,
                    s_regionId,
                  }}
                />
              </Conditional>
              <Conditional if={isWeb}>
                <HTMLRecommendations
                  {...{
                    atcRecsEnabled,
                    gsaUser,
                    sliceData,
                    sessionId,
                    page,
                    s_regionId,
                  }}
                />
              </Conditional>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProductRecsHTML;
