'use client';

import { COOKIE_EXPIRY_TIME } from '@next-app/constants/constants';
import classes from './product-card.module.scss';
import { setCookie } from '@next-app/utils/cookies';
import { useEffect } from 'react';
import Conditional from '@next-app/components/Conditional';
import { useRouter } from 'next/navigation';

const ProductListGridView = (props: {
  listingView: boolean;
  listingViewOptionTemplate: string;
  hasEGiftCardRecords: boolean | undefined;
  hasGiftCardRecords: boolean | undefined;
}) => {
  const { listingView, hasGiftCardRecords, hasEGiftCardRecords } = props;

  const router = useRouter();

  const handleView = (view: string) => {
    setCookie('view', view, COOKIE_EXPIRY_TIME);
    const url = new URL(window.location.href);
    url.searchParams.set('view', view);
    router.push(url.pathname + url.search);
  };

  useEffect(() => {
    const value = listingView ? 'list' : 'grid';
    setCookie('view', value, COOKIE_EXPIRY_TIME);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Conditional if={!hasEGiftCardRecords && !hasGiftCardRecords}>
      <div className={classes['views']}>
        <button
          data-testid="grid-view-button"
          className={`${classes.viewsBtn} ${classes.gridView} ${listingView ? classes['inactive-view'] : ''}`}
          onClick={() => handleView('grid')}
        >
          <i className={`ls-icon ${classes['icn-grid']}`}></i>
        </button>
        <button
          data-testid="list-view-button"
          className={`${classes.viewsBtn} ${classes.gridList} ${!listingView ? classes['inactive-view'] : ''}`}
          onClick={(e) => {
            e.preventDefault();
            handleView('list');
          }}
        >
          <i className={`ls-icon ${classes['icn-list']}`}></i>
        </button>
      </div>
    </Conditional>
  );
};

export default ProductListGridView;
