import { FooterCertificationContent } from '@next-app/interface/PageContent';
import { FooterLeft, FooterRight } from '@next-app/interface/Footer';
import { findComponent, getUniqueKey } from '@next-app/utils';
import classes from './section-footer.module.scss';
import SectionFooterApp from './SectionFooterApp';
import Link from '../Link/Link';

interface SectionFooterProps {
  contentItem: FooterCertificationContent;
}

export default function SectionFooter(props: SectionFooterProps) {
  const { footerLeft, footerRight } = props.contentItem;
  const footerLeftComponentList = footerLeft?.map(
    (item: FooterLeft, index: number) => {
      const Component = findComponent(item);
      if (!Component) {
        return null;
      }
      return (
        <Component contentItem={item} key={getUniqueKey(index, item.name)} />
      );
    },
  );

  function findAnniversaryBadge(footerLeft: any): any | null {
    return (
      footerLeft?.reduce((foundBadge: any, section: any) => {
        if (foundBadge) return foundBadge;

        return section.content?.reduce((foundBadge: any, contentItem: any) => {
          if (foundBadge) return foundBadge;

          return contentItem.columns?.reduce((foundBadge: any, column: any) => {
            return (
              foundBadge ||
              column.links?.find(
                (link: { id: string }) => link.id === '70thAnnniversaryBadge',
              ) ||
              null
            );
          }, null);
        }, null);
      }, null) || null
    );
  }

  const footerRightComponentList = footerRight?.map(
    (item: FooterRight, index: number) => {
      const Component = findComponent(item);
      if (!Component) {
        return null;
      }
      return (
        <Component contentItem={item} key={getUniqueKey(index, item.name)} />
      );
    },
  );

  const anniversaryBadge = findAnniversaryBadge(footerLeft);

  const attribute = {
    s_regionid: 'Footer - Main',
  };

  return (
    <footer id="footer" className={classes['footer-wrapper']} {...attribute}>
      <div className={`${classes.container} three-column`}>
        <div className="row">
          <div className={`${classes['footer-links']} col-lg-8 col-md-7`}>
            <div className="row tight accordion" id="accordion">
              {footerLeftComponentList}
            </div>
          </div>

          <div className={`${classes['footer-contact']} col-lg-4 col-md-5`}>
            <div className="row tight">{footerRightComponentList}</div>
            <div className={`${classes['footer-badge']}`}>
              <Link contentItem={anniversaryBadge} />
            </div>
          </div>
          <SectionFooterApp />
        </div>
      </div>
    </footer>
  );
}
