import { findComponent, getUniqueKey } from '@next-app/utils';
import {
  IGuidedNavigationProps,
  PageContentFacets,
} from '@next-app/interface/PLP';

type DynamicComponentType = React.ComponentType<{
  contentItem: PageContentFacets;
  index: number | string;
  setFilterCount?: Function;
  setUpdatedUrl?: Function;
  getFilterLabel?: Function;
  isFilter?: string;
  setIsFilter?: Function;
  setFilterLabel?: Function;
  filterLabel?: string;
  setSelectedFilter?: Function;
  assortmentTitle?: string;
}>;

const GuidedNavigation = (props: IGuidedNavigationProps) => {
  let { facets: list = [] } = props?.contentItem || {};
  if (list?.length === 0) {
    list = props?.contentItem?.contents?.[0]?.facets || [];
  }

  const {
    getFilterLabel,
    setFilterCount,
    setUpdatedUrl,
    setIsFilter,
    isFilter,
    setFilterLabel,
    filterLabel,
    setSelectedFilter,
    assortmentTitle,
  } = props;

  const OneColComponentList = list?.map(
    (item: PageContentFacets, index: number) => {
      const DynamicComponent = findComponent(item) as DynamicComponentType;
      return (
        <DynamicComponent
          contentItem={item}
          getFilterLabel={getFilterLabel}
          index={index}
          key={getUniqueKey(index, item.name)}
          setFilterCount={setFilterCount}
          setUpdatedUrl={setUpdatedUrl}
          setIsFilter={setIsFilter}
          isFilter={isFilter}
          setFilterLabel={setFilterLabel}
          filterLabel={filterLabel}
          setSelectedFilter={setSelectedFilter}
          assortmentTitle={assortmentTitle}
        />
      );
    },
  );

  return OneColComponentList;
};

export default GuidedNavigation;
