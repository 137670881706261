'use client';

import { useState } from 'react';
import { useRouter } from 'next/navigation';
import styles from './facets.module.scss';
import Conditional from '@next-app/components/Conditional';
import { useSelector } from 'react-redux';
import { getStoresByZip } from '@next-app/utils/get-store-data';
import Checkbox from '@next-app/components/elements/Checkbox/Checkbox';
import Modal from '@next-app/components/elements/Modal/Modal';
import HTMLReactParser from 'html-react-parser/lib/index';
import classes from '../PageContent/LocationRefinementMenu/location-refinement-menu.module.scss';
import Facets from './Facets';
import Card from '../PageContent/AccountMenuLink/StoreCard';
import SkeletonUI from '@next-app/components/Skeleton/Skeleton';
import { PageContentFacets } from '@next-app/interface/PLP/index';
import Link from '@next-app/components/elements/Link/Link';
import { isNotEmptyObject } from '../ProductDetails/helper';
import { trimURL } from '@next-app/utils/get-guided-data';
import { dyEvent } from '@next-app/utils/dy-event';
import {
  newLocationRefinement,
  replaceNarrowBy,
  handleClick,
  getClickLinkProps,
} from './helper';

import constants from '@next-app/constants/constants';

interface ISelectStoreProps {
  contentItem: PageContentFacets;
  isMobileLocationNav: boolean;
}

const SelectStore = (props: ISelectStoreProps) => {
  const [zipCode, setZipcode] = useState<string | null>('');
  const [showFindStoreModal, setShowFindStoreModal] = useState<Boolean>(false);
  const [data, setData] = useState([]);
  const [storeUserMessage, setStoreUserMessage] = useState({});
  const [isStore, setIsStore] = useState<{ [key: string]: boolean }>({
    inStoreSku: false,
    shipOrderSku: false,
  });
  const router = useRouter();
  const { contentItem: item, isMobileLocationNav } = props || {};

  const dynamicModalId = isMobileLocationNav
    ? 'findAnotherStorePlpMobile'
    : 'findAnotherStorePlp';

  const { sessionInfo, init } = useSelector((state: any) => state.init);
  const { sessionInfo: { profile: { gsaUser = false } = {} } = {} } =
    sessionInfo || {};

  const itemNaming: { [index: string]: string } = {
    inStoreSku: isMobileLocationNav ? 'Store Availability' : 'Store Pickup',
    shipOrderSku: 'Ship Item',
  };

  const newRefinement = newLocationRefinement(item);
  const isStoreSelected =
    sessionInfo?.sessionInfo?.profile?.selectedStore &&
    isNotEmptyObject(sessionInfo?.sessionInfo?.profile?.selectedStore);

  let city = '';
  let state = '';
  if (isStoreSelected) {
    city = sessionInfo?.sessionInfo?.profile?.selectedStore?.city;
    state = sessionInfo?.sessionInfo?.profile?.selectedStore?.state;
  }

  let cardProps: any = {
    zipCode,
    data,
    showFindStoreModal,
    isMobileView: true,
    storeUserMessage,
  };

  const handleShow = async () => {
    const {
      profile: { selectedStore, id, address },
      cartInfo,
      dynSess: { dynSessConfNumber },
    } = sessionInfo.sessionInfo;

    const internalAPI = init?.initResponse?.internalAPI;

    let country = '';
    if (address && address?.country) {
      country = address?.country;
    }

    const storeId = selectedStore?.id || '';
    const name = selectedStore?.name || '';
    const longitude = selectedStore?.longitude;
    const latitude = selectedStore?.latitude;

    const { cartId } = cartInfo;
    const isStoreSelected = isNotEmptyObject(selectedStore);

    if (isStoreSelected) {
      const storeList = await getStoresByZip({
        internalAPI,
        longitude,
        latitude,
        dynSessConfNumber,
      });
      const storeData = storeList.stores;
      setShowFindStoreModal(false);
      setStoreUserMessage(storeList.userMessages);
      await setData(storeData);
    }
    cardProps = {
      ...cardProps,
      cartId: cartId || '',
      profileId: id,
      storeId,
      storeName: name,
      storeState: state,
      dynSessConfNumber,
      country: country,
    };
  };

  const handleStoreState = (field: any, value: boolean) => {
    setIsStore((prev: any) => ({
      ...prev,
      [field]: !value,
    }));
  };

  const handleDYEvent = (value: string) => {
    const properties = {
      dyType: 'filter-items-v1',
      filterType: replaceNarrowBy(item.name),
      filterStringValue: value,
    };
    dyEvent({ properties: properties, eventName: 'Filter Items' });
  };

  const AvailabilityMobView = () => {
    return (
      <Conditional
        if={
          item?.refinements?.length > 0 &&
          isMobileLocationNav &&
          item?.dimensionName === 'skuShipType'
        }
      >
        <div className={styles['toggle-container-wrapper']}>
          {newRefinement?.map((subItem, idx: number) => (
            <div key={idx} className={styles['toggle-container']}>
              <Conditional if={subItem.label === 'inStoreSku'}>
                <div>
                  <Conditional if={isStoreSelected}>
                    <Link
                      onClick={() => router.push(trimURL(subItem?.link))}
                      data-testid="store-link"
                      className={`${styles.mobileToggle} ${subItem?.properties?.selected === 'true' ? styles.active : ''}`}
                    ></Link>
                  </Conditional>
                  <Conditional if={!isStoreSelected}>
                    <span
                      data-testid="mobileToggle-span"
                      onClick={() => handleShow()}
                      data-bs-toggle="modal"
                      data-bs-target={'#' + dynamicModalId}
                      className={styles.mobileToggle}
                    ></span>
                  </Conditional>
                  <span>
                    {itemNaming[subItem.label] || subItem.label} (
                    {subItem.count})
                  </span>
                  <span>
                    <span
                      data-bs-toggle="modal"
                      data-bs-target="#storePickUpModal"
                      data-testid="popover-tool-tip-n"
                      className={`ls-icon ${classes['icnHelpOtl']} ${styles['primary-color']}`}
                    ></span>
                  </span>
                </div>
              </Conditional>
              <Conditional if={idx === 1 && !isNotEmptyObject(sessionInfo)}>
                <SkeletonUI width={150} height={15} />
              </Conditional>
              <Conditional if={idx === 1 && isNotEmptyObject(sessionInfo)}>
                <div>
                  <a
                    onClick={() => handleShow()}
                    data-bs-toggle="modal"
                    className={`${styles['primary-color']} ${styles.selectstore}`}
                    data-bs-target={'#' + dynamicModalId}
                  >
                    {isStoreSelected ? `${city}, ${state}` : 'Select Store'}
                  </a>
                  {subItem.count === 0 && (
                    <p>{constants.messageTextValue.ROPIS_NO_ELIGIBLE_ITEMS}</p>
                  )}
                </div>
              </Conditional>
            </div>
          ))}
        </div>
      </Conditional>
    );
  };

  const AvailabilityWebView = () => {
    return (
      <Conditional if={item?.refinements?.length > 0 && !isMobileLocationNav}>
        <div className={styles.container}>
          <h2 className={styles.title}>{item.name}</h2>
          <div className={styles.subContainer}>
            <ul className={styles.list}>
              <Conditional if={!!item?.mainCategoryDisplay}>
                <Link
                  className={styles.link}
                  href={trimURL(item?.mainCategoryLink)}
                >
                  {item.mainCategoryDisplay}
                </Link>
              </Conditional>
              <Conditional
                if={
                  item?.refinements?.length > 0 &&
                  item.dimensionName !== 'skuShipType'
                }
              >
                <Facets item={props.contentItem} />
              </Conditional>
              <Conditional
                if={
                  item?.refinements?.length > 0 &&
                  item.dimensionName === 'skuShipType'
                }
              >
                <div className="narrow-by-availability-desktop">
                  {newRefinement?.map((subItem, idx: number) => (
                    <li key={idx} className={styles.contentItem}>
                      <div className={styles.linkbox}>
                        <Conditional if={subItem.count === 0}>
                          <div className={styles['narrow-disable']}>
                            <Checkbox
                              label={''}
                              checked={subItem?.properties?.selected === 'true'}
                              onChange={() => {}}
                              disabled={subItem.count === 0}
                            />
                            <Link
                              className={`${styles.checkboxlabel}`}
                              dangerouslySetInnerHTML={{
                                __html: `${itemNaming[subItem.label] || subItem.label} (${subItem.count})`,
                              }}
                            />
                          </div>
                        </Conditional>
                        <Conditional if={subItem.count !== 0}>
                          <>
                            <Conditional if={subItem.label !== 'inStoreSku'}>
                              <div
                                className={styles.linkbox}
                                onClick={() => {
                                  router.push(trimURL(subItem?.link));
                                  handleDYEvent(
                                    itemNaming[subItem.label] || subItem.label,
                                  );
                                  handleStoreState(
                                    subItem.label,
                                    !!isStore[subItem.label],
                                  );
                                }}
                              >
                                <Link
                                  {...(subItem?.properties?.selected !==
                                    'true' &&
                                    getClickLinkProps(
                                      subItem?.link,
                                      handleClick,
                                      router,
                                      true,
                                    ))}
                                >
                                  <Checkbox
                                    label={''}
                                    checked={
                                      subItem?.properties?.selected ===
                                        'true' || isStore[subItem.label]
                                    }
                                    onChange={() =>
                                      router.push(trimURL(subItem?.link))
                                    }
                                  />
                                </Link>
                                <Link
                                  className={styles.checkboxlabel}
                                  {...(subItem?.properties?.selected !==
                                    'true' &&
                                    getClickLinkProps(
                                      subItem?.link,
                                      handleClick,
                                      router,
                                      true,
                                    ))}
                                  dangerouslySetInnerHTML={{
                                    __html: `${itemNaming[subItem.label] || subItem.label} (${subItem.count})`,
                                  }}
                                />
                              </div>
                            </Conditional>
                            <Conditional
                              if={
                                isStoreSelected &&
                                subItem.label === 'inStoreSku'
                              }
                            >
                              <div
                                className={styles.linkbox}
                                onClick={() => {
                                  router.push(trimURL(subItem?.link));
                                  handleDYEvent(
                                    itemNaming[subItem.label] || subItem.label,
                                  );
                                  handleStoreState(
                                    subItem.label,
                                    !!isStore[subItem.label],
                                  );
                                }}
                              >
                                <Link
                                  {...(subItem?.properties?.selected !==
                                    'true' &&
                                    getClickLinkProps(
                                      subItem?.link,
                                      handleClick,
                                      router,
                                      true,
                                    ))}
                                >
                                  <Checkbox
                                    data-testid="storeSku-checkbox"
                                    label={''}
                                    checked={
                                      subItem?.properties?.selected ===
                                        'true' || isStore[subItem.label]
                                    }
                                    onChange={() =>
                                      router.push(trimURL(subItem?.link))
                                    }
                                  />
                                </Link>
                                <Link
                                  className={styles.checkboxlabel}
                                  {...(subItem?.properties?.selected !==
                                    'true' &&
                                    getClickLinkProps(
                                      subItem?.link,
                                      handleClick,
                                      router,
                                      true,
                                    ))}
                                  dangerouslySetInnerHTML={{
                                    __html: `${itemNaming[subItem.label] || subItem.label} (${subItem.count})`,
                                  }}
                                />
                              </div>
                            </Conditional>
                            <Conditional
                              if={
                                !isStoreSelected &&
                                subItem.label === 'inStoreSku'
                              }
                            >
                              <div
                                onClick={() => handleShow()}
                                data-bs-toggle="modal"
                                data-bs-target={'#' + dynamicModalId}
                                className={styles['store-pickup']}
                              >
                                <Checkbox
                                  label={''}
                                  checked={
                                    subItem?.properties?.selected === 'true'
                                  }
                                  onChange={() => {}}
                                />
                                <Link
                                  className={styles.checkboxlabel}
                                  dangerouslySetInnerHTML={{
                                    __html: `${itemNaming[subItem.label] || subItem.label} (${subItem.count})`,
                                  }}
                                />
                              </div>
                            </Conditional>
                          </>
                        </Conditional>
                        <Conditional if={subItem.label === 'inStoreSku'}>
                          <div className={classes['store-info-icon']}>
                            <Link
                              data-bs-toggle="modal"
                              data-bs-target="#storePickUpModal"
                              data-testid="popover-tool-tip-n"
                            >
                              <Link
                                className={`ls-icon ${classes['icnHelpOtl']}`}
                              ></Link>
                            </Link>
                          </div>
                        </Conditional>
                      </div>
                      <Conditional
                        if={idx === 1 && !isNotEmptyObject(sessionInfo)}
                      >
                        <SkeletonUI width={150} height={15} />
                      </Conditional>
                      <Conditional
                        if={idx === 1 && isNotEmptyObject(sessionInfo)}
                      >
                        <div className={styles['selectstore-desktop']}>
                          <Link
                            onClick={() => handleShow()}
                            data-bs-toggle="modal"
                            data-bs-target={'#' + dynamicModalId}
                            className={`${styles['primary-color']}`}
                          >
                            {isStoreSelected
                              ? `${city}, ${state}`
                              : 'Select Store'}
                          </Link>

                          <Conditional if={subItem.count === 0}>
                            <p>
                              {
                                constants?.messageTextValue
                                  ?.ROPIS_NO_ELIGIBLE_ITEMS
                              }
                            </p>
                          </Conditional>
                        </div>
                      </Conditional>
                    </li>
                  ))}
                </div>
              </Conditional>
            </ul>
          </div>
        </div>
      </Conditional>
    );
  };

  if (!isNotEmptyObject(sessionInfo)) {
    return (
      <>
        <Conditional if={!isMobileLocationNav}>
          <div className={styles.SkeletonUI}>{AvailabilityWebView()}</div>
        </Conditional>
        <Conditional if={isMobileLocationNav}>
          <div className={styles.SkeletonUI}>{AvailabilityMobView()}</div>
        </Conditional>
      </>
    );
  }

  return (
    <div className={classes.storeAvailable}>
      <Conditional if={!gsaUser}>
        <>
          {AvailabilityWebView()}
          {AvailabilityMobView()}
        </>
      </Conditional>
      <Modal
        showHeader={true}
        id="storePickUpModal"
        dialogClass={`modal-dialog ${classes['store-pick-up-modal']}`}
        contentClass={`modal-content`}
        headingTagName="4"
        wrapperClass={`${classes['store-pick-up-modal-wrapper']}`}
        modalTitle="Buy Online…Pick Up in Store!"
        closeButtonId="modalCloseButton"
        closeModalOnClick={() => {}}
      >
        <span
          className={`${classes['store-pick-up-modal-description']}`}
          dangerouslySetInnerHTML={{
            __html: HTMLReactParser(
              `${constants.messageTextValue.STORE_PICKUP_INFO}`,
            ),
          }}
        />
      </Modal>
      <Card
        portalId={dynamicModalId}
        setZipcode={setZipcode}
        setData={setData}
        setStoreUserMessage={setStoreUserMessage}
        setShowFindStoreModal={setShowFindStoreModal}
        zipCode={zipCode}
        data={data}
        plpModalTitle={true}
        blueHeader={true}
        {...cardProps}
      />
    </div>
  );
};

export default SelectStore;
