import urlMapping from '@next-app/config/urlMapping';
import { makeFetchCall } from './fetch-data';

// TO get the suggestion stores
export const getStoresByZip = async (
  {
    longitude = '',
    latitude = '',
    internalAPI,
    dynSessConfNumber,
  }: {
    longitude: number | string;
    latitude: number | string;
    internalAPI?: string;
    dynSessConfNumber?: number;
  },
  zipCode = '',
) => {
  try {
    const epochTime = Date.now();
    const response = await makeFetchCall({
      endpoint: `${internalAPI}${urlMapping.findStoreUrl}`,
      params: {
        latitude,
        longitude,
        zipCode: zipCode,
        context: 'store-selector',
        _dynSessConf: dynSessConfNumber,
        _: epochTime,
      },
    });
    return response;
  } catch (error) {
    console.log('Error fetching Store by Zip::', error);
    return error;
  }
};

// To apply the selected store
export const setStoreLocation = async (body: any) => {
  try {
    const response = await makeFetchCall({
      endpoint: urlMapping.applyStoreUrl,
      options: {
        method: 'POST',
      },
      body,
    });
    return response;
  } catch (error) {
    console.error('Error to Set the Store', error);
  }
};

// Tom Tom API
export const getTomtomData = async ({
  countryCode,
  postalCode,
  key,
  tomTomURL,
}: {
  countryCode: string;
  postalCode: string;
  key: string;
  tomTomURL: string;
}) => {
  try {
    const url = `${tomTomURL}?countryCode=${countryCode}&postalCode=${postalCode}&key=${key}`;
    const response = await fetch(url);
    const newData = await response.json();
    return newData;
  } catch (error) {
    console.error('Error fetching tomtom data:', error);
    return error;
  }
};
