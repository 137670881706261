'use client';

import { useState } from 'react';
import Card from '../PageContent/AccountMenuLink/StoreCard';
import { getStoresByZip } from '@next-app/utils/get-store-data';
import { Stores } from '@next-app/interface/StoreList';
import { useSelector } from 'react-redux';
import classes from './hamburger.module.scss';

function getLocation(setLatitude: any, setLongitude: any) {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      reject(new Error('Geolocation is not supported by your browser'));
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          return reject(error);
        },
      );
    }
  });
}

export default function Hamburger() {
  const [showFindStoreModal, setShowFindStoreModal] = useState<Boolean>(false);
  const [zipCode, setZipcode] = useState<string | null>('');
  const [data, setData] = useState([]);
  const [locations, setLocation] = useState({});
  const [locationError, setLocationError] = useState(false);
  const [storeUserMessage, setStoreUserMessage] = useState({});
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();

  const { sessionInfo, init }: any = useSelector((state: any) => state.init);

  if (!Object.keys(sessionInfo).length || !Object.keys(init).length) {
    return;
  }

  let cardProps: any = {
    zipCode,
    data,
    showFindStoreModal,
    isMobileView: true,
    locations,
    locationError,
    storeUserMessage,
  };

  const handleMobileMenu = () => {
    let menuEle = document.querySelector('#nav');
    let mpPusher = document.querySelector('#mp-pusher');
    if (menuEle && mpPusher) {
      menuEle.classList.toggle('mobileMenuActive');
      mpPusher.classList.toggle('mp-pushed');
    }
  };

  const handleCloseMobileMenu = () => {
    let menuEle = document.querySelector('#nav');
    if (menuEle && menuEle.classList.contains('mobileMenuActive')) {
      menuEle.classList.remove('mobileMenuActive');
    }
  };

  const handleClose = () => {
    setShowFindStoreModal(false);
  };

  const handleShow = async () => {
    const {
      profile: { selectedStore, id, address },
      cartInfo,
      dynSess: { dynSessConfNumber },
    } = sessionInfo.sessionInfo;

    const {
      initResponse: { internalAPI },
    } = init;

    let country = '';
    if (address && address?.country) {
      country = address?.country;
    }

    const state = selectedStore?.state || '';
    const storeId = selectedStore?.id || '';
    const name = selectedStore?.name || '';
    const longitude = selectedStore?.longitude;
    const latitude = selectedStore?.latitude;

    const { cartId } = cartInfo;
    const isStoreSelected = Object.keys(selectedStore).length > 0;

    cardProps = {
      ...cardProps,
      cartId: cartId || '',
      profileId: id,
      storeId,
      storeName: name,
      storeState: state,
      dynSessConfNumber,
      country: country,
    };

    if (isStoreSelected) {
      const storeList = await getStoresByZip({
        internalAPI,
        longitude,
        latitude,
        dynSessConfNumber,
      });
      const storeData: Stores[] | any = storeList.stores;
      setShowFindStoreModal(false);
      setStoreUserMessage(storeList.userMessages);
      await setData(storeData);
    } else {
      setShowFindStoreModal(true);
      try {
        const resp: any = await getLocation(setLatitude, setLongitude);
        setLocation({ longitude: resp?.longitude, latitude: resp?.latitude });
      } catch (error: any) {
        setLocationError(true);
      }
    }
  };

  return (
    <div
      className={`${classes['mobileHeader']} vertical-center headerHamburger`}
    >
      <div
        data-testid="bars-icon-mobile"
        className={`${classes['bars-icon']}`}
        onClick={handleMobileMenu}
      >
        <a
          className={`${classes.trigger} navigation-menu-bar`}
          data-testid="navigation-menu-trigger"
          id="trigger"
          aria-label="Open Navigation Menu"
          automation-id="hamburger_menu"
          role="button"
        >
          <span></span>
          <span></span>
          <span className="mb0"></span>
        </a>
        <button type="button" className="close-navigation-menu-bar">
          <span className={`${classes.closeIcon}`}></span>
        </button>
      </div>
      <span
        className={`${classes['location-icon']}`}
        data-bs-toggle="modal"
        data-bs-target="#findAnotherStoreHamburgerMenu"
        onClick={handleShow}
      >
        <a
          className="text-center"
          aria-label="Your Store"
          data-original-title=""
          title=""
          role="button"
        >
          <span className={`${classes['store-icon']}`}></span>
        </a>
        <div className="row">
          <div className="col-xs-12">
            <h3 className="mt0">
              <a
                className={`${classes['close']} popover-close mt-10 lh44`}
                aria-label="Close"
                data-testid="close-popover"
                data-dismiss="popover"
                onClick={handleCloseMobileMenu}
              >
                &times;
              </a>
            </h3>
          </div>
        </div>
      </span>
      {
        <Card
          portalId="findAnotherStoreHamburgerMenu"
          setZipcode={setZipcode}
          setData={setData}
          handleClose={handleClose}
          setStoreUserMessage={setStoreUserMessage}
          setShowFindStoreModal={setShowFindStoreModal}
          userLongitude={longitude}
          userLatitude={latitude}
          {...cardProps}
        />
      }
    </div>
  );
}
