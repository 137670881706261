'use client';

import { useSelector } from 'react-redux';
import { usePathname, useRouter } from 'next/navigation';
import { useEffect } from 'react';

import {
  addRouteIsLoadingClass,
  addTrailingSlash,
  isValidUrl,
  removeRouteIsLoadingClass,
  resetDesktopMenu,
  resetHamburgerMenu,
} from '@next-app/utils/common-utils';
import { withoutSlash } from '@next-app/utils/common-utils';
import { ProductCardDTMEvent } from '@next-app/utils/dtm-event';
import { setActivityMapLink } from '@next-app/utils/set-adobe-activity-map';

export const trackAnalyticsEvent = (event: MouseEvent) => {
  const clickedElement = event?.target as HTMLElement | null;

  // Ensure the clicked element is not null
  if (clickedElement) {
    // Find the closest element with the 'data-analytics-attribute'
    const closestElementWithAnalytics = clickedElement.closest(
      '[data-analytics-attribute]',
    ) as HTMLElement | null;

    if (closestElementWithAnalytics) {
      const dataAnalytics = closestElementWithAnalytics.getAttribute(
        'data-analytics-attribute',
      );

      if (dataAnalytics) {
        const dataToDTM = JSON.parse(dataAnalytics);
        ProductCardDTMEvent(dataToDTM);
      }
    }
  }
};

export const getAdobeAttribute = (data: string) => {
  const adobeAttribute = JSON.parse(data);

  if (Object.keys(adobeAttribute).length > 0) {
    return {
      data: adobeAttribute?.vars || {},
      targetUrl: adobeAttribute?.targetUrl || '',
      isExitLink: adobeAttribute?.isExitLink || false,
      preventPinWindow: adobeAttribute?.preventPinWindow || false,
    };
  }
  return '';
};

export function RouteChangeListener() {
  const router = useRouter();
  const pathname = usePathname();
  const {
    init: { initResponse },
    sessionInfo,
  } = useSelector((state: any) => state.init);

  const loginStatus = sessionInfo?.sessionInfo?.profile?.loginStatus;
  const securityStatus = sessionInfo?.sessionInfo?.profile?.securityStatus || 0;

  useEffect(() => {
    sessionStorage.removeItem('reloadedForReact');
  }, []);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const controlClicked = event.ctrlKey || event.metaKey ? true : false;
      if (event.target instanceof Element) {
        trackAnalyticsEvent(event);
        const closestAnchorTag = event.target.closest('a');
        const href = closestAnchorTag?.getAttribute('href');
        const target = closestAnchorTag?.getAttribute('target');
        const typeAheadProductClick = closestAnchorTag?.getAttribute(
          'data-TYPE_AHEAD_PRODUCT_CLICK',
        );
        const carouselDtmFlag = closestAnchorTag?.getAttribute(
          'data-carouselDtmFlag',
        );
        const internalSearchTermTypeAhead = closestAnchorTag?.getAttribute(
          'data-INTERNAL_SEARCH_TERM_TYPEAHEAD',
        );
        const anchorDataAttribute =
          closestAnchorTag?.getAttribute('data-redirect');
        const hrefWithoutSlash = withoutSlash(href || '');

        // get adobe attribute to set the adobeActivityMap
        const activityAdobeMap = event.target
          .closest('[data-activity-map]')
          ?.getAttribute('data-activity-map');
        const adobeAttribute: any =
          activityAdobeMap && getAdobeAttribute(activityAdobeMap || '{}');

        if (
          adobeAttribute &&
          adobeAttribute !== null &&
          adobeAttribute !== '' &&
          Object.keys(adobeAttribute).length
        ) {
          const { data } = adobeAttribute || {};
          setActivityMapLink(event.target, data);
        }

        if (
          href !== '' &&
          href !== null &&
          href !== undefined &&
          anchorDataAttribute !== 'false'
        ) {
          event.preventDefault();
          event.stopImmediatePropagation();
          const authPages = initResponse?.authPages || [];
          if (authPages.includes(hrefWithoutSlash) && securityStatus < 4) {
            // Open signIn modal
            new window.bootstrap.Modal(
              document.getElementById('signInModal') as HTMLElement,
            ).show();
          } else {
            if (href === '/' || isValidUrl(href)) {
              // Route if the page has been migrated to React
              resetHamburgerMenu();
              resetDesktopMenu();
              if (controlClicked) window.open(href, '_blank');
              else {
                // push href to router only if clicked href is not same as current page url
                if (addTrailingSlash(pathname) !== addTrailingSlash(href)) {
                  router.push(href);
                  addRouteIsLoadingClass(href);
                }
              }

              if (typeAheadProductClick === 'true') {
                sessionStorage.setItem('searchDtm', 'typeaheadProductClick');
              } else if (carouselDtmFlag === 'true') {
                sessionStorage.setItem(carouselDtmFlag, 'true');
              } else if (internalSearchTermTypeAhead === 'true') {
                sessionStorage.setItem(
                  'searchDtm',
                  'internalSearchTermTypeAhead',
                );
              }
            } else {
              if (
                closestAnchorTag?.getAttribute('data-react-referrer') === 'true'
              ) {
                sessionStorage.setItem(
                  'reactReferrerUrl',
                  window.location.href,
                );
              }
              if (controlClicked || target == '_blank') {
                window.open(href, '_blank');
              } else {
                window.location.href = href.endsWith('/') ? href : `${href}/`;
              }
            }
          }
        }
      }
    };

    document.addEventListener('click', handleClick);

    // Cleanup listener on component unmount
    return () => {
      document.removeEventListener('click', handleClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initResponse, loginStatus, securityStatus, pathname]);

  useEffect(() => {
    removeRouteIsLoadingClass();
  }, [pathname]);

  return null;
}

export default RouteChangeListener;
