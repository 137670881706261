import React from 'react';
import classes from './review-list.module.scss';

const ReviewStars = ({ rating }: { rating: number }) => {
  return (
    <span className={classes['stars']}>
      <span
        className={classes['gold']}
        style={{ width: `${rating * 20}px` }}
      ></span>
    </span>
  );
};

export default ReviewStars;
