'use client';

import React from 'react';
import { useSelector } from 'react-redux';

import Carousel from '@next-app/components/shared/Carousel/Carousel';
import ResponsiveImageClient from '@next-app/components/elements/ResponsiveImageClient/ResponsiveImageClient';
import Conditional from '@next-app/components/Conditional';
import Link from '@next-app/components/elements/Link/Link';
import ReviewStars from '../PDPReviews/ReviewStars';
import PDPCarouselButton from './PDPCarouselButton';
import classes from './pdp-carousels.module.scss';
import { CarouselProducts } from '@next-app/interface/PDP/PDPRecentlyViewedProducts';
import constants from '@next-app/constants/constants';
const PDPCarouselItem = ({
  recommendationTitle,
  chunkedRecommendations,
  carouselId,
  classnameItem,
  classnameItemName,
  classnameItemButton,
  mboxid,
  fallbackRecs,
}: {
  recommendationTitle: string;
  chunkedRecommendations: CarouselProducts[][];
  carouselId: string;
  classnameItem?: string;
  classnameItemName?: string;
  classnameItemButton?: string;
  mboxid?: string;
  fallbackRecs?: boolean;
}) => {
  const { STORE_PICKUP_ONLY } = constants.LLConstants;
  const { sessionInfo } = useSelector((state: any) => state.init);
  const { sessionInfo: { profile: { gsaUser = false } = {} } = {} } =
    sessionInfo || {};
  return (
    <>
      <h2 className={classes.title}>{recommendationTitle}</h2>
      <div
        className={classes.carousel}
        data-fallbackRecs={fallbackRecs}
        data-placementId={mboxid}
      >
        <Carousel
          carouselId={carouselId}
          sliceData={chunkedRecommendations}
          dataBsRide={false}
          dataBsWrap={false}
          dataBsInterval={5000}
          dataBsKeyboard={true}
          dataBsPause={false}
          dataBstouch={true}
        >
          {chunkedRecommendations.map((group, groupIndex) => (
            <div
              key={`recommendationSlide-${groupIndex}`}
              className={`carousel-item ${groupIndex === 0 ? 'active' : ''}`}
            >
              <div className="row justify-content-center h-100">
                <div className={`${classes.item} col-lg-10 d-flex p-0`}>
                  {group?.map((item, itemIndex) => (
                    <div
                      key={`recommendation-${itemIndex}`}
                      className={`${classes['carousel-items']} item-wrapper ${classnameItem}`}
                    >
                      <div
                        className={`${classes['product-name']} ${classnameItemName}`}
                      >
                        <Link
                          data-carouselDtmFlag={true}
                          href={item?.seoURL}
                          onClick={() => (window.location.href = item?.seoURL)}
                        >
                          <ResponsiveImageClient
                            mobileImagePath={item?.imageURL}
                            tabletImagePath={item?.imageURL}
                            desktopImagePath={item?.imageURL}
                            altText={item?.altText}
                            isFullUrl={false}
                            fill={true}
                            className="position-static"
                          />
                          <Conditional if={item?.storeOnly}>
                            <span className={classes['store-pickup']}>
                              {STORE_PICKUP_ONLY}
                            </span>
                          </Conditional>
                          <span
                            className={classes['item-name']}
                            dangerouslySetInnerHTML={{ __html: item?.name }}
                          ></span>
                        </Link>
                        {/* for sale */}
                        <Conditional if={item?.isOnSale && !item?.hasGSAPrice}>
                          <p
                            className={`${classes['product-price-original']} }`}
                          >
                            <span
                              className={`${classes['product-price-sale']}`}
                            >
                              {item?.salePriceRange}
                            </span>
                            <span
                              className={`${classes['product-price-reg']} }`}
                            >{`Reg. ${item?.listPriceRange}`}</span>
                          </p>
                        </Conditional>

                        {/* for GSA */}
                        <Conditional if={gsaUser && item?.hasGSAPrice}>
                          <p
                            className={`${classes['product-price']} ${classes['product-price-gsa']}`}
                          >
                            {item?.salePriceRange} GSA
                          </p>
                        </Conditional>

                        {/* normal */}
                        <Conditional if={!item?.hasGSAPrice && !item?.isOnSale}>
                          <p className={`${classes['product-price']} }`}>
                            {item?.listPriceRange}
                          </p>
                        </Conditional>
                      </div>
                      <div className={classes['product-detail']}>
                        <Conditional if={!!(item?.showRating && item?.rating)}>
                          <div className={classes.rating}>
                            <ReviewStars rating={item?.rating} />
                            <span className={classes['rating-count']}>
                              <Link
                                href={item?.seoURL}
                                aria-label="Close"
                                data-bs-dismiss="modal"
                                data-dismiss="dynamicModalWrapper"
                              >
                                ({item?.reviewCount})
                              </Link>
                            </span>
                          </div>
                        </Conditional>
                        <Conditional if={!!item?.presellDate}>
                          <div className={classes['pre-sell-date']}>
                            Available {item?.presellDate}
                          </div>
                        </Conditional>
                        <PDPCarouselButton
                          product={item}
                          customClasses={classnameItemButton}
                          recommendationTitle={recommendationTitle}
                          carouselId={carouselId}
                          mboxid={mboxid}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </>
  );
};

export default PDPCarouselItem;
