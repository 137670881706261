const anchorElementName = {
  currentLocation: 'Use My Current Location',
  moreInfo: 'More Info',
  lessInfo: 'Less Info',
};

const buttonElementNames = {
  findAnotherStore: 'Find Another Store',
  search: 'Search',
  yourStore: 'Your Store',
  makeYourStore: 'Make Your Store',
};

const headingElementName = {
  findStore: 'Find a Store',
  chooseStore: 'Choose a Store',
  findAnotherStore: 'Find Another Store',
  chooseLocation: 'Choose a Location',
  selectStore: 'Select a Store',
};

const linkElementNames = {
  viewMap: 'View Map',
  viewAllStore: 'View All Stores',
};

const popOverContentNames = {
  accountText: 'Account',
  welcomeText: 'Welcome',
  ordersText: 'Orders',
  profileText: 'Profile',
  addressBookText: 'Address Book',
  walletText: 'Wallet',
  shoppingListsText: 'Lists',
  registriesText: 'Registries',
  preferencesText: ' Preferences',
  pointsText: 'Points',
  signOutText: 'Sign Out',
  accountHomeText: 'Account Home',
  earnText: 'EARN',
  redeemText: 'REDEEM',
  saveText: 'SAVE',
  learnMoreText: 'Learn More',
};

const signInModalNames = {
  keepUnchecked: 'Keep unchecked if using a public device or public Wi-Fi',
  certifyAge:
    'By signing in, I certify that I am 18 years of age or older and agree to the ',
  rewardTermCondition: ' Lakeshore Rewards Terms & Conditions',
  privacyPolicy: 'Privacy Policy',
  staySignIn: ` We'll keep you signed in on this device. For your account's security, this is recommended only for personal device(s).`,
  signedInReward: 'Signed up for Lakeshore Rewards in store?',
  newUserAccount: 'New to Lakeshore?',
  createAccount: 'Create Account',
  trackOrder: 'Track orders, create registries, save favorites & more!',
  forgotPass: 'Forgot password?',
  andText: 'and',
  signIn: 'Sign In',
  linkMembership: 'Link Membership',
  passwordExpiryErrorMessage:
    'It looks like your password has expired. Please use the <a data-bs-backdrop="static" data-bs-dismiss="modal" data-bs-keyboard="true" data-bs-toggle="modal" tabindex="0" href="#forgotModal">Forgot Password</a> link to reset your account.',
};

const forgotModalNames = {
  enterMailId: `Please enter your email address, and we’ll send you instructions for resetting your password. 
     If you do not receive an email, the email address you provided may not be registered.`,
  enterCode:
    'Enter the code sent to the email address provided. The code expires at 2:51 am, PT.',
};

const stayConnectedNames: any = {
  signUpSpecialOffer:
    'Sign up for special offers and news sent directly to you.',
  stayConnected: 'Stay Connected',
  seePrivacyPolicy: 'See Privacy Policy',
  classroom: 'Classroom/Organization',
  home: 'Home',
  all: 'Both',
  signUp: 'Sign Up',
};

const createModalNames = {
  staySignIn: ` We'll keep you signed in on this device. For your account's security, this is recommended only for personal device(s).`,
  byRegistry: `By registering, I certify that I am 18 years of age or older and agree to the 
  <a href="/help/rewards-terms/">Lakeshore Rewards Terms &amp; Conditions</a> 
  and <a href="/about/privacy-policy/">Privacy Policy</a>. I also agree to 
  receive promotional emails from Lakeshore`,
  gsaUser: ' I am a GSA Member',
  publicWifi: 'Keep unchecked if using a public device or public Wi-Fi.',
  phoneNumber: `Phone Number <span> Optional (for Lakeshore Rewards lookup) </span>`,
};

const gsaModalNames = {
  gsaAccountHolderText: `GSA Account holders aren't eligible for this program as contract terms already exceed the value of Lakeshore rewards.`,
  staySignIn: ` We'll keep you signed in on this device. For your account's security, this is recommended only for personal device(s).`,
};

const footerAppNames = {
  scanQrCode: 'Scan the QR code to download our app.',
  getTheApp: 'Get the App',
};

export {
  anchorElementName,
  buttonElementNames,
  headingElementName,
  linkElementNames,
  popOverContentNames,
  signInModalNames,
  forgotModalNames,
  stayConnectedNames,
  gsaModalNames,
  createModalNames,
  footerAppNames,
};

export const locationError = `To use your current location, please turn on your device’s location services.`;
