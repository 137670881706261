import Conditional from '@next-app/components/Conditional';
import classes from './menu-item.module.scss';
import { DesktopMenuItemsInterface } from '@next-app/interface/Header';

const DesktopMenuItem = ({
  menuItemOne,
  menuItemTwo,
  menuItems,
  itemHeader,
  itemHeaderUrl,
  columnMaxSize,
  tabHeader,
}: DesktopMenuItemsInterface) => {
  return (
    <Conditional if={menuItems.length > 0}>
      <div className={classes['dropdown-menu']}>
        <a
          automation-id={`${itemHeader}-dropdownmenu`}
          className={classes['dropdown-header']}
          href={itemHeaderUrl}
          {...{ s_objectid: itemHeader }}
          data-activity-map={JSON.stringify({
            vars: { events: 'event24', eVar: 'eVar1', list1: itemHeader },
          })}
        >
          {itemHeader}
        </a>
        <div className="row">
          {columnMaxSize ? (
            <>
              <div className="col-sm-6">
                <ul>
                  {menuItemOne?.map((menuItem: any, index: number) => (
                    <li key={index}>
                      <a
                        automation-id={`${tabHeader}-${menuItem?.displayName}`}
                        href={menuItem.url}
                        {...{
                          s_objectid:
                            `${tabHeader}:${menuItem?.displayName}` || '',
                        }}
                        data-activity-map={JSON.stringify({
                          vars: {
                            events: 'event24',
                            eVar: 'eVar1',
                            list1: `${tabHeader}:${menuItem?.displayName}`,
                          },
                        })}
                      >
                        {menuItem.displayName}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-sm-6">
                <ul>
                  {menuItemTwo?.map((menuItem: any, index: number) => (
                    <li key={index}>
                      <a
                        automation-id={`${tabHeader}-${menuItem?.displayName}`}
                        href={menuItem.url}
                        {...{
                          s_objectid:
                            `${tabHeader}:${menuItem?.displayName}` || '',
                        }}
                        data-activity-map={JSON.stringify({
                          vars: {
                            events: 'event24',
                            eVar: 'eVar1',
                            list1: `${tabHeader}:${menuItem?.displayName}`,
                          },
                        })}
                      >
                        {menuItem.displayName}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          ) : (
            <div className="col-sm-12">
              <ul>
                {menuItems.map((menuItem: any, index: number) => (
                  <li key={index}>
                    <a
                      automation-id={`${tabHeader}-${menuItem?.displayName}`}
                      href={menuItem.url}
                      {...{
                        s_objectid:
                          `${tabHeader}:${menuItem?.displayName}` || '',
                      }}
                      data-activity-map={JSON.stringify({
                        vars: {
                          events: 'event24',
                          eVar: 'eVar1',
                          list1: `${tabHeader}:${menuItem?.displayName}`,
                        },
                      })}
                    >
                      {menuItem.displayName}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </Conditional>
  );
};

export default DesktopMenuItem;
