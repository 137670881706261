import React from 'react';
import {
  IAssortmentListProps,
  PageContentFacets,
} from '@next-app/interface/PLP';
import {
  PageContentAssortmentResultsList,
  PageContentSection,
} from '@next-app/interface/PLP/AssortmentListing';
import StrictCategoryListingTitle from '../StrictCategoryListing/StrictCategoryListingTitle';
import classes from './assortment-listing.module.scss';
import PLPBreadcrumbs from '@next-app/components/Breadcrumbs/Breadcrumb';
import LocationRefinementMenu from '../../Facets/SelectStore';
import AssortmentResultList from '../AssortmentResultsList/AssortmentResultsList';
import GuidedNavigation from '../GuidedNavigation/GuidedNavigation';
import Banner from '../Banner/Banner';
import { findComponent } from '@next-app/utils';
import Conditional from '@next-app/components/Conditional';
import ImageBanner from '../ImageBanner/ImageBanner';

type DynamicComponentType = React.ComponentType<{
  contentItem: PageContentAssortmentResultsList | PageContentSection;
}>;

const AssortmentListing = (props: IAssortmentListProps) => {
  const PLPBreadcrumb: React.JSX.Element[] = [];

  const assortmentResultListData =
    props?.contentItem['PageContent-AssortmentListing']?.assortmentResultsList;

  const guidedNavigationData =
    props?.contentItem['PageContent-AssortmentListing']?.guidedNavigation;

  const bannerData = props?.contentItem['PageContent-Banner'];
  const imageBannerData = props.contentItem['PageContent-ImageBanner'];
  const AssortmentComponent = assortmentResultListData?.map(
    (item: PageContentAssortmentResultsList) => {
      const DynamicComponent = findComponent(item) as DynamicComponentType;
      return <DynamicComponent contentItem={item} key={item['@type']} />;
    },
  );

  const Section = AssortmentComponent?.find(({ key }: React.JSX.Element) =>
    key?.includes('Section'),
  );

  const ContactForm = AssortmentComponent?.find(({ key }: React.JSX.Element) =>
    key?.includes('PageContent-ContactForm'),
  );

  assortmentResultListData?.forEach((e: PageContentAssortmentResultsList) => {
    if (e?.selectedNavigationBreadcrumbs) {
      PLPBreadcrumb.push(
        <PLPBreadcrumbs
          key="PLPBreadcrumbs"
          data={e?.selectedNavigationBreadcrumbs}
        />,
      );
    }
  });
  const GuidedNavigationLocation = guidedNavigationData[0]?.facets?.map(
    (item: PageContentFacets) => {
      if (item?.['@type'] === 'PageContent-LocationRefinementMenu') {
        return (
          <LocationRefinementMenu
            key={'LocationRefinementMenu'}
            contentItem={item}
            isMobileLocationNav
          />
        );
      }
    },
  );

  return (
    <div className="plp-main-container">
      <div className={classes['plp-container']}>
        <div className="breadcrumb">{PLPBreadcrumb}</div>
        <div className={classes['plp-container-box']}>
          <div className={classes['product-guided-nav']}>
            {
              <GuidedNavigation
                contentItem={guidedNavigationData[0]}
                assortmentTitle={assortmentResultListData[0]?.assortmentTitle}
              />
            }
          </div>
          <div className={classes['assortment-layout']}>
            <div className={classes['assortment-plp-layout']}>
              <Conditional if={bannerData}>
                <Banner contentItem={bannerData} />
              </Conditional>

              <Conditional if={imageBannerData}>
                <ImageBanner contentItem={imageBannerData} />
              </Conditional>

              <div className={classes['assortment-list']}>
                <StrictCategoryListingTitle
                  contentItem={{
                    mainDimension: {
                      label: assortmentResultListData[0]?.headerTitle,
                      count: assortmentResultListData[0]?.totalNumRecs,
                      assortmentTitle: props?.metaData?.title,
                    },
                  }}
                />
                <div className={`${classes['product-divider']}`}>
                  <hr />
                </div>
                <div className={classes['store-result-list']}>
                  {GuidedNavigationLocation}
                  <AssortmentResultList
                    view={props.view}
                    isGSAUser={props.isGSAUser}
                    guidedNavigation={guidedNavigationData}
                    contentItem={{
                      ...assortmentResultListData[0],
                      mainDimension: {
                        label: assortmentResultListData[0]?.assortmentTitle,
                        count: assortmentResultListData[0]?.totalNumRecs,
                      },
                    }}
                  />
                </div>
              </div>
              <Conditional if={Section}>{Section}</Conditional>
              <Conditional if={ContactForm}>{ContactForm}</Conditional>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssortmentListing;
