import { makeFetchCall } from '@next-app/utils/fetch-data';
import urlMapping from '@next-app/config/urlMapping';

async function getInitData(cacheType?: string) {
  const queryParams = new URLSearchParams();
  const uri = `${urlMapping.initUrl}?${queryParams.toString()}`;
  const cache = cacheType;
  const initResp = await makeFetchCall({
    endpoint: uri,
    cache,
  });
  return initResp;
}

export default getInitData;
