'use client';

import { findComponent } from '@next-app/utils';
import classes from './HeaderEyebrow.module.scss';
import { useSelector } from 'react-redux';
import { ReactElement, useEffect, useRef, useState } from 'react';
import Conditional from '@next-app/components/Conditional';
import dynamic from 'next/dynamic';
const HTMLContentModal = dynamic(
  () =>
    import('@next-app/components/elements/HTMLContentModal/HTMLContentModal'),
  { ssr: false },
);
interface IHeaderEyebrowMessage {
  '@type': string;
  linkText2: string;
  linkText1: string;
  openModal2: boolean;
  linkUrl1: string;
  linkUrl2: string;
  openModal1: boolean;
  name: string;
  s_objectId1: string;
  text: string;
  modalContentPath1: string;
  modalContentPath2: string;
  s_objectId2: string;
}

interface IHeaderEyebrow {
  contentItem: {
    messages: Array<IHeaderEyebrowMessage>;
  };
  SkeletonComponent: ReactElement<any, any>;
}

const HeaderEyebrow = (props: IHeaderEyebrow) => {
  const [messages, setMessages] = useState(props.contentItem.messages);
  const { headerFooter, sessionInfo } = useSelector((state: any) => state.init);

  const isGsaUser = sessionInfo?.sessionInfo?.profile?.gsaUser;
  const [modalUrl, setModalUrl] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const carouselRef = useRef<any>(null);
  function handleModal(url: string, title: string) {
    setModalTitle(title);
    setModalUrl(url);
  }
  useEffect(() => {
    if (headerFooter && !Object.keys(headerFooter).length) {
      return;
    }

    headerFooter?.headerBlock?.contents[0]?.content?.map(
      (item: {
        '@type': string;
        headerEyebrow: [{ contents: [{ messages: IHeaderEyebrowMessage[] }] }];
      }) => {
        if (item['@type'] === 'PageContent-SectionHeader') {
          if (
            item?.headerEyebrow[0]?.contents[0]?.messages &&
            item?.headerEyebrow[0]?.contents[0]?.messages.length > 0
          ) {
            setMessages(item?.headerEyebrow[0]?.contents[0]?.messages);
          }
        }
      },
    );
  }, [headerFooter]);

  useEffect(() => {
    if (messages && messages.length > 1) {
      setTimeout(() => {
        if (carouselRef && carouselRef.current) carouselRef.current.click();
      }, 3000);
    }
  }, [messages]);

  let ComponentList;

  if (messages && messages.length > 0) {
    ComponentList = messages.map((item: IHeaderEyebrowMessage, key: number) => {
      const Component = findComponent(item);
      if (!Component) return null;
      return (
        <div className={`carousel-item ${key === 0 ? 'active' : ''}`} key={key}>
          <p>
            <Component
              contentItem={item}
              key={key}
              index={key}
              handleModalCallback={handleModal}
            />
          </p>
        </div>
      );
    });
  }

  return (
    <>
      <Conditional if={!isGsaUser}>
        <div className={classes.headerEye}>
          <Conditional if={headerFooter && !Object.keys(headerFooter).length}>
            <>{props.SkeletonComponent}</>
          </Conditional>
          <Conditional
            if={
              messages &&
              messages.length > 1 &&
              headerFooter &&
              Object.keys(headerFooter).length > 0
            }
          >
            <div
              id="carouselExampleControls"
              className={`carousel carousel-dark slide ${classes['eyebrow-carousel']} eyebrow-slider`}
              data-bs-ride="carousel"
              data-bs-pause="false"
            >
              <div
                className={`carousel-inner ${classes['eyebrow-carousel-items']}`}
              >
                {ComponentList}
              </div>

              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="next"
                ref={carouselRef}
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </Conditional>
          <Conditional
            if={
              messages &&
              messages.length === 1 &&
              headerFooter &&
              Object.keys(headerFooter).length > 0
            }
          >
            <>{ComponentList}</>
          </Conditional>
        </div>
      </Conditional>
      <HTMLContentModal
        url={modalUrl}
        modalTitle={modalTitle}
        modalId="seeDetailModal"
      />
    </>
  );
};

export default HeaderEyebrow;
