import {
  findComponent,
  getBlockClassNames,
  getUniqueKey,
} from '@next-app/utils';
import { FooterLinkBlockContent } from '@next-app/interface/PageContent';
import { Column } from '@next-app/interface/Common';
import Heading from '@next-app/components/elements/Heading/Heading';
import classes from './footer-link-block.module.scss';

interface FooterLinkBlockProps {
  contentItem: FooterLinkBlockContent;
}

export default function FooterLinkBlock(props: FooterLinkBlockProps) {
  const { columns, name } = props.contentItem;

  const ColumnsComponentList = columns.map((item: Column, index: number) => {
    const Component = findComponent(item);
    if (!Component) {
      return null;
    }
    return (
      <Component contentItem={item} key={getUniqueKey(index, item.name)} />
    );
  });

  let dataId = name.split(' ').join('');

  return (
    <div
      className={`${getBlockClassNames(props.contentItem)} ${classes['footer-block']}`}
    >
      <Heading
        tagName="3"
        customClass={`${classes['heading-md']} d-none d-md-block`}
      >
        {name}
      </Heading>

      <Heading
        tagName="3"
        customClass={`footer-accordion-header d-block d-md-none ${classes.footerHeading}`}
        id={`${dataId}`}
      >
        <span
          data-bs-toggle="collapse"
          data-bs-target={`#${dataId}Collapse`}
          aria-expanded="false"
          aria-controls="collapseOne"
          className="collapsed"
        >
          {name}
        </span>
      </Heading>
      <div
        id={`${dataId}Collapse`}
        className={`${classes['customer-support']} accordion-collapse collapse d-md-block`}
        aria-labelledby={`#${dataId}`}
        data-bs-parent="#accordion"
      >
        {ColumnsComponentList}
      </div>
    </div>
  );
}
