'use client';

import classes from './button.module.scss';
import Link from '../Link/Link';
interface ButtonProps {
  children?: any;
  onClick?: any;
  onBlur?: () => void;
  onFocus?: () => void;
  variant?: 'primary' | 'secondary' | 'ternary' | 'danger' | 'transparent';
  buttonSize?: 'lg' | 'sm' | 'full';
  id?: string;
  name?: string;
  disabled?: boolean;
  outline?: boolean;
  type?: 'button' | 'submit';
  ariaLabel?: string;
  ariaPressed?: boolean;
  link?: string;
  customClass?: string;
  databstoggle?: string;
  databstarget?: string;
  datatestid?: string;
  style?: React.CSSProperties;
  automationId?: string;
  s_objectid?: string;
}

const Button: React.FC<ButtonProps> = (props) => {
  const {
    children,
    variant,
    buttonSize,
    disabled,
    type = 'button',
    onClick,
    onBlur,
    onFocus,
    ariaLabel,
    ariaPressed,
    link,
    id,
    customClass,
    name,
    databstoggle,
    databstarget,
    datatestid,
    style,
    automationId,
    ...rest
  } = props;

  const btnSize = `${buttonSize ? classes['btn-' + buttonSize] : ''}`;
  const btnVariant = `${variant ? classes[variant] : classes.primary}`;

  if (link) {
    return (
      <Link
        automation-id={automationId}
        className={`${classes.btn} ${btnVariant} ${btnSize} ${customClass}`}
        href={link}
        aria-label={ariaLabel}
        aria-pressed={ariaPressed}
        id={id}
        style={style}
        onClick={onClick}
        {...rest}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      automation-id={automationId}
      disabled={disabled}
      onClick={onClick}
      onBlur={onBlur}
      onFocus={onFocus}
      type={type}
      aria-label={ariaLabel}
      aria-pressed={ariaPressed}
      className={`${classes.btn} ${btnVariant} ${btnSize} ${customClass}`}
      id={id}
      name={name}
      data-bs-toggle={databstoggle}
      data-bs-target={databstarget}
      data-testid={datatestid}
      style={style}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
