import CrossIcon from '../../CrossIcon/CrossIcon';
import Link from '@next-app/components/elements/Link/Link';
import Menu from '../../MenuIcon/MenuIcon';
import { DynamicCategoriesNavigationMenuContent } from '@next-app/interface/PageContent';
import { LinkDetailList2 } from '@next-app/interface/Navigation';

interface DynamicCategoriesNavigationMenuProps {
  contentItem: DynamicCategoriesNavigationMenuContent;
}

export default function DynamicCategoriesNavigationMenu(
  props: DynamicCategoriesNavigationMenuProps,
) {
  return (
    <>
      <h4 style={{ fontWeight: 700 }}>
        <Menu />
        {props?.contentItem?.name}
        <CrossIcon />
      </h4>
      {props?.contentItem?.linkDetailList?.map(
        (ele: LinkDetailList2, index: number) => {
          return (
            <li
              key={`${ele.displayName}-${index}`}
              className={`${ele['@class']} PageContentDynamicCategoriesNavigationMenu`}
            >
              <Link href={`${ele.originalUrl}`}>{ele.displayName}</Link>
            </li>
          );
        },
      )}
    </>
  );
}
