import { BannerProps } from '@next-app/interface/PageContent';
import classes from './banner.module.scss';

interface PageContentBanner {
  contentItem: BannerProps;
}

const CarouselIndicator = ({ contentItem }: PageContentBanner) => {
  return (
    <div
      className={`carousel-indicators ${classes['carousel-indicator-main']}`}
    >
      {contentItem?.carousel?.map((item: any, index: number) => (
        <button
          key={item.s_regionId}
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to={index}
          className={`${classes.indicator} ${index == 0 ? 'active' : ''}`}
          aria-label={`Slide ${index + 1}`}
        ></button>
      ))}
    </div>
  );
};

export default CarouselIndicator;
