interface BlockClassItem {
  blockClass: string;
}

export default function getBlockClassNames(
  item: BlockClassItem,
): string | null {
  if (!item.blockClass || typeof item.blockClass !== 'string') {
    return null;
  } else {
    return item.blockClass;
  }
}
