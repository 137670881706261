import React from 'react';
import classes from './radio-button.module.scss';
import { getUniqueKey } from '@next-app/utils';

interface RadioButtonProps {
  label: string;
  value: string;
  checked: boolean;
  classname?: string;
  btncustomclass?: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: string) => void;
  datatestid?: string;
  automationId?: string;
  isDisabled?: boolean;
  labelClass?: string;
}

const RadioButton: React.FC<RadioButtonProps> = (props) => {
  const {
    label,
    value,
    checked,
    onChange,
    classname,
    datatestid,
    automationId,
    isDisabled,
    btncustomclass,
    labelClass,
  } = props;

  const handleChange = () => {
    onChange(value);
  };

  const uniqueId = getUniqueKey(1, 'radio-input') as string;
  return (
    <div
      className={`${classes.radioButtonFieldControl} ${classname ? classname : ''}`}
    >
      <label htmlFor={uniqueId}>
        <input
          automation-id={automationId}
          type="radio"
          value={value}
          checked={checked}
          onChange={handleChange}
          className={classes.radio}
          data-testid={datatestid}
          disabled={isDisabled}
          id={`${uniqueId}`}
        />
        <div
          className={`${classes['radio-button-indicator']} ${
            btncustomclass ? btncustomclass : ''
          }`}
        ></div>
        <span className={`${classes.radioButtonLabel} ${labelClass}`}>
          {label}
        </span>
      </label>
    </div>
  );
};

export default RadioButton;
