import { IAssortmentResultsListProps } from '@next-app/interface/PLP/AssortmentListing';
import ProductCards from '../../ProductCards/ProductCards';
import MobFilter from '../../Facets/MobFacets';
import classes from './assortment-results-list.module.scss';
import { getInitData } from '@next-app/utils';
import { IPageContentGuidedNavigation } from '@next-app/interface/PLP';

const AssortmentResultsList = async (props: IAssortmentResultsListProps) => {
  const {
    breadcrumbs: { refinementCrumbs = [], removeAllAction },
    assortmentTitle,
    selectedNavigationBreadcrumbs,
    metaTag,
  } = props.contentItem;
  const { guidedNavigation } = props;
  const initResp = await getInitData('force-cache');
  const { sceneSevenImageUrlPrefix, scene7Env, scene7BaseURL } =
    initResp.initResponse;

  let availability = 0;
  guidedNavigation?.forEach((navItem: IPageContentGuidedNavigation) => {
    const availabilityFacet = navItem?.facets?.find(
      (item) => item?.name === 'Narrow by Availability',
    );
    const count = availabilityFacet?.refinements?.find(
      (item) => item?.label === 'inStoreSku',
    )?.count;
    if (count !== undefined) {
      availability += count;
    }
  });

  return (
    <div
      className={`${classes['plp-resultList']} ${refinementCrumbs?.length > 0 ? 'filters-active' : ''}`}
    >
      <MobFilter
        guidedNavigation={props.guidedNavigation}
        filterCount={refinementCrumbs?.length}
        mainDimension={props?.contentItem?.mainDimension}
        clearAllURL={removeAllAction?.navigationState}
        assortmentTitle={assortmentTitle}
        metaTag={metaTag}
        selectedNavigationBreadcrumbs={selectedNavigationBreadcrumbs}
      />
      <ProductCards
        view={props.view}
        isGSAUser={props.isGSAUser}
        contentItem={props.contentItem}
        filtersLength={refinementCrumbs?.length > 0 || false}
        availabilityCount={availability}
        imgBaseUrl={`${scene7BaseURL}${sceneSevenImageUrlPrefix}${scene7Env}`}
        clearAllURL={removeAllAction?.navigationState}
        refinementCrumbs={refinementCrumbs}
        mainDimension={props?.contentItem?.mainDimension}
      />
    </div>
  );
};

export default AssortmentResultsList;
