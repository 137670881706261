import { MenuGroupProps } from '@next-app/interface/Common';
import { findComponent } from '@next-app/utils';
import MenuGroupHtml from './MenuGroupHtml';

const MenuGroup = ({
  contentItem,
  tabHeader,
  mobileMenuItemsArr,
}: MenuGroupProps) => {
  const { groupData, displayInMobile, groupHeader, groupHeaderUrl } =
    contentItem;
  const ComponentList = groupData.map((item, index: number) => {
    const Component = findComponent(item);
    if (!Component) return null;
    return (
      <Component
        contentItem={item}
        tabHeader={tabHeader}
        mobileMenuItemsArr={mobileMenuItemsArr}
        groupHeader={groupHeader}
        displayInMobile={displayInMobile}
        key={index}
      />
    );
  });

  return (
    <MenuGroupHtml
      contentItem={contentItem}
      ComponentList={ComponentList}
      tabHeader={tabHeader}
      groupHeader={groupHeader}
      groupHeaderUrl={groupHeaderUrl}
    />
  );
};

export default MenuGroup;
