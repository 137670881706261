import { IDisrupterTileProps } from '@next-app/interface/PLP';
import classes from './disrupter-tiles.module.scss';
import Link from '../elements/Link/Link';
import ResponsiveImage from '../elements/ResponsiveImage/ResponsiveImage';

const DisrupterTile = ({ contentItem, dataPlpToDtm }: IDisrupterTileProps) => {
  const { imageAltText, scene7Asset, scene7ImageParams, slotsToSpan } =
    contentItem;
  let colWidth = 'col-md-12';

  const adobeAttribute = JSON.stringify({
    vars: { eVar: 'eVar88' },
  });

  switch (slotsToSpan && slotsToSpan[0]) {
    case '0':
      colWidth = 'col-md-12';
      break;
    case '1':
      colWidth = 'col-md-3';
      break;

    case '2':
      colWidth = 'col-md-6';
      break;

    case '3':
      colWidth = 'col-md-9';
      break;

    default:
      colWidth = 'col-md-12';
      break;
  }

  return (
    <div className={`${colWidth}`}>
      <div className={classes['image-wrapper']}>
        <Link
          data-bs-toggle="modal"
          data-bs-target="#contactForm-request"
          data-analytics-attribute={JSON.stringify(dataPlpToDtm)}
          {...{ s_objectid: imageAltText }}
        >
          <ResponsiveImage
            fill={true}
            className={`${classes.image} position-static`}
            mobileImagePath={`/${scene7Asset}?${scene7ImageParams}`}
            tabletImagePath={`/${scene7Asset}?${scene7ImageParams}`}
            desktopImagePath={`/${scene7Asset}?${scene7ImageParams}`}
            altText={imageAltText}
            isFullUrl={true}
            data-activity-map={adobeAttribute}
          />
        </Link>
      </div>
    </div>
  );
};

export default DisrupterTile;
