export const formDataFields = {
  fname: {
    propertyName: 'fname',
    ocPropertyName: 'firstName',
    label: 'First Name',
    type: 'text',
    validateInput: 'LtrNumCh1',
    maxLength: 40,
    localizedMessage: 'Please enter a first name.',
    validPatternRegExp: new RegExp(/(?=.*[a-zA-Z])[a-zA-Z0-9\\s\\-\\.']/),
    invalidMessage: 'Please enter a valid first name.',
  },
  lname: {
    propertyName: 'lname',
    ocPropertyName: 'lastName',
    label: 'Last Name',
    type: 'text',
    validateInput: 'LtrNumCh1',
    maxLength: 40,
    localizedMessage: 'Please enter a last name.',
    validPatternRegExp: new RegExp(/(?=.*[a-zA-Z])[a-zA-Z0-9\\s\\-\\.']/),
    invalidMessage: 'Please enter a valid last name.',
  },
  phoneNo: {
    propertyName: 'phoneNo',
    ocPropertyName: 'phoneNumber',
    label: 'Phone Number',
    type: 'tel',
    validateInput: 'TelNum',
    maxLength: '',
    localizedMessage: 'Please enter a phone number.',
    validPatternRegExp: new RegExp(
      /(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/,
    ),
    invalidMessage: 'Please enter a valid phone #.',
  },
  companyName: {
    propertyName: 'companyName',
    ocPropertyName: 'companyName',
    label: 'Organization',
    type: 'text',
    validateInput: 'inputCompanyName',
    maxLength: '50',
    localizedMessage: 'Please enter an organization name.',
    validPatternRegExp: new RegExp(/[A-Za-z0-9\s`~!@#$%^&*()\-_=+|;:,.?'‘’]/),
    invalidMessage: 'Please enter organization in a valid format.',
  },
  email: {
    propertyName: 'email',
    ocPropertyName: 'email',
    label: 'Email',
    type: 'email',
    validateInput: 'LtrNumCh6',
    maxLength: 100,
    localizedMessage: 'Please enter an email address.',
    validPatternRegExp: new RegExp(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    ),
    invalidMessage: 'Please enter a valid email address.',
  },
  userShopFor: {
    propertyName: 'userShopFor',
    ocPropertyName: 'userShopFor',
    label: 'I typically shop for',
    type: 'radio',
    options: [
      { displayName: 'Classroom/Organization', value: 'classroom' },
      { displayName: 'Home', value: 'home' },
      { displayName: 'Both', value: 'all' },
    ],
  },
  address: {
    propertyName: 'address',
    ocPropertyName: 'address1',
    label: 'Address',
    type: 'text',
    validateInput: 'LtrNumCh1',
    maxLength: '40',
    localizedMessage: 'Please enter an address.',
    validPatternRegExp: new RegExp(/[A-Za-z0-9\s-.'‘’]/),
    invalidMessage: 'Please enter a valid address.',
  },
  city: {
    propertyName: 'city',
    ocPropertyName: 'city',
    label: 'City',
    type: 'text',
    validateInput: 'LtrCh1',
    maxLength: '30',
    localizedMessage: 'Please enter a city.',
    validPatternRegExp: new RegExp(/[A-Za-z\s-.'‘’]/),
    invalidMessage: 'Please enter a valid city.',
  },
  state: {
    propertyName: 'state',
    ocPropertyName: 'state',
    label: 'State',
    type: 'text',
    validateInput: 'Ltr',
    maxLength: '2',
    localizedMessage: 'Please select a state.',
    validPatternRegExp: new RegExp(/[A-Za-z]/),
    invalidMessage: 'Please enter a valid state.',
  },
  zipCode: {
    propertyName: 'zipCode',
    ocPropertyName: 'postalCode',
    label: 'Zip Code',
    type: 'text',
    validateInput: 'LtrNumCh4',
    maxLength: 10,
    localizedMessage: 'Please enter Zip/Postal Code.',
    validPatternRegExp: {
      1: '^[0-9]{5}[s]?(?:[-]?[0-9]{4})?$',
      2: '^(?!.*[DFIOQU])[A-VXYa-vxy][0-9][A-Za-z] ?[0-9][A-Za-z][0-9]$',
    }, // two regex passed as object one for numeric and other for Canadian ZipCodes
    invalidMessage: 'Please enter a valid zip code.',
  },
  message: {
    propertyName: 'message',
    ocPropertyName: 'message',
    label: 'Tell us how we can help.',
    type: 'textarea',
    validateInput: 'LtrNumCh5',
    maxLength: 250,
    localizedMessage: 'Please enter a message.',
    validPatternRegExp: new RegExp(/[A-Za-z0-9\s`~!@#$%^*()\-_=+|;:,.?'‘’]/),
    invalidMessage: 'Please enter a valid message.',
  },
};
