'use client';

import { useState } from 'react';
import Link from '@next-app/components/elements/Link/Link';
import HTMLReactParser from 'html-react-parser';
import { getStoresByZip } from '@next-app/utils/get-store-data';
import { Stores } from '@next-app/interface/StoreList';
import Card from './StoreCard';
import {
  buttonElementNames,
  linkElementNames,
} from '@next-app/config/elementNameConfig';
import { useSelector } from 'react-redux';
import Button from '@next-app/components/elements/Button/Button';
import classes from './store-modal.module.scss';

const SelectedStoreComponent = (props: any) => {
  const [data, setData] = useState([]);
  const [zipCode, setZipcode] = useState<string>('');
  const [storeUserMessage, setStoreUserMessage] = useState({});

  const {
    profile: { selectedStore, id, address },
    cartInfo,
    dynSessConfNumber,
  } = props;

  const {
    city,
    state,
    address1,
    postalCode,
    phoneNumber,
    hours,
    seoUrl,
    id: storeId,
    name,
    longitude,
    latitude,
  } = selectedStore;

  const { cartId } = cartInfo;
  const { init } = useSelector((state: any) => state.init);

  const handleShow = async () => {
    const {
      initResponse: { internalAPI },
    } = init;

    const resp = await getStoresByZip({
      internalAPI,
      longitude,
      latitude,
      dynSessConfNumber,
    });

    const storeData: Stores[] | any = resp.stores;
    setStoreUserMessage(resp.userMessages);
    await setData(storeData);
  };

  const cardProps = {
    cartId,
    profileId: id,
    storeId,
    storeName: name,
    storeState: state,
    dynSessConfNumber,
    zipCode,
    country: address?.country || '',
    data,
    storeUserMessage,
  };

  const hoursData = hours && HTMLReactParser(hours);

  return (
    <div className={classes['location-detail']}>
      <div className={classes['location']}>
        <h4 className="font-bold hover:text-zinc-300">{`${city}, ${state}`}</h4>
        <p>{address1}</p>
        <p>{`${city}, ${state} ${postalCode}`}</p>
      </div>
      <div>
        <p className={classes['select-modal-phone-wrap']}>
          <strong>Phone:</strong> {phoneNumber}
        </p>
        <div className="popover-hours-data">{hoursData}</div>
      </div>
      <div className="view-all-store-details flex space-x-2">
        <Link className={classes.btnLink} href={`${seoUrl}`}>
          {linkElementNames.viewMap}
        </Link>
        {' | '}
        <Link
          className={classes.btnLink}
          href={'/stores/locator?landingpage=slpViewAllStores&viewAll=true'}
        >
          {linkElementNames.viewAllStore}
        </Link>
      </div>
      <div>
        <Button
          type="button"
          data-testid="find-another-store"
          buttonSize="full"
          databstoggle="modal"
          databstarget="#findAnotherStoreNavMenu"
          onClick={handleShow}
        >
          {buttonElementNames.findAnotherStore}
        </Button>
      </div>
      {
        <Card
          portalId="findAnotherStoreNavMenu"
          setZipcode={setZipcode}
          setData={setData}
          setStoreUserMessage={setStoreUserMessage}
          {...cardProps}
        />
      }
    </div>
  );
};

export default SelectedStoreComponent;
