'use client';

import Script from 'next/script';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classes from './talkative.module.scss';
interface ITalkative {
  name: string;
}

interface ITalkativeURL {
  talkativeURL: string;
}
interface IChatInfo {
  chatInfo: ITalkativeURL;
}

interface IInitResponse {
  initResponse: IChatInfo;
}

interface IInit {
  init: IInitResponse;
}

interface IState {
  init: IInit;
}

const Talkative = ({ name }: ITalkative) => {
  const [status, setStatus] = useState(false);
  const { init } = useSelector((state: IState) => state.init);
  const talkativeURL =
    init?.initResponse?.chatInfo?.talkativeURL ||
    'https://talkative-cdn.com/companies/lakeshore/widget/0.0.2/talkative.js';

  const buttonClick = () => {
    const talkativeDiv = document.querySelector('#talkative-engage');
    const talktivekey =
      'talkative_ecs__us__8478b053-1343-430f-8ea5-485a321672ef__widget_is_visible';
    const talktivkeyValue = localStorage.getItem(talktivekey);

    const clickButtonInShadowRoot = (selector: any) => {
      if (talkativeDiv) {
        const newShadowRoot = talkativeDiv.shadowRoot;
        if (newShadowRoot) {
          const button = newShadowRoot.querySelector(selector);
          if (button) {
            button.click();
          }
        }
      } else {
        (window as any)?.talkativeAdvancedWidgetApi.talkativeCallable();
      }
    };

    if (talktivkeyValue === null) {
      (window as any)?.talkativeAdvancedWidgetApi?.talkativeCallable();
    } else if (talktivkeyValue === '0') {
      clickButtonInShadowRoot('button[title*="Open"]');
    } else {
      clickButtonInShadowRoot('.bg-primary button');
    }
  };

  useEffect(() => {
    const checkStatusChange = () => {
      setStatus((window as any)?.talkativeOnline);
    };
    const intervalId = setInterval(checkStatusChange, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <Script
        src={talkativeURL}
        onReady={() => {
          (window as any)?.talkativeAdvancedWidgetApi?.init({
            primary: 'e691550e-e0e5-43fc-af85-89ef9bf91453',
            region: 'us',
            offlineActionable: 'offline',
            onlineActionable: 'start-chat',
          });
        }}
        strategy="lazyOnload"
      />
      <div
        data-testid="toggle-talkative"
        style={{ cursor: 'pointer', ...(status ? { color: '#007daa' } : {}) }}
        className={
          status ? classes['talkativeOnline'] : classes['talkativeOffline']
        }
        onClick={() => buttonClick()}
      >
        {name}
        {status ? (
          <span className={classes['online']}></span>
        ) : (
          <span> (Offline)</span>
        )}
      </div>
    </>
  );
};

export default Talkative;
