'use client';

import React from 'react';
import PDPCarouselItem from '../PDPCarousels/PDPCarouselItem';
import { chunkCarouselItem } from '@next-app/utils/PDPHelper';
import { CarouselProducts } from '@next-app/interface/PDP/PDPRecentlyViewedProducts';
import classes from './added-to-cart-recommendations.module.scss';
import { getUniqueKey } from '@next-app/utils';

interface AddedToCartRecommendationsProps {
  recsProductInfo: CarouselProducts[] | [];
  title: string | '';
}

const AddedToCartRecommendations = ({
  items,
  id,
}: {
  items: AddedToCartRecommendationsProps | undefined;
  id: string | '';
}) => {
  const chunkedRecommendations: CarouselProducts[][] = chunkCarouselItem(
    items?.recsProductInfo ?? [],
    4,
  );

  return (
    <PDPCarouselItem
      carouselId={`addToCartCarouse${getUniqueKey(1, id)}`}
      recommendationTitle={items?.title || ''}
      chunkedRecommendations={chunkedRecommendations}
      classnameItem={classes['addToCartCarouselItem']}
      classnameItemName={classes['addToCartCarousel-name']}
      classnameItemButton={classes['addToCartCarousel-button']}
    />
  );
};

export default AddedToCartRecommendations;
