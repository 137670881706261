import { IpardotData } from '@next-app/components/shared/PageContent/ContactForm/ContactForm';

export function fetchDataWithJsonp(
  url: string,
  callbackName: any,
  callbackFunction: Function,
) {
  window[callbackName] = function (data: IpardotData) {
    if (typeof callbackFunction === 'function') {
      callbackFunction(data);
    }
    delete window[callbackName];
    document.head.removeChild(script);
  } as any;

  const script = document.createElement('script');
  script.src = `${url}?&callback=${callbackName}`;
  script.onerror = function () {
    console.error('Failed to load JSONP script.');
  };
  document.head.appendChild(script);
}
