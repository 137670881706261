import { findComponent } from '@next-app/utils';
import { MainMenuProps } from '@next-app/interface/Common';
import MainMenuHtml from './MainMenuHtml';

const MainMenu = ({
  contentItem,
  navigationRight,
  mobileNavigation,
}: MainMenuProps) => {
  const { tabs } = contentItem;

  const ComponentList = tabs.map((item, index: number) => {
    const Component = findComponent(item);
    if (!Component) return null;

    return (
      <Component
        contentItem={item}
        key={index}
        mobileNavigation={mobileNavigation}
      />
    );
  });

  const NavigationRightComponent = findComponent(navigationRight);

  const NavigationRight = NavigationRightComponent && (
    <NavigationRightComponent
      contentItem={navigationRight}
      accMenuType="NavRight"
      mobileNavigation={mobileNavigation}
    />
  );

  return (
    <MainMenuHtml
      ComponentList={ComponentList}
      NavigationRight={NavigationRight}
    />
  );
};

export default MainMenu;
