import parse from 'html-react-parser';
import classes from './product-card.module.scss';
import constants from '@next-app/constants/constants';
import Conditional from '@next-app/components/Conditional';
import ProductPrice from './ProductPrice';
import StorePickup from './StorePickup';
import Link from '@next-app/components/elements/Link/Link';
import { IProductCardProps } from '@next-app/interface/PLP/AssortmentListing';
import ResponsiveImage from '@next-app/components/elements/ResponsiveImage/ResponsiveImage';

const ProductCard = ({
  product,
  isListView,
  automationId,
  lastIndexAutomationId,
  dataPlpToDtm,
  isGSAUser,
  isCardAboveTheFold,
  hasEGiftCardRecords,
  hasGiftCardRecords,
}: IProductCardProps) => {
  const renderStars = (rating: number) => {
    const fullStars = Math.floor(rating);

    const halfStar = rating % 1 !== 0;

    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    return (
      <>
        {Array.from({ length: fullStars }).map((_, index) => (
          <span key={`full-${index}`} className={classes.fullStar}></span>
        ))}

        {halfStar && <span className={classes.halfStar}></span>}

        {Array.from({ length: emptyStars }).map((_, index) => (
          <span key={`empty-${index}`} className={classes.emptyStar}></span>
        ))}
      </>
    );
  };
  return (
    <div
      className={
        isListView
          ? `${classes.listView} col-12`
          : `${classes.gridView} col-md-4 col-xl-3`
      }
    >
      <div className={`${classes.card}`} automation-id={automationId}>
        <div className={classes['image-wrapper']}>
          <Link
            className="position-relative"
            href={product?.attributes['product.seoUrl']}
            // onClick={saveScrollPositionInSessionStorage}
            data-analytics-attribute={JSON.stringify(dataPlpToDtm)}
          >
            <ResponsiveImage
              mobileImagePath={product?.attributes['product.displayImage']}
              desktopImagePath={product?.attributes['product.displayImage']}
              tabletImagePath={product?.attributes['product.displayImage']}
              altText={''}
              isFullUrl={true}
              className={`${classes.image} ${hasEGiftCardRecords || hasGiftCardRecords ? classes['gift-image'] : ''} position-relative`}
              fill={true}
              lazyload={!isCardAboveTheFold}
              data-analytics-attribute={JSON.stringify(dataPlpToDtm)}
            />
          </Link>
        </div>

        <div className={`${classes.details}`}>
          <div className={classes['isNew-store-wrap']}>
            <Conditional
              if={
                product?.attributes['product.hasNewBadge'] &&
                product?.attributes['product.hasNewBadge'][0] === '1'
              }
            >
              <div className={classes.isNew}>
                <span>NEW</span>
              </div>
            </Conditional>
            {/* This is related to exclusive badge. Need to remove later.  */}
            {/* <Conditional
              if={
                product?.attributes['product.isLLExclusive'] &&
                product?.attributes['product.isLLExclusive'][0] === '1'
              }
            >
              <div className={classes.isLLExclusive}>
                <span>EXCLUSIVE</span>
              </div>
            </Conditional> */}
            <Conditional
              if={product?.attributes['product.isStoreOnlyProduct']?.includes(
                '1',
              )}
            >
              <div className={classes['is-store-only-product']}>
                <span>{constants.LLConstants.STORE_PICKUP_ONLY}</span>
              </div>
            </Conditional>
          </div>

          <div className={classes.label}>
            <Link
              href={product?.attributes['product.seoUrl']}
              automation-id={lastIndexAutomationId}
              // onClick={saveScrollPositionInSessionStorage}
              data-analytics-attribute={JSON.stringify(dataPlpToDtm)}
            >
              {parse(`${product?.attributes['product.displayName']}`)}
            </Link>
          </div>
          <ProductPrice isGSAUser={isGSAUser} product={product} />
          <Conditional if={product?.attributes['product.preSellDate']}>
            <span className={classes.preSellDate}>
              Available {product?.attributes['product.preSellDate']}
            </span>
          </Conditional>
          <Conditional
            if={product?.attributes['product.approvedReviewCount'] > 0}
          >
            <div className={classes.rating}>
              <Link
                href={product?.attributes['product.seoUrl']}
                className={classes['product-review']}
                // onClick={saveScrollPositionInSessionStorage}
                data-analytics-attribute={JSON.stringify(dataPlpToDtm)}
              >
                <span>
                  {renderStars(product?.attributes['product.rating'])}
                </span>
                {'  '}{' '}
                <span className={`${classes['review-count']}`}>
                  ({product?.attributes['product.approvedReviewCount']})
                </span>
              </Link>
            </div>
          </Conditional>

          <Conditional if={isListView}>
            <>
              <div
                className={classes['long-sescription']}
                dangerouslySetInnerHTML={{
                  __html: product?.attributes['product.longDescription'] || '',
                }}
              ></div>
              <Conditional
                if={
                  !product?.attributes['product.ageGradeRange']?.includes(
                    'hideRecommendedAge',
                  )
                }
              >
                <div className={classes['age-grade-range']}>
                  {product?.attributes['product.ageGradeRange']}
                </div>
              </Conditional>

              <div className={classes['repository-id']}>
                Item # {product?.attributes['product.repositoryId']}
              </div>
            </>
          </Conditional>
          <StorePickup
            isStoreSku={
              (product?.attributes['skuShipType']?.includes('inStoreSku') &&
                product?.attributes['storeIdsWithStock']) ||
              false
            }
            product={product}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
