'use client';

import Conditional from '@next-app/components/Conditional';
import classes from './main-menu.module.scss';
import getDevice from '@next-app/components/HOC/GetDevice';

interface MainMenuHtmlProps {
  ComponentList: (React.JSX.Element | null)[];
  NavigationRight: React.JSX.Element | null;
  isMobile: boolean;
  isTab: boolean;
  isWeb: boolean;
}

const MainMenuHtml = ({
  ComponentList,
  NavigationRight,
  isMobile,
  isTab,
  isWeb,
}: MainMenuHtmlProps) => {
  const handleCloseMobileMenu = () => {
    let menuEle = document.querySelector('.mp-pusher');
    menuEle && menuEle.classList.remove('mp-pushed');
  };

  return (
    <>
      <Conditional if={isWeb}>
        <ul className={`${classes['nav-main']} nav-main_wrap`}>
          {ComponentList}
        </ul>
      </Conditional>

      <Conditional if={isMobile || isTab}>
        <>
          <ul className="mobileNavTop">
            <div className={classes['list-item-title']}>
              <span className="logo-center">
                <a className={classes['header-logo']} role="presentation">
                  <span className="logos-sprites ll-logo-red"></span>
                  <span className="logos-sprites lf-logo-black hidden"></span>
                </a>
              </span>
              <span
                aria-label="Close"
                data-testid="close-popover"
                data-dismiss="popover"
                onClick={handleCloseMobileMenu}
                className={classes['close-hamburger-menu']}
              >
                <span
                  className={classes['sprites-close-icon']}
                  role="presentation"
                ></span>
              </span>
            </div>

            {ComponentList}
            <li className={classes['account-mobile-menu-right-wrapper']}>
              {NavigationRight}{' '}
            </li>
          </ul>
        </>
      </Conditional>
    </>
  );
};

export default getDevice(MainMenuHtml);
