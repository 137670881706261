import { makeFetchCall } from '@next-app/utils/fetch-data';
import configurations from '@next-app/config/configurations';
import urlMapping from '../config/urlMapping';
import getInitData from './get-init-data';
import { normalizePathname } from './generatePLPath';
import { IParams } from '@next-app/interface/PLP/ProductListPage';
import getOneColumnMetadata from './getOneColumnMetadata';
import getData from './ProductHelper';
import parse from 'html-react-parser';

interface SeoItem {
  id: string;
  title: string;
  pageName: string;
  url: string;
  description: string;
  canonicalURL: string | null;
  robotsContent: string | null;
}

async function getAllSeoData(internalAPI: string) {
  const allSeoData = await makeFetchCall({
    endpoint: `${internalAPI}${urlMapping.getAllSEOData}`,
  });
  return allSeoData;
}

function extractMetaData(allSeoData: SeoItem[], url: string) {
  return allSeoData?.find((item) => normalizePathname(item.url) === url);
}

function getRobotsContent(metaData: SeoItem | undefined) {
  return (
    metaData?.robotsContent?.split(',').map((val) => val.trim()) || [
      'index',
      'follow',
    ]
  );
}

async function getSeoData(page: string) {
  const initData = await getInitData('force-cache');
  const { baseUrl } = configurations;
  const allSeoData: SeoItem[] = await getAllSeoData(
    initData?.initResponse?.internalAPI,
  );
  const metaData = extractMetaData(allSeoData, page);
  const robotsContent = getRobotsContent(metaData);

  const shouldIndex =
    !initData?.initResponse?.lowerEnv &&
    metaData?.robotsContent !== null &&
    robotsContent.includes('index');

  const shouldFollow =
    !initData?.initResponse?.lowerEnv &&
    metaData?.robotsContent !== null &&
    robotsContent.includes('follow');

  const title = parse(metaData?.title ?? '');
  const description = parse(metaData?.description ?? '');
  return {
    title,
    url: baseUrl,
    description,
    alternates: {
      canonical:
        metaData?.canonicalURL !== null
          ? `${baseUrl}${metaData?.canonicalURL}`
          : baseUrl,
    },
    openGraph: {
      title,
      description,
      url: baseUrl,
    },
    robots: {
      index: shouldIndex,
      follow: shouldFollow,
    },
  };
}

export async function genMetaTags(
  params: IParams,
  isSale: boolean,
  isGSA: boolean,
  requestHeaders = {},
  isPdpPage: boolean,
) {
  const initData = await getInitData('force-cache');
  const data = await getData(
    params?.params,
    isSale,
    isGSA,
    requestHeaders,
    isPdpPage,
  );
  const metaData = getOneColumnMetadata(data);
  const robotsContent = getRobotsContent(metaData);
  const description = parse(metaData?.description ?? '');

  const decodeHTMLEntities = (str: any) => {
    if (!str || typeof str !== 'string') return str;
    return str
      .replace(/&nbsp;/g, ' ')
      .replace(/&amp;/g, '&')
      .replace(/&quot;/g, '"')
      .replace(/&apos;/g, "'")
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&#39;/g, "'")
      .replace(/&#34;/g, '"');
  };

  function combineParsedTitle(parsed: any) {
    if (Array.isArray(parsed)) {
      return parsed
        ?.map((item) => {
          if (typeof item === 'string') {
            return item;
          }
          if (item && item.props && item.props.children) {
            return item.props.children;
          }
          return '';
        })
        .join('');
    }
    return parsed;
  }

  const decodedTitle = parse(decodeHTMLEntities(metaData?.title ?? ''));
  const parsedTitle = combineParsedTitle(decodedTitle);

  const shouldIndex =
    robotsContent.length > 0 &&
    !initData?.initResponse?.lowerEnv &&
    metaData?.robotsContent !== null &&
    robotsContent.includes('index');

  const shouldFollow =
    robotsContent.length > 0 &&
    !initData?.initResponse?.lowerEnv &&
    metaData?.robotsContent !== null &&
    robotsContent.includes('follow');

  const canonicalURL = metaData?.canonicalURL?.endsWith('/json/')
    ? metaData?.canonicalURL?.replace('/json/', '')
    : metaData?.canonicalURL;

  const metaURL = metaData?.url?.endsWith('/json/')
    ? metaData?.url?.replace('/json/', '')
    : metaData?.url;

  return {
    title: parsedTitle,
    url: metaData?.siteUrl,
    description,
    alternates: {
      canonical:
        metaData?.canonicalURL !== null
          ? `${metaData?.siteUrl}${canonicalURL}`
          : metaData?.siteUrl,
    },
    openGraph: {
      title: parse(metaData?.ogTitle ?? ''),
      description,
      url: `${metaData?.siteUrl}${metaURL}`,
    },
    ...(robotsContent.length > 0 && {
      robots: {
        index: shouldIndex,
        follow: shouldFollow,
      },
    }),
  };
}

export default getSeoData;
