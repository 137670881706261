'use client';

import Link from '@next-app/components/elements/Link/Link';
import { useSelector } from 'react-redux';
import classes from './account-menu-link.module.scss';
import Conditional from '@next-app/components/Conditional';

export default function RewardSection(props: any) {
  const { link, linkUrl, linkClass = '' } = props;
  const { sessionInfo } = useSelector((state: any) => state.init);

  if (!Object.keys(sessionInfo).length) {
    return;
  }

  const { featureToggles, profile, rewardsCustomer } = sessionInfo.sessionInfo;
  const { rewardsEnabled } = featureToggles;
  const { gsaUser } = profile;
  let rewardPoints = rewardsCustomer?.rewardPoints;

  const showRewards = rewardsEnabled && !gsaUser;

  const handleRewards = () => {
    return (
      <>
        <div className="link-wrapper">{link.name}</div>
      </>
    );
  };

  return (
    <>
      <Conditional if={showRewards && linkClass === ''}>
        <Link
          className={`${classes['rewards-icon-link']}`}
          tabIndex={0}
          href={linkUrl}
          automation-id="nh_rewards_icon"
          s_objectid={link?.s_objectId}
        >
          <span className={`${classes['rewards-icon']} f-enabled`} />{' '}
          {handleRewards()}
        </Link>
      </Conditional>
      <Conditional if={showRewards && linkClass !== ''}>
        <li className="default">
          <span className={link.classAttributes}></span>
          <Link
            tabIndex={0}
            className="header-link"
            data-toggle="modal"
            aria-label={link.name}
            href={linkUrl}
            s_objectid={link?.s_objectId}
          >
            {handleRewards()}
          </Link>
        </li>
      </Conditional>
    </>
  );
}
