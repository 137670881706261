'use client';

import Conditional from '@next-app/components/Conditional';
import classes from './menu-link.module.scss';
import { extractDimensionsFromUrl } from '@next-app/utils/getImageHeightWidthDynamic';
import { MobileMenuLinkInterface } from '@next-app/interface/Header';
import ResponsiveImageClient from '@next-app/components/elements/ResponsiveImageClient/ResponsiveImageClient';

const MobileMenuLinks = ({
  isMobile,
  isTab,
  displayInMobile,
  imageLink,
  imageDecorations,
  imageURL,
  imageAltText,
  imageBottomText,
  textLink,
  textLinkLabel,
  tabHeader,
}: MobileMenuLinkInterface) => {
  const { width, height } = extractDimensionsFromUrl(imageURL);
  return (
    <Conditional if={isMobile || isTab}>
      <>
        <Conditional if={displayInMobile}>
          <a
            href={imageLink}
            className={`${classes['menu-link-img']} ${imageDecorations ? classes['imageDecorations'] : ''}`}
          >
            <ResponsiveImageClient
              fill={false}
              className={`${classes.image} position-static`}
              mobileImagePath={`/${imageURL}`}
              tabletImagePath={`/${imageURL}`}
              desktopImagePath={`/${imageURL}`}
              altText={imageAltText}
              isFullUrl={true}
              width={width}
              height={height}
            />

            <span>{imageBottomText}</span>
          </a>
        </Conditional>
        <Conditional if={textLinkLabel !== ''}>
          <div className={`${classes['menu-link-mobile']}`}>
            <a
              href={textLink}
              {...{
                s_objectid: `${tabHeader}:${textLinkLabel}`,
              }}
              data-activity-map={JSON.stringify({
                vars: {
                  events: 'event24',
                  eVar: 'eVar1',
                  list1: `${tabHeader}:${textLinkLabel}`,
                },
              })}
            >
              <span>{textLinkLabel}</span>
            </a>
          </div>
        </Conditional>
      </>
    </Conditional>
  );
};

export default MobileMenuLinks;
