'use client';

import { useEffect, memo, useState } from 'react';

import classes from './added-to-cart.module.scss';
import { AddToCartResponse } from '@next-app/interface/PDP/PDPAddToCart';
import { ShowModalProps } from '@next-app/interface/Common';
import Conditional from '@next-app/components/Conditional';
import DynamicModal from '@next-app/components/elements/DynamicModal/DynamicModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  prepareGuestCheckout,
  applyPaypalCheckout,
} from '@next-app/utils/PDPHelper';
import { CarouselProducts } from '@next-app/interface/PDP/PDPRecentlyViewedProducts';

import Button from '@next-app/components/elements/Button/Button';
import { makeFetchCall } from '@next-app/utils/fetch-data';
import { selectDynSess } from '@next-app/lib/features/InitSelectors';
import { PDPState } from '@next-app/lib/features/PDP/PDPSlice.interface';
import urlMapping from '@next-app/config/urlMapping';
import PayPalButton from './PayPalButton';

const RegistryCartModal = (props: ShowModalProps) => {
  const session = useSelector((state: PDPState) => selectDynSess(state));
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const dispatch = useDispatch();

  const onClick = (state: boolean) => async () => {
    setIsButtonDisabled(true);
    const body = {
      paypalCheckout: false,
      isPaypalFromCheckout: false,
      _dynSessConf: session,
      dispatch,
    };
    const prepareGuestCheckoutPayloadResponse =
      await prepareGuestCheckout(body);

    const payPalBody = {
      useRegistryAddress: state,
      isPaypalFromCheckout: false,
      _dynSessConf: session,
      dispatch,
    };

    const applyPayPalCheckoutPayloadResponse =
      await applyPaypalCheckout(payPalBody);
    if (applyPayPalCheckoutPayloadResponse.status === 'OK') {
      window.location.href = applyPayPalCheckoutPayloadResponse.redirectURL;
      return;
    }
  };

  useEffect(() => {
    if (props.showModal) {
      window.scrollTo(0, 0);
      document.body.style.position = 'fixed';
      document.body.style.right = '0px';
      document.body.style.left = '0px';
    }
  }, [props.showModal]);

  return (
    <Conditional if={props.showModal}>
      <DynamicModal
        modalTitle={
          <div className={classes.modalHeading}>Registry Shipping Address</div>
        }
        modalContentClass={`${classes.modalContent} ll-modal-top`}
        onModalClose={() => {
          props.setShowModal(false);
          document.body.style.position = 'static';
        }}
      >
        <div className={classes['modal-content-wrapper']}>
          <p>
            Your order contains a registry item. Would you like to ship it to
            the address provided by the registrant?
          </p>

          <div className="d-flex justify-content-md-end flex-md-row flex-column mt-4 pe-md-4">
            <div>
              <Button
                customClass={classes['modal-no-button']}
                onClick={onClick(false)}
                name="No"
                variant="ternary"
              >
                No
              </Button>
            </div>
            <div className={classes['registry-button-wrap']}>
              <Conditional if={isButtonDisabled}>
                <div className={classes['spinner-border']} role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </Conditional>

              <Button
                onClick={onClick(true)}
                name="Yes"
                variant="primary"
                customClass={classes['modal-yes-button']}
                disabled={isButtonDisabled}
              >
                Yes
              </Button>
            </div>
          </div>
        </div>
      </DynamicModal>
    </Conditional>
  );
};

export default memo(RegistryCartModal);
