import Link from '@next-app/components/elements/Link/Link';
import { LinkFooterLink } from '@next-app/interface/PageContent';
import Talkative from './Talkative';
import GSAAuthLink from './GSAAuthLink';
import Conditional from '@next-app/components/Conditional';

interface LinkFooterProps {
  contentItem: LinkFooterLink;
}

const LinkFooter = (props: LinkFooterProps) => {
  const { name, href, isLiveChatLink } = props.contentItem;

  return isLiveChatLink ? (
    <Talkative name={name} />
  ) : (
    <>
      <Conditional if={name === 'GSA Sign-Up & Sign-In'}>
        <GSAAuthLink name={name} href={href} />
      </Conditional>
      <Conditional if={name !== 'GSA Sign-Up & Sign-In'}>
        <Link tabIndex={0} href={href} data-updated="false">
          {name}
        </Link>
      </Conditional>
    </>
  );
};

export default LinkFooter;
