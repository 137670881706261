'use client';

import React from 'react';
import Facets from '../../Facets/Facets';
import Subcategory from '../../Facets/Subcategory';
import { INonCategoryNavRefinementMenuProps } from '@next-app/interface/PLP';

const NonCategoryNavRefinementMenu = (
  props: INonCategoryNavRefinementMenuProps,
) => {
  const {
    contentItem,
    setFilterCount,
    setUpdatedUrl,
    getFilterLabel,
    isFilter,
    setIsFilter,
    setFilterLabel,
    filterLabel,
    setSelectedFilter,
    assortmentTitle,
  } = props;

  return (
    <div>
      {contentItem?.childDimensionName !== 'subcategory' ? (
        <Facets
          item={contentItem}
          setFilterCount={setFilterCount}
          setUpdatedUrl={setUpdatedUrl}
          getFilterLabel={getFilterLabel}
          setIsFilter={setIsFilter}
          isFilter={isFilter}
          setFilterLabel={setFilterLabel}
          filterLabel={filterLabel}
          setSelectedFilter={setSelectedFilter}
        />
      ) : (
        <Subcategory
          contentItem={contentItem}
          setFilterCount={setFilterCount}
          setUpdatedUrl={setUpdatedUrl}
          getFilterLabel={getFilterLabel}
          setIsFilter={setIsFilter}
          isFilter={isFilter}
          setFilterLabel={setFilterLabel}
          filterLabel={filterLabel}
          setSelectedFilter={setSelectedFilter}
          assortmentTitle={assortmentTitle}
        />
      )}
    </div>
  );
};

export default NonCategoryNavRefinementMenu;
