import { useRouter } from 'next/navigation';
import Image from 'next/image';
import classes from './added-to-cart.module.scss';
import Button from '@next-app/components/elements/Button/Button';
import {
  CommerceItemsEntity,
  PriceInfo2,
  SubTotalInfoEntity,
} from '@next-app/interface/PDP/PDPAddToCart';
import Link from '@next-app/components/elements/Link/Link';
import { decimalParse } from '@next-app/components/shared/ProductDetails/helper';
import ProductPrice from '../ProductPrice/ProductPrice';
import { useSelector } from 'react-redux';
import {
  selectParentSku,
  selectSelectedGiftCardDetails,
  selectSelectedSku,
} from '@next-app/lib/features/PDP/PDPSelectors';
import { selectFeatureToggles } from '@next-app/lib/features/InitSelectors';
import { PDPChildSKUInfo } from '@next-app/interface/PDP/PDPProductInfo';
import { PDPState } from '@next-app/lib/features/PDP/PDPSlice.interface';
import Conditional from '@next-app/components/Conditional';
import { getFormattedPrice } from '@next-app/utils/common-utils';
import PayPalButton from './PayPalButton';
import { AddToCartResponse } from '@next-app/interface/PDP/PDPAddToCart';

const AddedToCartItemDetails = ({
  productId = '',
  addedCommerceItemsInfo = [],
  subTotalInfo = [],
  isCarousel = '',
  addToCartData,
  openRegistryModal,
}: {
  productId: string;
  addedCommerceItemsInfo: CommerceItemsEntity[] | null | undefined;
  subTotalInfo: SubTotalInfoEntity[] | null | undefined;
  isCarousel?: string | '';
  addToCartData: AddToCartResponse | undefined;
  openRegistryModal: Function | undefined;
}) => {
  const router = useRouter();

  const { commerceItem, quantity } = addedCommerceItemsInfo?.[0] || {};
  const {
    gsaPrice,
    productImage,
    productDisplayName,
    isGsaPrice,
    skuDisplayName,
    seoUrl,
    skuType,
    amount,
    priceInfo,
    skuImage,
  } = commerceItem || {};

  const selectedSku: PDPChildSKUInfo = useSelector((state: PDPState) =>
    selectSelectedSku(state, productId),
  );
  const parentSku = useSelector((state: PDPState) =>
    selectParentSku(state, productId),
  );
  const carouselPriceInfo = {
    addlSaveSalePrice: 0,
    addlSaving: false,
    bopisListPrice: 0,
    bopisSale: false,
    bopisSalePrice: 0,
    clearance: false,
    doorBuster: false,
    doorBusterPrice: 0,
    gsa: isGsaPrice,
    listPrice: priceInfo?.listPrice,
    sale: priceInfo?.onSale,
    salePrice: priceInfo?.salePrice,
    special: false,
    specialPrice: gsaPrice,
    superSavings: false,
  };
  const price = isCarousel
    ? carouselPriceInfo
    : selectedSku?.priceInfo || parentSku?.priceInfo;

  const selectedGiftCardDetails = useSelector((state: PDPState) =>
    selectSelectedGiftCardDetails(state, productId),
  );

  const featureToggles = useSelector((state: PDPState) =>
    selectFeatureToggles(state),
  );

  const isCustomAmount = selectedGiftCardDetails?.amount === 'Custom Amount';
  const isGiftCard = skuType === 'eGiftcard' || skuType === 'giftcard';

  return (
    <div className={classes['info-and-shipping']}>
      <div className={classes['product-info']}>
        <div className={classes['product-image']}>
          <Image
            src={skuImage || productImage || ''}
            alt="Product Image"
            width={180}
            height={135}
          />
        </div>
        <div className={classes['product-details']}>
          <Link
            data-bs-dismiss="modal"
            href={seoUrl}
            className={classes['name']}
            dangerouslySetInnerHTML={{
              __html: `${skuDisplayName || productDisplayName}`,
            }}
          />
          <div
            className={`${isGsaPrice ? classes['price-gsa'] : ''} ${classes['price']}`}
          >
            <Conditional if={isCustomAmount && isGiftCard}>
              <span>{getFormattedPrice(Number(amount) || 0)}</span>
            </Conditional>

            <Conditional if={!isCustomAmount}>
              <ProductPrice
                productId={productId}
                priceRange={{
                  isGiftcard: skuType === 'giftcard' || skuType === 'eGiftcard',
                  giftcardPriceRange: String(amount),
                  ...price,
                  showGSA: !!isGsaPrice,
                }}
                isCartPrice={true}
              />
            </Conditional>
          </div>
          <div className={classes['quantity']}>Qty: {quantity}</div>
        </div>
      </div>
      <div className={classes['shipping-info']}>
        {subTotalInfo?.map(({ headerText, amount }, index) => (
          <div key={index}>
            <span className={classes['info-heading']}>{headerText}:</span> $
            {decimalParse(amount)}
          </div>
        ))}
        <Button
          data-testid="add-to-cart-checkout"
          customClass={classes['checkout-button']}
          onClick={() => router.push('/checkout-page')}
        >
          Checkout
        </Button>
        <div className={classes['paypal-button-wrp']}>
          <Conditional
            if={addToCartData?.showPaypal ? addToCartData?.showPaypal : false}
          >
            <PayPalButton
              openRegistryModal={openRegistryModal}
              showRegistryAddressModal={addToCartData?.showRegistryAddressModal}
            />
          </Conditional>
        </div>
      </div>
    </div>
  );
};

export default AddedToCartItemDetails;
