import {
  Breadcrumb,
  BreadcrumbListSchema,
  ProductInfoSchema,
  ProductReviewSchema,
  SchemaMarkupData,
} from '@next-app/interface/Schema';
import { SchemaMarkupConstants } from '@next-app/constants/constants';
import { IPLPRecords } from '@next-app/interface/PLP';

const SchemaMarkup = (props: SchemaMarkupData): React.JSX.Element => {
  return (
    <script
      id={`${props.id}__schema_script`}
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: props.data }}
    ></script>
  );
};

const generateHomeSchema = (): string => {
  const homepageSchema = {
    '@context': SchemaMarkupConstants.links.schema,
    '@type': SchemaMarkupConstants.type.organization,
    url: SchemaMarkupConstants.links.home,
    name: SchemaMarkupConstants.name.home,
    logo: SchemaMarkupConstants.links.logo,
    contactPoint: [
      {
        '@type': SchemaMarkupConstants.type.contactPoint,
        telephone: SchemaMarkupConstants.contact.phone,
        contactType: SchemaMarkupConstants.contact.type,
        contactOption: SchemaMarkupConstants.contact.option,
      },
    ],
  };

  return JSON.stringify(homepageSchema);
};

const generateBreadcrumbSchema = (breadcrumbs: Breadcrumb[]): string => {
  const breadcrumbSchema: BreadcrumbListSchema = {
    '@context': SchemaMarkupConstants.links.schema,
    '@type': SchemaMarkupConstants.type.breadcrumbList,
    itemListElement: breadcrumbs?.reverse().map(({ label, link }, index) => ({
      '@type': SchemaMarkupConstants.type.listItem,
      position: index + 1,
      name: label,
      ...(index < breadcrumbs.length - 1 && {
        item: `${SchemaMarkupConstants.links.base}${link}`,
      }),
    })),
  };

  return JSON.stringify(breadcrumbSchema);
};

const generateProductsListSchema = (products: IPLPRecords[]) => {
  const productsListSchema = {
    '@context': SchemaMarkupConstants.links.schema,
    '@type': SchemaMarkupConstants.type.itemList,
    itemListElement: products?.map((product: IPLPRecords, index: number) => {
      const item = product.attributes;

      return {
        '@type': SchemaMarkupConstants.type.listItem,
        position: index + 1,
        item: {
          '@type': SchemaMarkupConstants.type.product,
          description: item['product.longDescription']
            ? item['product.longDescription'][0]
            : '',
          name: item['product.displayName']
            ? item['product.displayName'][0]
            : '',
          image: item['product.displayImage']
            ? `${SchemaMarkupConstants.links.imageBaseUrl}${item['product.displayImage'][0]}`
            : '',
          offers: {
            '@type': SchemaMarkupConstants.type.offer,
            url: item['product.seoUrl']
              ? `${SchemaMarkupConstants.links.base}${item['product.seoUrl'][0]}`
              : '',
            priceCurrency: 'USD',
            price: item['product.maxPrice'] ? item['product.maxPrice'][0] : '',
            availability: `${SchemaMarkupConstants.links.schema}/${SchemaMarkupConstants.productAvailability}`,
          },
          ...(item['product.rating'] &&
            item['product.rating'][0] !== 0 && {
              aggregateRating: {
                '@context': SchemaMarkupConstants.links.schema,
                '@type': SchemaMarkupConstants.type.rating,
                ratingValue: item['product.rating'][0],
                reviewCount: item['product.approvedReviewCount']
                  ? item['product.approvedReviewCount'][0]
                  : '',
              },
            }),
        },
      };
    }),
  };

  return JSON.stringify(productsListSchema);
};

const generateProductInfoSchema = (data: ProductInfoSchema) => {
  const pdpSchemaData = {
    '@context': SchemaMarkupConstants.links.schema,
    '@type': SchemaMarkupConstants.type.product,
    description: data.description,
    name: data.name,
    image: data.imageUrl,
    offers: {
      '@type': SchemaMarkupConstants.type.offer,
      url: `${SchemaMarkupConstants.links.base}${data.productUrl}`,
      priceCurrency: data.currency,
      price: data.price,
      availability: `${SchemaMarkupConstants.links.schema}/${SchemaMarkupConstants.productAvailability}`,
    },
    ...(data.ratingValue &&
      data.ratingValue !== 0 && {
        aggregateRating: {
          '@context': SchemaMarkupConstants.links.schema,
          '@type': SchemaMarkupConstants.type.rating,
          ratingValue: data.ratingValue,
          reviewCount: data.reviewCount,
        },
      }),
  };
  return pdpSchemaData;
};

const generateProductReviewsSchema = (reviews: ProductReviewSchema[]) => {
  const reviewData = reviews?.map((review: any) => ({
    '@context': SchemaMarkupConstants.links.schema,
    '@type': SchemaMarkupConstants.type.review,
    reviewBody: review?.body,
    itemReviewed: {
      '@type': SchemaMarkupConstants.type.thing,
      name: review?.itemName,
    },
    author: {
      '@type': SchemaMarkupConstants.type.person,
      name: review?.authorName,
    },
    reviewRating: {
      '@type': SchemaMarkupConstants.type.rating,
      bestRating: 5,
      ratingValue: review?.ratingValue,
      name: review?.title,
    },
  }));
  return reviewData;
};

export {
  SchemaMarkup,
  generateBreadcrumbSchema,
  generateHomeSchema,
  generateProductsListSchema,
  generateProductInfoSchema,
  generateProductReviewsSchema,
};
