import getUniqueKey from '@next-app/utils/get-unique-key';
import classes from './checkbox.module.scss';
interface CheckboxProps {
  label: string | React.JSX.Element | React.JSX.Element[];
  checked: boolean;
  classname?: string;
  // eslint-disable-next-line no-unused-vars
  onChange: Function;
  ariaLabelledBy?: string;
  id?: string;
  name?: string;
  ariaDescribedBy?: string;
  variant?: 'sm' | 'md' | 'lg';
  datatestid?: string;
  disabled?: boolean;
  automationId?: string;
}

const Checkbox: React.FC<CheckboxProps> = (props) => {
  const {
    label,
    checked,
    onChange,
    ariaDescribedBy,
    classname,
    id,
    name,
    variant,
    datatestid,
    disabled = false,
    automationId,
  } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  const checkboxVariant = `${variant ? classes[variant] : ''}`;
  const uniqueId = getUniqueKey(1, 'checkbox') as string;

  return (
    <label
      className={`${classes.checkFieldControl}  ${classname ? classname : ''} ${checkboxVariant}`}
      htmlFor={uniqueId}
    >
      <input
        type="checkbox"
        checked={checked}
        aria-labelledby={id}
        aria-describedby={ariaDescribedBy}
        aria-checked={checked}
        onChange={handleChange}
        id={uniqueId}
        name={name}
        data-testid={datatestid}
        disabled={disabled}
        automation-id={automationId}
      />
      <div
        className={`${classes['check-indicator']} ${disabled ? classes['check-indicator-disabled'] : ''} `}
      ></div>
      <span className={classes.labelText}>{label}</span>
    </label>
  );
};

export default Checkbox;
