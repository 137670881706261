import Link from '@next-app/components/elements/Link/Link';
import CrossIcon from '../../CrossIcon/CrossIcon';
import Menu from '../../MenuIcon/MenuIcon';
import MobileMenu from '../../MobileMenu/MobileMenu';
import { AllCategoryNavigationMenuContent } from '@next-app/interface/PageContent';
import { LinkDetailList } from '@next-app/interface/Navigation';
import { ChildCategory } from '@next-app/interface/Header';

interface AllCategoryNavigationMenuProps {
  contentItem: AllCategoryNavigationMenuContent;
}

export default function AllCategoryNavigationMenu(
  props: AllCategoryNavigationMenuProps,
) {
  return (
    <div className={props.contentItem.columnClass}>
      <h4 style={{ fontWeight: 700 }}>
        <Menu />
        {props.contentItem.titleDisplayName}
        <CrossIcon />
      </h4>
      <ul className="shopByCategory">
        {props?.contentItem?.linkDetailList?.map(
          (item: LinkDetailList, index: number) => {
            return (
              <li key={`Category-${item.displayName}-${index}`}>
                <MobileMenu />
                <a>{item.displayName}</a>
                <ul className="megaMenuForSubMenuListing">
                  <CrossIcon />
                  <h4 style={{ fontWeight: 700 }}>
                    <Menu />
                    {item.displayName}
                  </h4>
                  {item?.childCategory?.map((subMenu: ChildCategory, index) => {
                    return (
                      <li key={`AllCat-${index}`}>
                        <Link href={'#'}>{subMenu.displayName}</Link>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          },
        )}
      </ul>
    </div>
  );
}
