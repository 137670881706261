'use client';

import Conditional from '@next-app/components/Conditional';
import classes from './menu-group.module.scss';
import { MenuGroupProps } from '@next-app/interface/Common';
import getDevice from '@next-app/components/HOC/GetDevice';
import getClassesByTabHeader from './helper';
import GSANavLink from '../GSANavLink/GSANavLink';

interface MenuTabHtmlProps {
  contentItem: MenuGroupProps['contentItem'];
  ComponentList: (React.JSX.Element | null)[];
  tabHeader: string;
  isMobile: boolean;
  groupHeader: string;
  groupHeaderUrl: string;
  isTab: boolean;
  isWeb: boolean;
}

const MenuGroupHtml = ({
  contentItem,
  ComponentList,
  isMobile,
  isTab,
  isWeb,
  tabHeader,
}: MenuTabHtmlProps) => {
  const {
    gridColsMedium = '3',
    gridColsLarge = '2',
    groupHeader,
    groupHeaderUrl,
    displayInMobile,
  } = contentItem;
  const gridData = getClassesByTabHeader(tabHeader);
  const FirstGridData = [String(contentItem.name.split(' ').join(''))];
  const checkImage = FirstGridData[0].toLowerCase().includes('image');
  const imageClass =
    gridData && gridData[0] ? `${gridData[1]}` : 'col-sm-6 col-md-7';
  return (
    <>
      <Conditional if={gridColsMedium !== '' && gridColsLarge !== ''}>
        <>
          <Conditional if={!isMobile && !isTab}>
            <div
              className={
                !checkImage ? `${gridData && gridData[0]}` : imageClass
              }
            >
              {groupHeader !== '' && groupHeaderUrl !== '' && (
                <a
                  automation-id={groupHeader}
                  className={classes['dropdown-header']}
                  href={groupHeaderUrl}
                  {...{
                    s_objectid: `${tabHeader}:${groupHeader}`,
                  }}
                  data-activity-map={JSON.stringify({
                    vars: {
                      events: 'event24',
                      eVar: 'eVar1',
                      list1: `${tabHeader}:${groupHeader}`,
                    },
                  })}
                >
                  {groupHeader}
                </a>
              )}
              {ComponentList}
              <Conditional if={groupHeader === 'Featured Assortments'}>
                <GSANavLink />
              </Conditional>
            </div>
          </Conditional>
          <Conditional if={isMobile || isTab}>
            <>
              {ComponentList}
              <Conditional if={groupHeader === 'Featured Assortments'}>
                <GSANavLink />
              </Conditional>
            </>
          </Conditional>
        </>
      </Conditional>
      <Conditional if={gridColsMedium === '' && gridColsLarge === ''}>
        <>
          <Conditional if={displayInMobile}>
            <h3 className={`${classes['title-decor']}`}>{groupHeader}</h3>
          </Conditional>
          <Conditional if={isWeb}>
            <div className={`d-flex gap-3 ${classes['menu-img-wrp']}`}>
              {ComponentList}
            </div>
          </Conditional>
          <Conditional if={isMobile || isTab}>
            <div className={`${classes['menu-img-grp']}`}>{ComponentList}</div>
          </Conditional>
        </>
      </Conditional>
    </>
  );
};

export default getDevice(MenuGroupHtml);
