import {
  PayPalScriptProvider,
  PayPalButtons,
  PayPalButtonsComponentProps,
} from '@paypal/react-paypal-js';
import {
  selectUserProfileId,
  selectCartInfo,
  selectPayPalInfo,
  selectDynSess,
} from '@next-app/lib/features/InitSelectors';
import { useSelector } from 'react-redux';
import { PDPState } from '@next-app/lib/features/PDP/PDPSlice.interface';
import { makeFetchCall } from '@next-app/utils/fetch-data';
import { usePathname, useRouter } from 'next/navigation';
import classes from './PayPalButtion.module.scss';
import paypalLogo from '/public/assets/images/paypal-logo.svg';
import GenericImage from '@next-app/components/elements/GenericImage/GenericImage';

interface PayPalButtonProps {
  openRegistryModal: Function | undefined;
  showRegistryAddressModal: boolean | undefined;
}

export default function PayPalButton({
  showRegistryAddressModal,
  openRegistryModal,
}: PayPalButtonProps) {
  const profileId = useSelector((state: PDPState) =>
    selectUserProfileId(state),
  );
  const cartInfo = useSelector((state: PDPState) => selectCartInfo(state));
  const payPalInfo = useSelector((state: PDPState) => selectPayPalInfo(state));
  const session = useSelector((state: PDPState) => selectDynSess(state));

  const initialOptions = {
    clientId: payPalInfo?.['client-id'],
    'disable-funding': payPalInfo?.['disable-funding'],
  };
  const router = useRouter();
  const pathname = usePathname();

  const styles: PayPalButtonsComponentProps['style'] = {
    disableMaxWidth: true,
    color: 'white',
    label: 'paypal',
    layout: 'vertical',
  };

  const createOrder = async (data: any, actions: any) => {
    try {
      const requestPayload = {
        endpoint:
          '/rest/model/atg/commerce/order/purchase/PurchaseActor/applyPayPalLight',
        options: {
          method: 'POST',
        },
        headers: { 'Content-Type': 'application/json' },
        body: {
          profileId: profileId,
          cartId: cartInfo?.cartId,
          _dynSessConf: session,
          isPaypalFromCheckout: false,
        },
      };

      const response = await makeFetchCall(requestPayload);

      if (response.formError) {
        console.log('There are errors inside the form', response);
      }
      return response?.payPalToken;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const onApprove = async (data: any) => {
    try {
      const requestPayload = {
        endpoint:
          '/rest/model/atg/commerce/order/purchase/PurchaseActor/doExpressCheckout',
        options: {
          method: 'POST',
        },
        headers: { 'Content-Type': 'application/json' },
        body: {
          profileId: profileId,
          cartId: cartInfo?.cartId,
          token: data.orderID,
          PayerId: data.payerID,
          _dynSessConf: session,
        },
      };
      const response = await makeFetchCall(requestPayload);
      if (response.status === 'OK') {
        router.push('/checkout-page');
      }
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const onClick = async (data: any, actions: any) => {
    if (showRegistryAddressModal && openRegistryModal) {
      await actions.reject();
      openRegistryModal();
      return;
    }
    return actions.resolve();
  };

  const onInit = async (data: any, actions: any) => {
    // Disable the button if `showRegistryAddressModal` is true
    if (showRegistryAddressModal) {
      return actions.disable();
    } else {
      actions.enable();
    }
  };

  const onCancel = async () => {
    router.push(pathname);
  };

  return (
    <PayPalScriptProvider options={initialOptions}>
      <div className={classes['paypal-button-wrp']}>
        <PayPalButtons
          style={styles}
          createOrder={createOrder}
          onApprove={onApprove}
          onClick={onClick}
          onInit={onInit}
          onCancel={onCancel}
        />
        <div className={classes['paypal-logo-button']}>
          <GenericImage
            src={paypalLogo}
            alt="PayPal Logo"
            width={80}
            height={18}
          />
        </div>
      </div>
    </PayPalScriptProvider>
  );
}
