import urlMapping from '@next-app/config/urlMapping';

import { makeFetchCall } from './fetch-data';

export const checkBalance = async ({
  authorizationId,
  giftCardId,
  sessionConfirmationNumber,
}: {
  authorizationId: string;
  giftCardId: string;
  sessionConfirmationNumber: string;
}) => {
  try {
    const response = await makeFetchCall({
      endpoint: `${urlMapping.loadGiftCardBalanceUrl}`,
      options: {
        method: 'POST',
      },
      body: {
        authorizationId: authorizationId,
        giftCardId: giftCardId,
        _dynSessConf: sessionConfirmationNumber,
      },
    });

    return response;
  } catch (error) {
    console.error('Load Gift Card Balance Error:', error);
  }
};
