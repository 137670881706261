import constants from '@next-app/constants/constants';
import { isValid } from './generic-functions';

interface SessionPreferencesData {
  [key: string]: string | boolean;
}

export default function getSetSessionPreferences(data: SessionPreferencesData) {
  var preferencesString = sessionStorage.getItem('preferences');
  var preferences = preferencesString ? JSON.parse(preferencesString) : {},
    email = preferences.email || '',
    sfid = preferences.sfid || '';
  if (data) {
    if (data.customerType) {
      if (data.customerType === 't' || data.customerType === 'classroom') {
        data.customerType = 'classroom';
      } else if (data.customerType === 'p' || data.customerType === 'home') {
        data.customerType = 'home';
      } else {
        data.customerType = 'all';
      }
    }
    if (isValid(data.email) || isValid(data.sfid)) {
      var matchPref =
        (data.email && email && data.email === email) ||
        (data.sfid && sfid && data.sfid === sfid);
      if (matchPref) {
        for (const key in data) {
          if (constants.prefProperties.indexOf(key) !== -1) {
            data[key] =
              data[key] === 'true'
                ? true
                : data[key] === 'false'
                  ? false
                  : data[key];
            preferences[key] = data[key];
          }
        }
      } else {
        preferences = {};
        constants.prefProperties.map((value) => {
          if (data[value]) {
            data[value] =
              data[value] === 'true'
                ? true
                : data[value] === 'false'
                  ? false
                  : data[value];
            preferences[value] = data[value];
          }
        });
      }
      sessionStorage.setItem('preferences', JSON.stringify(preferences));
    }
  }
  return preferences;
}
