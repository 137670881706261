'use client';

import classes from './product-price.module.scss';
import {
  PDPAvailableSkuOptions,
  PDPChildSKUInfo,
  PDPPriceInfo,
} from '@next-app/interface/PDP/PDPProductInfo';
import {
  minArrayValue,
  maxArrayValue,
  getPrice,
  getPriceRange,
} from '@next-app/utils/PDPHelper';
import Conditional from '@next-app/components/Conditional';
import { useSelector } from 'react-redux';
import { isNotEmptyObject } from '@next-app/components/shared/ProductDetails/helper';
import {
  getDeliveryMethod,
  selectAdditionalProductInfo,
  selectDropdownValues,
  selectSelectedGiftCardDetails,
  selectSelectedSku,
} from '@next-app/lib/features/PDP/PDPSelectors';
import { selectSessionInfo } from '@next-app/lib/features/InitSelectors';
import { PDPState } from '@next-app/lib/features/PDP/PDPSlice.interface';

interface ProductPriceProps {
  productId: string;
  priceRange?: PDPPriceInfo;
  isCartPrice?: boolean;
  availableSkuOptions?: PDPAvailableSkuOptions;
}

const ProductPrice = (props: ProductPriceProps) => {
  const { productId, availableSkuOptions } = props;
  const selectedSku: PDPChildSKUInfo = useSelector((state: PDPState) =>
    selectSelectedSku(state, productId),
  );
  const selectedGiftCardDetails = useSelector((state: PDPState) =>
    selectSelectedGiftCardDetails(state, productId),
  );
  const deliveryMethod = useSelector((state: PDPState) =>
    getDeliveryMethod(state, productId),
  );
  const additionalProductInfo = useSelector((state: PDPState) =>
    selectAdditionalProductInfo(state, productId),
  );

  const dropdownValues = useSelector((state: PDPState) =>
    selectDropdownValues(state, productId),
  );

  const sessionInfo = useSelector(selectSessionInfo);
  const { sapPriceEnabled = false } = sessionInfo || {};

  const isAllSelectedDropDown =
    dropdownValues &&
    Object.values(dropdownValues).every((value) => value !== '');

  let data = props?.priceRange;
  const { isCartPrice = false } = props;

  const { availableOptions = {}, priority = [] } = availableSkuOptions || {};

  const { childSkus = [] } = additionalProductInfo || {};
  const availableDropdowns = availableOptions && Object.keys(availableOptions);

  const filteredPriorityData = priority?.filter((priorityItem: string) => {
    return availableDropdowns.includes(priorityItem);
  });

  const newPriceRange =
    filteredPriorityData &&
    filteredPriorityData
      ?.map((el) => {
        const priceRangeData =
          childSkus &&
          childSkus?.length &&
          dropdownValues &&
          dropdownValues[el] &&
          getPriceRange(childSkus, el, dropdownValues[el]);
        return priceRangeData;
      })
      .filter((e) => e);
  if (isNotEmptyObject(selectedSku)) {
    data = selectedSku?.priceInfo;
  }
  if (
    !isAllSelectedDropDown &&
    selectedSku &&
    Object.keys(dropdownValues).length &&
    availableDropdowns?.length > 1 &&
    newPriceRange.length
  ) {
    data = {
      ...newPriceRange[0],
      minPrice: newPriceRange[0]?.minListPrice,
      maxPrice: newPriceRange[0]?.maxListPrice,
      saleMinPrice: newPriceRange[0]?.minPrice,
      saleMaxPrice: newPriceRange[0]?.maxPrice,
    };
  }

  const {
    onSale = false,
    gsa = false,
    hasGSAPrice,
    saleMinPrice,
    saleMaxPrice,
    maxPrice,
    minPrice,
    special,
    specialPrice,
    listPrice,
    doorBusterPrice,
    salePrice,
    sale,
    bopisSale,
    bopisSalePrice,
    doorBuster,
    doorBusterListPrice,
    doorBusterSalePrice,
    bopisListPrice,
  } = data || {};

  let prices = new Array<number>();
  let listPrices = new Array<number>();

  const isDoorbuster =
    doorBuster || !!doorBusterListPrice || !!doorBusterSalePrice;
  const showGSA =
    props?.priceRange?.showGSA ||
    data?.showGSA ||
    data?.gsa ||
    data?.hasGSAPrice;
  const isGSA = gsa || hasGSAPrice;
  let isSale = onSale || sale;
  const isGiftCard = props?.priceRange?.isGiftcard;
  const amountGiftCard = selectedGiftCardDetails?.amount
    ? selectedGiftCardDetails?.amount
    : props?.priceRange?.giftcardPriceRange;

  if (listPrice || minPrice || maxPrice) {
    listPrice && listPrices.push(listPrice);
    minPrice && listPrices.push(minPrice);
    maxPrice && listPrices.push(maxPrice);
  }
  if (isSale) {
    sale && salePrice && prices.push(salePrice);
    saleMinPrice && prices.push(saleMinPrice);
    saleMaxPrice && prices.push(saleMaxPrice);
  } else if (isGSA) {
    gsa && specialPrice && prices.push(specialPrice);
    saleMinPrice && prices.push(saleMinPrice);
    saleMaxPrice && prices.push(saleMaxPrice);
  } else if (bopisSale && sapPriceEnabled) {
    isSale = true;
    bopisSalePrice && prices.push(bopisSalePrice);
    bopisListPrice && listPrices.push(bopisListPrice);
  } else if (isDoorbuster) {
    if (deliveryMethod === 'store') {
      isSale = true;
      doorBusterSalePrice && prices.push(doorBusterSalePrice);
      doorBusterPrice && prices.push(doorBusterPrice);

      listPrice && listPrices.push(listPrice);
      doorBusterListPrice && listPrices.push(doorBusterListPrice);
    } else if (deliveryMethod === 'shipItem') {
      listPrice && listPrices.push(listPrice);
      doorBusterListPrice && listPrices.push(doorBusterListPrice);
    }
  }
  if (special && specialPrice) {
    prices.push(specialPrice);
  }

  let minimumPrice = minArrayValue(prices);
  let maximumPrice = maxArrayValue(prices);
  let minListPrice = minArrayValue(listPrices);
  let maxListPrice = maxArrayValue(listPrices);

  const gsaLabel = showGSA ? 'GSA' : '';
  if (isGiftCard) {
    return (
      <span className={`${classes['product-price-range']}`}>
        {amountGiftCard?.includes('$') || amountGiftCard === 'Custom Amount'
          ? amountGiftCard
          : getPrice(Number(amountGiftCard))}
      </span>
    );
  }
  if (isSale && !isGSA) {
    return (
      <Conditional if={isSale}>
        <div
          className={`${isCartPrice ? classes['cart-price-wrp'] : classes['sale-price-wrp']}`}
        >
          <span
            className={`${isCartPrice ? `${classes['cart-price']} ${classes.red}` : `${classes['product-price-range']} ${classes.red}`}`}
          >
            {getPrice(minimumPrice, maximumPrice)}
          </span>
          <span className={`${classes.sale}`}>
            {'reg. '}
            {getPrice(minListPrice, maxListPrice)}
          </span>
        </div>
      </Conditional>
    );
  }
  if (isGSA) {
    return (
      <span className={`${classes['product-price-range']}`}>
        <span className={`${gsa ? classes.green : ''} ${classes['gsa-price']}`}>
          {getPrice(minimumPrice, maximumPrice)}
        </span>
        <span className={classes.green}>{gsaLabel}</span>
      </span>
    );
  }
  return (
    <Conditional if={!isGSA && !isSale}>
      <span
        className={`${isCartPrice ? classes['cart-price'] : classes['product-price-range']}`}
      >
        {getPrice(minListPrice, maxListPrice)}
      </span>
    </Conditional>
  );
};

export default ProductPrice;
