import { MenuItemProps } from '@next-app/interface/Common';
import MenuItemHtml from './MenuItemHtml';

const MenuItem = ({
  contentItem,
  tabHeader,
  groupDataId,
  mobileMenuItemsArr,
}: MenuItemProps) => {
  return (
    <MenuItemHtml
      contentItem={contentItem}
      groupDataId={groupDataId}
      tabHeader={tabHeader}
      mobileMenuItemsArr={mobileMenuItemsArr}
    />
  );
};

export default MenuItem;
