'use client';

import React from 'react';
import Link from '@next-app/components/elements/Link/Link';
import { useSelector } from 'react-redux';
import Conditional from '@next-app/components/Conditional';
import { isEmptyObject } from '../../ProductDetails/helper';

interface menuGSALink {
  name: string;
  href: string;
}

const GSAAuthLink = ({ name, href }: menuGSALink) => {
  const { sessionInfo } = useSelector((state: any) => state.init);
  if (isEmptyObject(sessionInfo)) {
    return;
  }
  const isGSAUser = sessionInfo?.sessionInfo?.profile?.gsaUser;

  return (
    <Conditional if={!isGSAUser}>
      <Link tabIndex={0} href={href} data-updated="false">
        {name}
      </Link>
    </Conditional>
  );
};

export default GSAAuthLink;
