import { Content } from '@next-app/interface/Common';
import { HeaderBlockContent } from '@next-app/interface/Header';
import { findComponent, getUniqueKey } from '@next-app/utils';

type DynamicComponentType = React.ComponentType<{
  contentItem: HeaderBlockContent;
}>;

interface MetaContentColumn {
  '@type': string;
  name: string;
  contentPaths: string[];
  content?: any;
}

interface PageColumnContent {
  fullWidth: boolean;
  '@type': string;
  mainContent: Content[];
  applyPadding: boolean;
  name: string;
  metaContent: MetaContentColumn[];
}
interface PageOneColumnProps {
  contentItem: PageColumnContent;
}

const PageOneColumn = (props: PageOneColumnProps) => {
  const OneColComponentList = props.contentItem?.mainContent?.map(
    (item: Content, index: number) => {
      const DynamicComponent = findComponent(item) as DynamicComponentType;
      return (
        <DynamicComponent
          contentItem={item}
          key={getUniqueKey(index, item.name)}
        />
      );
    },
  );

  return OneColComponentList;
};

export default PageOneColumn;
