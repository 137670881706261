import ResponsiveImage from '@next-app/components/elements/ResponsiveImage/ResponsiveImage';
import classes from './image-banner.module.scss';
import Button from '@next-app/components/elements/Button/Button';
import Conditional from '@next-app/components/Conditional';
import dynamic from 'next/dynamic';
const BannerSeeDetailsModal = dynamic(() => import('./BannerSeeDetailsModal'), {
  ssr: false,
});
const ImageBanner = ({ contentItem }: any) => {
  const {
    btn2Text,
    bannerType,
    imageAlt,
    btn2Link,
    mobileImageURL,
    imageLink,
    btn3Text,
    imageURL,
    btn1Text,
    btn3Link,
    btn1Link,
    imgModalTitle,
    imgModalLink,
    img_s_objectId,
  } = contentItem;

  const isFullUrl = imageURL.indexOf('https://') < 0;

  return (
    <>
      <Conditional if={bannerType === 'singleCTA' || bannerType === 'multiCTA'}>
        <div className={` ${classes['heroImage']}`}>
          <ResponsiveImage
            mobileImagePath={mobileImageURL}
            tabletImagePath={imageURL}
            desktopImagePath={imageURL}
            altText={imageAlt || ''}
            width={1500}
            height={170}
            isFullUrl={isFullUrl}
            lazyload={false}
          />
          <div className={classes['btn-wrapper']}>
            <Conditional if={btn1Link && btn1Text}>
              <Button
                variant="danger"
                buttonSize="lg"
                link={btn1Link}
                customClass={classes['save-nav']}
              >
                {btn1Text}
              </Button>
            </Conditional>
            <Conditional if={btn2Link && btn2Text}>
              <Button
                variant="danger"
                buttonSize="lg"
                link={btn2Link}
                customClass={classes['save-nav']}
              >
                {btn2Text}
              </Button>
            </Conditional>
            <Conditional if={btn3Link && btn3Text}>
              <Button
                variant="danger"
                buttonSize="lg"
                link={btn3Link}
                customClass={classes['save-nav']}
              >
                {btn3Text}
              </Button>
            </Conditional>
          </div>
        </div>
      </Conditional>

      <Conditional if={bannerType === 'imageOnly'}>
        <div className={` ${classes['heroImage']}`}>
          {/* <Conditional if={imgModalLink !== '' && imgModalTitle !== ''}> */}
          <BannerSeeDetailsModal
            mobileImageURL={mobileImageURL}
            imageURL={imageURL}
            imageAlt={imageAlt || ''}
            isFullUrl={isFullUrl}
            imageLink={imageLink ? imageLink : ''}
            url={imgModalLink}
            title={imgModalTitle}
            sObjectId={img_s_objectId}
          />
        </div>
      </Conditional>
    </>
  );
};

export default ImageBanner;
