import { DELIVERY_METHODS } from '@next-app/interface/PDP/PDPPageContent';
import { PDPState } from './PDPSlice.interface';

// export const selectPDPState = (state: PDPState) => state.PDP;
export const selectProductState = (state: PDPState, productId: string) => {
  return productId && productId in state.Products
    ? state.Products[productId]
    : {};
};

//Done
export const selectSelectedSkuId = (state: any, productId: string) => {
  return selectProductState(state, productId).selectedSkuId;
};

export const selectSelectedSku = (state: PDPState, productId: string) => {
  return selectProductState(state, productId).selectedSku;
};

export const accessoriesSelectSelectedSku = (state: any, productId: string) => {
  return selectProductState(state, productId).accessoriesSelectedSku;
};

//Done
export const selectIsInStorePickUp = (state: PDPState, productId: string) => {
  return (
    selectProductState(state, productId)?.delivery?.method ===
    DELIVERY_METHODS.IN_STORE_PICKUP
  );
};

//Done
export const selectPersonalization = (state: PDPState, productId: string) => {
  return selectProductState(state, productId).personalization;
};

//Done
export const selectSelectedGiftCardDetails = (
  state: PDPState,
  productId: string,
) => {
  return productId && productId in state.Products
    ? selectProductState(state, productId)?.selectedGiftCardDetails
    : {};
};

//pending for store card
export const selectAllPDPData = (state: PDPState, productId: string) => {
  return selectProductState(state, productId)?.pdpMainContents || [];
};

// done
export const selectSelectedItemQty = (
  state: PDPState,
  productId: string,
): number | undefined => {
  const pdpState = selectProductState(state, productId);
  return pdpState?.selectedItemQty?.[productId];
};
//done
export const selectProductHasChildSku = (
  state: PDPState,
  productId: string,
) => {
  return selectProductState(state, productId)?.productHasChildSku;
};

//done
export const selectParentSku = (state: PDPState, productId: string) => {
  return selectProductState(state, productId)?.parentSku;
};

//done
export const selectParentSkuId = (state: PDPState, productId: string) => {
  return selectProductState(state, productId)?.parentSkuId;
};

//not being used anywhere
export const selectAllChildSkuData = (state: PDPState, productId: string) => {
  return selectProductState(state, productId)?.pdpMainContents[1]
    ?.additionalProductInfo?.allChildSkus;
};

//Done
export const selectChildSkus = (state: PDPState, productId: string) => {
  return selectProductState(state, productId)?.pdpMainContents?.[1]
    ?.additionalProductInfo?.childSkus;
};

export const selectAdditionalProductInfo = (
  state: PDPState,
  productId: string,
) => {
  return selectProductState(state, productId)?.pdpMainContents?.[1]
    ?.additionalProductInfo;
};

//Done
export const selectInventoryInfo = (state: PDPState, productId: string) => {
  return selectProductState(state, productId)?.selectInventoryInfo;
};

//Done
export const selectIsInventoryExceeded = (
  state: PDPState,
  productId: string,
) => {
  return selectProductState(state, productId)?.selectIsInventoryExceeded;
};

// done
export const selectDropdownValues = (state: PDPState, productId: string) => {
  return selectProductState(state, productId)?.dropdownValues;
};

//Done
export const selectShowDropdownError = (state: PDPState, productId: string) => {
  return selectProductState(state, productId)?.showDropdownError;
};

//Store card pending
export const selectPdpMetaTagValues = (state: PDPState, productId: string) => {
  return selectProductState(state, productId)?.pdpMetaTagValues;
};

// done
export const getDeliveryMethod = (state: PDPState, productId: string) => {
  return selectProductState(state, productId)?.delivery?.method;
};

// done
export const selectCarouselDtmFlag = (state: PDPState, productId: string) => {
  return selectProductState(state, productId)?.carouselDtmFlag;
};

// done
export const selectSearchDtmFlag = (state: PDPState, productId: string) => {
  return selectProductState(state, productId)?.searchDtm;
};

// done
export const selectMboxid = (state: PDPState, productId: string) => {
  return selectProductState(state, productId)?.mboxid;
};

export const getMasterProductId = (state: any) => {
  return state.MasterProduct?.id || '';
};

export const selectShowQtyError = (state: PDPState, productId: string) => {
  return selectProductState(state, productId)?.showQtyError;
};

export const selectWebContent = (state: PDPState, productId: string) => {
  return selectProductState(state, productId)?.webContentCarousel;
};

export const selectStoreError = (state: PDPState, productId: string) => {
  return selectProductState(state, productId)?.showStoreError;
};

export const selectStoreOnlyStoreSelectionErrorMessage = (
  state: any,
  productId: string,
) => {
  return selectProductState(state, productId)?.storeOnlySelectStoreErrorMessage;
};
