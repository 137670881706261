import ProductCard from './ProductCard';
import classes from './product-card.module.scss';
import ProductCardHandler from './ProductCardHandler';
import Paginations from '../Pagination/Pagination';
import { IProductCardsProps } from '@next-app/interface/PLP/AssortmentListing';
import ProductListGridView from './ProductListGridView';
import DisrupterTile from '@next-app/components/DisrupterTile/DisrupterTile';
import Refinement from '../RefinementCrumbs/RefinementCrumbs';
import { getUniqueKey } from '@next-app/utils';
import Conditional from '@next-app/components/Conditional';
import { GiftCardType, RECORD_PER_ROW } from '@next-app/constants/constants';
import { checkViewGiftCard, generateDTMdata } from './helper';
import MoveToTop from './MoveToTop';

const ProductCards = ({
  contentItem,
  filtersLength,
  availabilityCount,
  imgBaseUrl,
  clearAllURL,
  refinementCrumbs,
  mainDimension,
  view: { value },
  isGSAUser,
}: IProductCardsProps) => {
  const {
    records,
    sortOptions,
    recordsPerPageOptions,
    totalNumRecs,
    recsPerPage,
    lastRecNum,
    pagingActionTemplate,
    hasEGiftCardRecords,
    hasGiftCardRecords,
    listingViewOptionTemplate,
    defaultSortOption,
  } = contentItem;

  const view = checkViewGiftCard(
    value,
    hasEGiftCardRecords,
    hasGiftCardRecords,
  );

  const cards: React.JSX.Element[] = [];
  const columns = 4;
  const getLastIndexId = records?.length;
  let dySKUData: string[] = [];

  records.forEach((product, index: number) => {
    dySKUData.push(product?.attributes?.['product.repositoryId']?.[0]);
    const rowIndex = Math.floor(index / columns);
    const colIndex = index % columns;

    const generateDtm = {
      label: mainDimension?.label || '',
      product,
      index,
      sortOptions,
      defaultSortOption,
      lastRecNum,
      recsPerPage,
      value,
      isGSAUser,
    };
    const dataPlpToDtm = generateDTMdata(generateDtm);

    if (
      !product.records &&
      !view &&
      product.attributes['record.type'] &&
      product.attributes['record.type'][0] === 'disruptorTile'
    ) {
      cards.push(
        <DisrupterTile
          contentItem={product.attributes}
          key={getUniqueKey(index, 'disrupterTile')}
        />,
      );
    } else if (
      !product.records &&
      ((product.attributes['record.type'] &&
        product.attributes['record.type'][0] === GiftCardType['E-Gift']) ||
        product.attributes['record.type'][0] === GiftCardType.Gift)
    ) {
      cards.push(
        <strong className={classes['giftCardHeader']}>
          {product.attributes['headerLabel']}
        </strong>,
      );
    } else if (product.records) {
      cards.push(
        <ProductCard
          key={index}
          product={product}
          isListView={view}
          imgBaseUrl={imgBaseUrl}
          automationId={`record_${rowIndex}${colIndex}`}
          lastIndexAutomationId={getLastIndexId - 1 === index ? 'lastRec' : ''}
          dataPlpToDtm={dataPlpToDtm}
          isGSAUser={isGSAUser}
          isCardAboveTheFold={index <= 7}
          hasEGiftCardRecords={hasEGiftCardRecords}
          hasGiftCardRecords={hasGiftCardRecords}
        />,
      );
    }
  });

  if (contentItem.bannerRecords && !view) {
    contentItem.bannerRecords.map(({ attributes }) => {
      const isMobile = attributes?.scene7Asset[0].includes('_Mobile_Tile');
      const location = parseInt(attributes?.tileInsertLocation[0]);
      const generateDtm = {
        label: mainDimension?.label || '',
        product: {},
        index: location * RECORD_PER_ROW,
        sortOptions,
        defaultSortOption,
        lastRecNum,
        recsPerPage,
        value,
        isGSAUser,
      };
      if (!isMobile) {
        cards.splice(
          location * RECORD_PER_ROW,
          0,
          <DisrupterTile
            contentItem={attributes}
            dataPlpToDtm={generateDTMdata({
              ...generateDtm,
              attributes,
              tile: true,
            })}
            key={getUniqueKey(location * RECORD_PER_ROW, 'disrupterTile')}
          />,
        );
      } else {
        cards.splice(
          location,
          0,
          <DisrupterTile
            contentItem={attributes}
            dataPlpToDtm={generateDTMdata({
              ...generateDtm,
              attributes,
              tile: true,
            })}
            key={getUniqueKey(location * RECORD_PER_ROW, 'disrupterTile')}
          />,
        );
      }
    });
  }

  return (
    <div className={classes['product-resultList-container']}>
      <ProductListGridView
        hasEGiftCardRecords={hasEGiftCardRecords}
        hasGiftCardRecords={hasGiftCardRecords}
        listingView={view}
        listingViewOptionTemplate={listingViewOptionTemplate}
      />

      <Conditional if={!hasEGiftCardRecords && !hasGiftCardRecords}>
        <ProductCardHandler
          sortOptions={sortOptions}
          items={recordsPerPageOptions}
          totalNumRecs={totalNumRecs}
          recsPerPage={recsPerPage}
          lastRecNum={lastRecNum}
          filtersLength={filtersLength || false}
          pagingActionTemplate={pagingActionTemplate}
          availabilityCount={availabilityCount}
        />
      </Conditional>

      <Conditional if={refinementCrumbs && refinementCrumbs.length > 0}>
        <Refinement
          clearAllURL={clearAllURL}
          refinementCrumbs={refinementCrumbs}
        />
      </Conditional>

      <div className={`row ${classes['product-card-container']}`}>{cards}</div>
      <div className={classes['product-resultList-footer']}>
        <div className="order-md-2">
          <Paginations
            totalNumRecs={totalNumRecs}
            recsPerPage={recsPerPage}
            lastRecNum={lastRecNum}
            pagingActionTemplate={pagingActionTemplate}
          />
        </div>

        <MoveToTop />
      </div>
    </div>
  );
};

export default ProductCards;
