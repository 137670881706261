import { CommerceItemsEntity1 } from '@next-app/interface/PDP/PDPAddToCart';

export const findWarrentyBadge = (badgesList: any, badge: string) =>
  (badgesList && badgesList[badge]) || null;

export const decimalParse = (value: string | number) => {
  if (value !== null && value !== undefined) {
    const numValue = typeof value === 'string' ? parseFloat(value) : value;
    return numValue.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } else {
    return '0.00';
  }
};

export const compareTwoValue = (value1: string, value2: string) => {
  return decimalParse(value1) !== decimalParse(value2);
};

export const isNotEmptyArray = (arr: any[]) => {
  return arr && arr.length > 0;
};

export const isNotEmptyObject = (value: any = {}) => {
  return value !== null && value !== undefined && Object.keys(value).length > 0;
};

export const isEmptyObject = (value: any = {}) => {
  return !isNotEmptyObject(value);
};

export const getCartData = (items: CommerceItemsEntity1[]) => {
  return (
    items &&
    items?.map((el) => {
      const { productId, catalogRefId, quantity, priceInfo } = el;
      return {
        item: catalogRefId,
        skuId: catalogRefId,
        productId: productId,
        qty: quantity,
        price: `$${priceInfo?.amount / quantity}`,
      };
    })
  );
};

export const getPromoStatus = (sku?: {
  onSale?: string;
  skuStatus?: string;
  restrictions?: {
    message?: string;
  } | null;
  skuType?: string;
}) => {
  let promoStatus = 'promo-eligible';

  if (sku?.onSale && sku?.skuStatus !== 'CLEARANCE') {
    promoStatus = 'sale';
  } else if (sku?.skuStatus === 'CLEARANCE') {
    promoStatus = 'clearance';
  } else if (sku?.restrictions != undefined && sku?.restrictions?.message) {
    promoStatus = 'shipping-restricted';
  } else if (sku?.skuType === 'giftcard' || sku?.skuType === 'eGiftcard') {
    promoStatus = 'gift-card';
  }
  return promoStatus;
};

export const toKebabCase = (str: string) => {
  return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
};
