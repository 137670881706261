import { Column } from '@next-app/interface/Common';
import { LinkFooterLink } from '@next-app/interface/PageContent';
import { findComponent, getUniqueKey } from '@next-app/utils';
import classes from './footer-link-column.module.scss';

interface FooterLinkColumnProps {
  contentItem: Column;
}

export default function FooterLinkColumn(props: FooterLinkColumnProps) {
  const { links, ulClass } = props.contentItem;
  const LinksComponentList = links.map(
    (item: LinkFooterLink, index: number) => {
      const Component = findComponent(item);

      if (!Component) {
        return null;
      }

      return (
        <li key={`FootLink-${item.name}-${index}`}>
          <Component contentItem={item} key={getUniqueKey(index, item.name)} />
        </li>
      );
    },
  );

  return (
    <ul className={`${classes.ulClass} ${ulClass}`}>{LinksComponentList}</ul>
  );
}
