'use client';
import { useState } from 'react';
import HTMLContentModal from '../HTMLContentModal/HTMLContentModal';
import Link from '../Link/Link';

const SeeDetailsLinkModal = (props: any) => {
  const [modalUrl, setModalUrl] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  function handleClick() {
    setModalTitle(props.title);
    setModalUrl(props.url);
  }

  const elemAttributes = {
    s_objectid: props?.s_objectId || '',
    s_regionid: props?.s_regionId || '',
  };

  return (
    <>
      <Link
        className={props.class}
        href=""
        onClick={() => {
          handleClick();
        }}
        data-bs-toggle="modal"
        data-bs-target={`#microBannerSeeDetailsModal`}
        {...elemAttributes}
      >
        {props.linkText}
      </Link>
      <HTMLContentModal
        url={modalUrl}
        modalTitle={modalTitle}
        modalId={`microBannerSeeDetailsModal`}
      />
    </>
  );
};

export default SeeDetailsLinkModal;
