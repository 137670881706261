import classes from './error-message.module.scss';
interface ErrorMessageProps {
  children: string;
  customClass?: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = (props) => {
  const { children, customClass } = props;

  return <span className={`${classes.error} ${customClass}`}>{children}</span>;
};

export default ErrorMessage;
