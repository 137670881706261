import { IPageView } from '@next-app/interface/PLP/ProductListPage';
import { compareTwoValue, decimalParse } from '../ProductDetails/helper';
import { IGeneratePrice } from '@next-app/interface/PLP/AssortmentListing';

export const checkViewGiftCard = (
  value: string,
  hasEGiftCardRecords?: boolean,
  hasGiftCardRecords?: boolean,
) => {
  return value === 'list' && (!hasGiftCardRecords || !hasEGiftCardRecords)
    ? true
    : false;
};

export const generateDTMdata = ({
  label,
  product,
  index,
  sortOptions,
  defaultSortOption,
  lastRecNum,
  recsPerPage,
  value,
  isGSAUser,
  attributes,
  tile = false,
}: IGeneratePrice) => {
  const sortValue =
    sortOptions?.find(({ selected }: any) => selected === true)?.label ||
    defaultSortOption?.label;
  const activePage = Math.ceil(lastRecNum / recsPerPage);

  return {
    categoryName: label,
    price: getProductPrice(product, isGSAUser),
    product: tile
      ? 'dsrpt_tile_clk'
      : product?.attributes && product?.attributes['product.repositoryId']
        ? product?.attributes['product.repositoryId'][0]
        : '',
    position: index + 1,
    page: activePage,
    layout: value,
    sortType: sortValue,
    recsPerPage: recsPerPage,
    ...(tile
      ? {
          disruptorLocation: `Row ${Number(attributes?.tileInsertLocation?.[0]) + 1}`,
          disruptorName: attributes?.imageAltText?.[0],
          slots:
            Number(attributes?.slotsToSpan?.[0]) > 1
              ? Number(attributes?.slotsToSpan?.[0])
              : '',
          showColumn:
            attributes?.['record.type'][0] === 'disruptorTile' &&
            !(attributes.slotsToSpan?.[0] === '3'),
        }
      : null),
  };
};

function renderPriceRange(minPrice: string, maxPrice: string, sku: boolean) {
  let result = `$${decimalParse(minPrice)}`;

  if (sku && compareTwoValue(minPrice, maxPrice)) {
    result += ` - $${decimalParse(maxPrice)}`;
  }

  return result;
}

const getProductPrice = (product: any, isGSAUser: string) => {
  const isGSA = isGSAUser === 'true';
  const {
    attributes: {
      'product.saleMinPrice': productSaleMinPrice = 0,
      'product.saleMaxPrice': productSaleMaxPrice = 0,
      'product.minPrice': productMinPrice = 0,
      'product.maxPrice': productMaxPrice = 0,
      'product.onSale': onSale = [],
      'product.activeSkus': activeSku = [],
      'product.isGC': isGiftCard = [],
      'product.gcPriceRange': gcPriceRange = [],
      'product.isGSAPrice': isGSAPrice = [],
    } = {},
  } = product || {};
  const sku = activeSku?.[0]?.split(',') || [];
  const isOnSale = onSale?.[0] === 'true';

  const getProductPrice = () => {
    if (isGSA && isGSAPrice?.[0] === '1') {
      return (
        renderPriceRange(
          productSaleMinPrice,
          productSaleMaxPrice,
          sku.length > 1,
        ) + ' GSA'
      );
    } else if (!isGSA || isGSAPrice?.[0] !== '1') {
      if (isOnSale) {
        return renderPriceRange(
          productSaleMinPrice,
          productSaleMaxPrice,
          sku.length > 1,
        );
      } else {
        return renderPriceRange(
          productMinPrice,
          productMaxPrice,
          sku.length > 1,
        );
      }
    }
    return '';
  };

  let result = '';

  if (isGiftCard?.[0] === 'true') {
    result = gcPriceRange;
  } else if (isGiftCard?.[0] !== 'true') {
    result = getProductPrice();
  }

  return result;
};

export const setViewValue = (
  viewValueFromSearchQuery: string,
  viewFromCookie: IPageView,
) => {
  if (viewValueFromSearchQuery) {
    return viewValueFromSearchQuery as 'list' | 'grid';
  }

  if (viewFromCookie?.value === 'list' || viewFromCookie?.value === 'grid') {
    return viewFromCookie.value as 'list' | 'grid';
  }
  return 'grid';
};
