const mobileClass = () => {
  if (typeof window !== 'undefined' && navigator && navigator?.userAgent) {
    const isAndroid = /Android/i.test(navigator.userAgent);
    const isIOS = /iP(hone|ad|od)/i.test(navigator.userAgent);
    const isMacOS = /Macintosh|Mac OS X/i.test(navigator.userAgent);

    const mobileType = isAndroid
      ? 'android-device'
      : isIOS || isMacOS
        ? 'apple-device'
        : 'desktop-device';

    return mobileType;
  }

  return;
};

export default mobileClass;
