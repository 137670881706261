import urlMapping from '@next-app/config/urlMapping';
import { populateTemplateString } from './common-utils';

const getPageType = (productFilter: string[] = []) => {
  return productFilter.includes('s') ? 's' : 'p';
};

export async function generatePDPPath(productFilter: string[], isGSA: boolean) {
  const gsa = isGSA === true ? 'gsa' : '';
  const productId = productFilter.pop() || '';
  const pageType = getPageType(productFilter);

  const generatedPDPAPIEndpoint = populateTemplateString(
    urlMapping.pdpContentAPI,
    { productId, gsa, pageType },
  ).replaceAll('//', '/');
  return generatedPDPAPIEndpoint;
}
