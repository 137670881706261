'use client';

import { isNotEmptyObject } from '@next-app/components/shared/ProductDetails/helper';
import { useEffect } from 'react';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    DY: any;
    DYO: any;
    dyInit: any;
  }
}

const InjectDynamicYield = ({
  page,
  data,
}: {
  page: string | undefined;
  data: any;
}) => {
  const recommendationContext: { type: string; lng: string; data?: any } = {
    type: page ? page.toUpperCase() : 'OTHER',
    lng: 'en_US',
  };
  if (data && data.length) {
    recommendationContext.data = data;
  }
  const dyData = recommendationContext;

  useEffect(() => {
    if (
      window &&
      (dyData.type == 'HOMEPAGE' || isNotEmptyObject(dyData?.data)) &&
      window.dyInit
    ) {
      window.dyInit(dyData);
    }
  }, [dyData]);

  return <></>;
};

export default InjectDynamicYield;
