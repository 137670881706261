'use client';

import Link from '@next-app/components/elements/Link/Link';
import { useSelector } from 'react-redux';
import classes from './account-menu-link.module.scss';
import { Link as ILink } from '@next-app/interface/Common';

const CardSection = (link: ILink) => {
  const { sessionInfo } = useSelector((state: any) => state.init);

  if (!Object.keys(sessionInfo).length) {
    return;
  }

  const cartQty = sessionInfo?.sessionInfo?.cartInfo?.cartQty;

  return (
    <div className={`${classes['link-wrapper']}`}>
      <Link
        className="no-decor header-link"
        aria-label="There are no items in your cart"
        tabIndex={0}
        automation-id="nh_cart_icon"
        href="/shopping-cart/"
        data-updated="false"
        s_objectid={link?.s_objectId}
      >
        <span
          className={classes['cart-icon']}
          title={`There are ${cartQty > 0 ? cartQty : 'no'} items in your cart`}
        ></span>
        {cartQty > 0 && (
          <span className="hasHref">
            <span className={classes['cart-badge']}>{cartQty}</span>
          </span>
        )}
        <span
          className={classes['text-icon']}
          role="presentation"
          aria-hidden="true"
          tabIndex={-1}
        >
          Cart
        </span>
      </Link>
    </div>
  );
};

export default CardSection;
