import { COMPONENTS_MAP } from '../config/components-map';

export default function findComponent(item: any) {
  if (!item?.['@type']) {
    return null;
  }

  const DynamicComponent = COMPONENTS_MAP[item['@type']];

  if (!DynamicComponent) {
    // Added this check to ensure that application should not break
    // if we get an unknown cartridge at runtime.
    const EmptyComponent = () => <></>;
    return EmptyComponent;
  }

  return DynamicComponent;
}
