'use client';

import React from 'react';
import styles from './facets.module.scss';
import { SubCategoryProps, IRefinement } from '../../../interface/PLP/index';
import Conditional from '@next-app/components/Conditional';
import { makeFetchCall } from '@next-app/utils/fetch-data';
import {
  replaceNarrowBy,
  handleClick,
  getSubCatProps,
  createHref,
} from './helper';
import Link from '@next-app/components/elements/Link/Link';
import { getUrl, trimURL } from '@next-app/utils/get-guided-data';
import { dyEvent } from '@next-app/utils/dy-event';
import { useRouter } from 'next/navigation';

const Subcategory = (props: SubCategoryProps) => {
  const {
    contentItem: categories,
    setUpdatedUrl,
    getFilterLabel,
    setIsFilter,
    filterLabel,
    isFilter = false,
    setSelectedFilter,
    setFilterLabel,
    setFilterCount,
    assortmentTitle,
  } = props || {};

  const router = useRouter();

  const handleDYEvent = (value: string, link: string = '') => {
    const properties = {
      dyType: 'filter-items-v1',
      filterType: replaceNarrowBy(categories?.name),
      filterStringValue: value,
    };
    dyEvent({ properties: properties, eventName: 'Filter Items' });
    if (link != '' && link?.includes('+')) {
      router.push(trimURL(link));
    }
  };

  const selectedObject = categories?.refinements?.find(
    (item) =>
      item.properties?.selected === 'true' &&
      item.properties?.isParentCategory === 'true',
  );

  const renderSubcategories = (categoryId: string) => {
    return categories?.refinements
      ?.filter(
        (subCat: IRefinement) =>
          subCat?.properties[
            'category.parentCategoriesForCatalog.repositoryId'
          ] === categoryId &&
          (!subCat.properties.isParentCategory ||
            !subCat?.properties?.hasSubCategory),
      )
      ?.map((sub, index: number) => (
        <li
          data-testid={`refinement-${index}`}
          key={index}
          onClick={(e) => {
            e.preventDefault();
            handleDYEvent(sub?.label);
          }}
        >
          <Link
            className={`${styles.link} ${sub?.properties['selected'] === 'true' && styles.removeUnderline}`}
            {...(sub?.link.includes('+')
              ? {
                  onClick: (e) => {
                    e.preventDefault();
                    router.push(sub?.link);
                  },
                }
              : { href: trimURL(sub?.link) })}
          >
            <Conditional if={sub?.properties['selected'] === 'true'}>
              <strong>{`${sub?.label} (${sub?.count})`}</strong>
            </Conditional>
            <Conditional if={sub?.properties['selected'] !== 'true'}>
              <>{`${sub?.label} (${sub?.count})`}</>
            </Conditional>
          </Link>
        </li>
      ));
  };

  const fetchData = async (selectedProductLink: string) => {
    const url = `${selectedProductLink}`;
    let navUrl = url;
    if (!navUrl.startsWith('/')) {
      navUrl = '/' + url;
    }
    const newUrl = getUrl(navUrl);

    try {
      const responseData = await makeFetchCall({
        endpoint: `${newUrl}`,
      });

      setSelectedFilter(responseData);
      const getCount =
        responseData?.mainContent[0]?.productListingInfo[0]?.mainDimension
          ?.count ||
        responseData?.contents[0]?.mainContent[0]?.contents[0]
          ?.productListingInfo[0]?.mainDimension?.count;
      setFilterCount(getCount);
      return responseData;
    } catch (error) {
      console.error('Error fetching searching products:', error);
      return [];
    }
  };

  const handleLabel = (value: string) => {
    setIsFilter(value);
    setFilterLabel(value);
    getFilterLabel(value);
  };

  const renderMobSubcategories = (categoryId: string) => {
    const filteredCategory = categories?.refinements?.filter(
      (subCat: IRefinement) =>
        subCat?.properties[
          'category.parentCategoriesForCatalog.repositoryId'
        ] === categoryId &&
        (!subCat.properties.isParentCategory ||
          !subCat?.properties?.hasSubCategory),
    );
    return (
      <Conditional if={filteredCategory.length > 0}>
        <ul className={styles.subbox}>
          {filteredCategory?.map((sub, index: number) => (
            <li key={index}>
              <Link
                data-testid={`sub-link-${index}`}
                onClick={() => {
                  fetchData(sub?.link);
                  setUpdatedUrl(sub?.link);
                  handleDYEvent(sub?.label);
                }}
              >
                <Conditional if={sub?.properties['selected'] === 'true'}>
                  <strong>{`${sub?.label} (${sub?.count})`}</strong>
                </Conditional>

                <Conditional if={sub?.properties['selected'] !== 'true'}>
                  <>{`${sub?.label} (${sub?.count})`}</>
                </Conditional>
              </Link>
            </li>
          ))}
        </ul>
      </Conditional>
    );
  };

  const getCategory = () => {
    const filterData = categories?.refinements?.filter((cat: IRefinement) =>
      cat?.properties?.isParentCategory
        ? cat?.properties?.isParentCategory
        : cat?.properties?.subCategoryLevel === '1',
    );

    const subCatData = categories?.refinements?.filter((cat: IRefinement) =>
      cat?.properties?.subCategoryLevel
        ? cat?.properties?.subCategoryLevel === '2'
        : !cat?.properties?.isParentCategory,
    );

    const subCatSelectedData = subCatData.find(
      (subCat) => subCat?.properties['selected'] === 'true',
    );

    return filterData.map((category, index: number) => (
      <li key={index} className={`${styles.list}`}>
        <div className={styles['accordion-box']}>
          <Conditional if={subCatData?.length > 0}>
            <Link
              className={styles['icon-space']}
              {...(category?.link?.includes('+')
                ? {
                    onClick: (event) =>
                      handleClick(event, trimURL(category?.link), router),
                  }
                : {
                    href: createHref(category, categories, subCatSelectedData),
                  })}
            >
              <span
                className={`ls-icon ${category?.properties['selected'] === 'true' ? 'icn-minus-lgt' : 'icn-plus-lgt'}`}
              ></span>
            </Link>
          </Conditional>
          <Link
            className={`${styles.link} ${subCatData?.length === 0 ? styles.nonExpend : ''}`}
            {...getSubCatProps(
              category,
              categories,
              subCatSelectedData,
              handleDYEvent,
            )}
          >
            <Conditional if={category?.properties['selected'] === 'true'}>
              <strong>{`${category?.label} (${category?.count})`}</strong>
            </Conditional>

            <Conditional if={category?.properties['selected'] !== 'true'}>
              <>{`${category?.label} (${category?.count})`}</>
            </Conditional>
          </Link>
        </div>
        <Conditional if={category?.properties['selected'] === 'true'}>
          <ul className={styles.subbox}>
            {renderSubcategories(
              category?.properties['category.repositoryId']
                ? category?.properties['category.repositoryId']
                : category?.properties['dimval.prop.subcategory.repositoryId'],
            )}
          </ul>
        </Conditional>
      </li>
    ));
  };

  const allSelected = categories?.refinements?.filter(
    (subItem: IRefinement) => subItem?.properties?.selected === 'true',
  );
  const childSelected =
    allSelected?.length > 1 ? allSelected[1]?.label : allSelected[0]?.label;

  return (
    <>
      <div className={styles.container}>
        <h4 className={styles.title}>{categories?.name}</h4>
        <ul className={styles.subContainer}>
          <Conditional if={!!assortmentTitle || !!categories?.assortmentName}>
            <strong>
              <Link
                {...(categories?.assortmentURL?.includes('+')
                  ? {
                      onClick: () =>
                        router.push(trimURL(categories?.assortmentURL)),
                    }
                  : { href: trimURL(categories?.assortmentURL) })}
                className={`${styles['labelBold']} ${styles['pointer']}`}
                dangerouslySetInnerHTML={{
                  __html: `${assortmentTitle || categories?.assortmentName} (${categories?.assortmentRecsCount})`,
                }}
              />
            </strong>
          </Conditional>
          <Conditional if={!!categories?.mainCategoryDisplay}>
            <strong>
              <Link
                href={trimURL(categories?.mainCategoryLink)}
                className={styles.labelBold}
                dangerouslySetInnerHTML={{
                  __html: `${categories?.mainCategoryDisplay}`,
                }}
              />
            </strong>
          </Conditional>
          {categories?.refinements && getCategory()}
        </ul>
      </div>

      <div className={styles.mobContainer}>
        <Conditional if={isFilter === replaceNarrowBy(categories?.name)}>
          <div className={styles['filter-sub-container']}>
            <div
              data-testid="filter-sub-title"
              className={styles['filter-sub-title']}
              onClick={() => {
                setIsFilter('All');
                getFilterLabel('All');
              }}
            >
              <h2 className={styles['title']}>
                <span className={styles['left-arrow']}>
                  <span className={styles.mobArrow}></span>
                </span>
                {filterLabel}
                <hr className={`${styles['hr-divider']} ${styles['mb0']}`} />
              </h2>
            </div>
            <ul className={`${styles.list}`}>
              <Conditional if={categories?.refinements?.length > 0}>
                <Conditional if={categories?.refinements?.length > 0}>
                  <div>
                    {Object.keys(selectedObject || {})?.length ? (
                      <li
                        key={selectedObject?.label}
                        className={styles.contentItem}
                      >
                        <a
                          data-testid="subCat-link"
                          className={styles.link}
                          onClick={() => {
                            fetchData(selectedObject?.link || '');
                            setUpdatedUrl(selectedObject?.link);
                            handleDYEvent(selectedObject?.label || '');
                          }}
                        >
                          <strong>{`${selectedObject?.label} (${selectedObject?.count})`}</strong>
                        </a>
                        {renderMobSubcategories(
                          selectedObject?.properties['category.repositoryId'] ||
                            selectedObject?.properties[
                              'dimval.prop.subcategory.repositoryId'
                            ],
                        )}
                      </li>
                    ) : (
                      // If no selectedObject, proceed with .map() logic
                      categories?.refinements
                        ?.filter((cat: IRefinement) =>
                          cat?.properties?.isParentCategory
                            ? cat?.properties?.isParentCategory
                            : cat?.properties?.subCategoryLevel === '1',
                        )
                        ?.map((subItem: IRefinement, idx: number) => (
                          <li key={idx} className={styles.contentItem}>
                            <a
                              className={`${styles['link']} ${styles['bold']}`}
                              onClick={() => {
                                fetchData(subItem?.link);
                                setUpdatedUrl(subItem?.link);
                                handleDYEvent(subItem?.label);
                              }}
                            >
                              <Conditional
                                if={subItem?.properties['selected'] === 'true'}
                              >
                                <strong>{`${subItem?.label} (${subItem?.count})`}</strong>
                              </Conditional>
                              <Conditional
                                if={subItem?.properties['selected'] !== 'true'}
                              >
                                <b>
                                  {`${subItem?.label} (${subItem?.count})`}
                                  <Conditional
                                    if={
                                      subItem?.properties[
                                        'isParentCategory'
                                      ] === 'true'
                                    }
                                  >
                                    <span className={styles.mobArrow}></span>
                                  </Conditional>
                                </b>
                              </Conditional>
                            </a>
                            <Conditional
                              if={subItem?.properties['selected'] === 'true'}
                            >
                              <>
                                {renderMobSubcategories(
                                  subItem?.properties[
                                    'category.repositoryId'
                                  ] ||
                                    subItem?.properties[
                                      'dimval.prop.subcategory.repositoryId'
                                    ],
                                )}
                              </>
                            </Conditional>
                          </li>
                        ))
                    )}
                  </div>
                </Conditional>
              </Conditional>
            </ul>
          </div>
        </Conditional>
        <Conditional if={isFilter !== replaceNarrowBy(categories?.name)}>
          <h2
            data-testid="heading2"
            className={styles.title}
            onClick={() => {
              handleLabel(replaceNarrowBy(categories?.name));
            }}
          >
            {replaceNarrowBy(categories?.name)}{' '}
            <span className={styles.mobArrow}></span>
            <Conditional if={!!childSelected}>
              <span className={styles.subheading}>
                <span
                  className={styles.selected}
                  dangerouslySetInnerHTML={{
                    __html: `${childSelected}`,
                  }}
                ></span>
              </span>
            </Conditional>
          </h2>
        </Conditional>
      </div>
    </>
  );
};

export default Subcategory;
