import React, { useEffect, useState } from 'react';
import Button from '../Button/Button';
import classes from './custom-select-option.module.scss';
import { ISortItems } from '@next-app/interface/PLP';
import { useSelector } from 'react-redux';
import {
  selectInventoryInfo,
  selectIsInStorePickUp,
  selectSelectedItemQty,
} from '@next-app/lib/features/PDP/PDPSelectors';
import { PDPState } from '@next-app/lib/features/PDP/PDPSlice.interface';

interface ICustomSelectOption {
  options: ISortItems[];
  defaultValue: string | Number;
  onChangeHandler: Function;
  selectionKey?: string;
  customClass?: string;
  customButtonClass?: string;
  productId?: string;
}

const CustomSelectOption = ({
  options,
  defaultValue,
  onChangeHandler,
  customClass,
  selectionKey,
  customButtonClass,
  productId,
}: ICustomSelectOption) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [selected, setSelected] = useState(defaultValue);

  const showDropDownHandler = () => {
    setShowDropDown(!showDropDown);
  };
  const isStorePickup = useSelector(
    (state: PDPState) => productId && selectIsInStorePickUp(state, productId),
  );
  const inventorydata = useSelector(
    (state: PDPState) => productId && selectInventoryInfo(state, productId),
  );
  const selectedInventoryqty = inventorydata?.qtyAvailable;

  const selectedValue =
    useSelector((state: PDPState) =>
      selectSelectedItemQty(state, String(productId)),
    ) || selected;

  useEffect(() => {
    if (showDropDown) {
      document.addEventListener('click', showDropDownHandler);
    } else {
      document.removeEventListener('click', showDropDownHandler);
    }
    return () => {
      document.removeEventListener('click', showDropDownHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDropDown]);

  const onSelectHandler = (data: string) => {
    onChangeHandler(data);
    setSelected(data);
  };

  return (
    <div className={`${classes['select-option-wrp']} ${customClass}`}>
      <Button
        customClass={`${classes['select-option']} ${customButtonClass}`}
        onClick={() => showDropDownHandler()}
      >
        {selectedValue}
        <span className={`caret caret-select ${classes['caret-icon']}`}></span>
      </Button>
      <ul
        className={`${classes['dropDown-val']} ${showDropDown ? `d-block ${classes['show-dropdown']}` : ''}`}
        onClick={() => showDropDownHandler()}
      >
        {options.map((item: any, index: number) => {
          const shouldDisable =
            isStorePickup && selectedInventoryqty < Number(item.value)
              ? true
              : false;

          return (
            <li
              key={index}
              className={`${shouldDisable ? classes.disableItems : ''} ${selectedValue === (selectionKey ? item[selectionKey] : item.label) ? classes.active : ''}`}
              onClick={() =>
                !shouldDisable &&
                onSelectHandler(selectionKey ? item[selectionKey] : item.label)
              }
            >
              {item?.label}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CustomSelectOption;
