'use client';

import { INavigationState } from '@next-app/interface/PLP';
import classes from './pagination.module.scss';
import { useRouter } from 'next/navigation';
import redirectionPath from '@next-app/utils/pathRedirectionAndScroll';
import { windowScroll } from '@next-app/utils/pathRedirectionAndScroll';
import Link from '@next-app/components/elements/Link/Link';

interface PaginationProps {
  recsPerPage: number;
  totalNumRecs: number;
  lastRecNum: number;
  type?: string;
  handlePagination?: Function;
  pagingActionTemplate?: INavigationState;
}

const Paginations = ({
  recsPerPage,
  totalNumRecs,
  lastRecNum,
  type = 'plp',
  handlePagination,
  pagingActionTemplate,
}: PaginationProps) => {
  const activePage = Math.ceil(lastRecNum / recsPerPage);
  const totalPages = Math.ceil(totalNumRecs / recsPerPage);
  const router = useRouter();

  const scrollReviewSection = type === 'pdp' ? '#llReviews' : null;

  const handleClick = (page: number) => {
    if (type === 'pdp' && handlePagination) {
      handlePagination(page);
    } else {
      const urlTemplate = pagingActionTemplate?.link || '';
      const path = urlTemplate?.replace('{X}', page.toString());
      redirectionPath(router, path);
    }
  };

  const conditionalPageReturn = (value: number) => {
    return (
      <Link
        key={value}
        className={`${classes['page']} ${activePage === value ? classes['active'] : ''}`}
        onClick={() => {
          if (scrollReviewSection) window.location.href = scrollReviewSection;
          handleClick(value);
        }}
      >
        {value}
      </Link>
    );
  };

  const handlePrevNext = (direction: string) => {
    const prevClicks = Math.max(activePage - 1, 1);
    const nextClicks = Math.min(activePage + 1, totalPages);
    if (type === 'pdp' && handlePagination) {
      if (direction === 'prev') {
        handlePagination(prevClicks);
      } else {
        handlePagination(nextClicks);
      }
    } else {
      const urlTemplate = pagingActionTemplate?.link || '';
      let path = '';
      if (direction === 'prev') {
        path = urlTemplate.replace('{X}', prevClicks.toString());
      } else {
        path = urlTemplate.replace('{X}', nextClicks.toString());
      }
      window.history.pushState(null, '', path);
      setTimeout(() => {
        router.push(path);
      }, 0);
    }
  };

  const renderPagination = () => {
    let pages = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(conditionalPageReturn(i));
      }
    } else {
      if (activePage <= 3) {
        for (let i = 1; i <= 4; i++) {
          pages.push(conditionalPageReturn(i));
        }
        pages.push(<span key="dots">...</span>);
        pages.push(conditionalPageReturn(totalPages));
      } else if (activePage > totalPages - 3) {
        pages.push(conditionalPageReturn(1));
        pages.push(<span key="dots1">...</span>);
        for (let i = totalPages - 3; i <= totalPages; i++) {
          pages.push(conditionalPageReturn(i));
        }
      } else {
        pages.push(conditionalPageReturn(1));
        pages.push(<span key="dots1">...</span>);
        for (let i = activePage - 1; i <= activePage + 1; i++) {
          pages.push(conditionalPageReturn(i));
        }
        pages.push(<span key="dots2">...</span>);
        pages.push(conditionalPageReturn(totalPages));
      }
    }
    return pages;
  };

  if (totalPages <= 1) {
    return <></>;
  }

  return (
    <>
      <div
        data-testid="back-to-top"
        className={classes['back-to-top-footer']}
        onClick={windowScroll}
      >
        <Link id="back-to-top" href="" className={`${classes['back-to-top']}`}>
          <i className={`ls-icon ${classes['icn-back-to-top']}`}></i>
          <span className={classes['product-resultList-footer-button']}></span>
        </Link>
      </div>

      <div className={classes['pagination']}>
        <Link
          className={`${classes['prev']} ${activePage === 1 ? classes['hidden'] : ''}`}
          onClick={() => {
            if (scrollReviewSection) window.location.href = scrollReviewSection;
            handlePrevNext('prev');
          }}
        >
          <i className={`ls-icon ${classes['icn-chevron-lft']}`}></i>
        </Link>
        {renderPagination()}
        <Link
          className={`${classes['next']} ${activePage === totalPages ? classes['hidden'] : ''}`}
          onClick={() => {
            if (scrollReviewSection) window.location.href = scrollReviewSection;
            handlePrevNext('next');
          }}
        >
          <i className={`ls-icon ${classes['icn-chevron-rgt']}`}></i>
        </Link>
      </div>
    </>
  );
};

export default Paginations;
