import Conditional from '@next-app/components/Conditional';
import classes from './menu-link.module.scss';
import { extractDimensionsFromUrl } from '@next-app/utils/getImageHeightWidthDynamic';
import { DesktopMenuLinkImageInterface } from '@next-app/interface/Header';
import ResponsiveImageClient from '@next-app/components/elements/ResponsiveImageClient/ResponsiveImageClient';

const DesktopMenuLinkImages = ({
  isWeb,
  linkType,
  imageLink,
  imageDecorations,
  imageURL,
  imageAltText,
  imageBottomText,
  s_objectId,
}: DesktopMenuLinkImageInterface) => {
  const { width, height } = extractDimensionsFromUrl(imageURL);

  return (
    <Conditional if={isWeb && linkType == 'image'}>
      <a
        href={imageLink}
        {...{
          s_objectid: s_objectId || '',
        }}
        className={`${classes['menu-link-img']} ${imageDecorations ? classes['imageDecorations'] : ''}`}
      >
        <ResponsiveImageClient
          fill={false}
          className={`${classes.image} position-static`}
          mobileImagePath={`/${imageURL}`}
          tabletImagePath={`/${imageURL}`}
          desktopImagePath={`/${imageURL}`}
          altText={imageAltText}
          isFullUrl={true}
          width={width}
          height={height}
        />
        <span>{imageBottomText}</span>
      </a>
    </Conditional>
  );
};

export default DesktopMenuLinkImages;
