import parse from 'html-react-parser';
import Conditional from '@next-app/components/Conditional';
import {
  generateBreadcrumbSchema,
  SchemaMarkup,
} from '@next-app/utils/schemaGeneration';
import classes from './breadcrumb.module.scss';

const PLPBreadcrumbs = (props: {
  data: Record<string, string>;
}): React.JSX.Element => {
  const breadcrumbs = Object.keys(props.data).map((key) => ({
    label: key,
    link: props.data[key],
  }));

  const schemaMarkupData = {
    id: 'breadcrumbs',
    data: generateBreadcrumbSchema(breadcrumbs),
  };

  return (
    <div className={`${classes.breadcrumb} d-none d-lg-block`}>
      <SchemaMarkup {...schemaMarkupData} />
      {breadcrumbs.map((breadcrumb, index) => (
        <span key={index}>
          <Conditional if={index !== breadcrumbs.length - 1}>
            <span>
              <a href={breadcrumb.link}>{parse(breadcrumb.label)}</a>
              <span className={classes.separator}> </span>
            </span>
          </Conditional>
          <Conditional if={index === breadcrumbs.length - 1}>
            <span>{parse(breadcrumb.label)}</span>
          </Conditional>
        </span>
      ))}
    </div>
  );
};

export default PLPBreadcrumbs;
