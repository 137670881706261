import NextImage, { ImageProps as NextImageProps } from 'next/image';

interface CustomImageProps extends NextImageProps {
  icon?: React.ReactNode;
  className?: string;
}

const GenericImage: React.FC<CustomImageProps> = (props) => {
  const { src, className, icon, alt, ...rest } = props;

  if (!src && icon) {
    return (
      <div className={className}>
        <span>{icon}</span>
      </div>
    );
  }

  return (
    <div className={className}>
      <NextImage src={src} alt={alt} {...rest} />
    </div>
  );
};

export default GenericImage;
