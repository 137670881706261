'use client';

import React, { useState } from 'react';
import classes from './carousel.module.scss';
import Conditional from '@next-app/components/Conditional';

interface CarouselProps {
  carouselId: string;
  children: React.ReactNode;
  dataBsRide?: boolean;
  dataBsWrap?: boolean;
  sliceData: any[];
  dataBsInterval?: number;
  dataBsKeyboard?: boolean;
  dataBsPause?: boolean;
  dataBstouch?: boolean;
}

const Carousel = ({
  carouselId,
  children,
  dataBsRide,
  dataBsWrap,
  sliceData,
  dataBsInterval,
  dataBsKeyboard,
  dataBsPause,
  dataBstouch,
}: CarouselProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const isAtStart = currentIndex === 0;
  const isAtEnd = currentIndex === sliceData.length - 1;

  const handlePrevClick = () => {
    if (!isAtStart) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (!isAtEnd) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <>
      <div
        id={`${carouselId}`}
        className={`carousel slide ${classes['carousel-main-wrp']} d-none d-lg-block`}
        data-bs-ride={dataBsRide}
        data-bs-wrap={dataBsWrap}
        data-bs-interval={dataBsInterval}
        data-bs-keyboard={dataBsKeyboard}
        data-bs-pause={dataBsPause}
        data-bs-touch={dataBstouch}
      >
        <div className="carousel-inner">{children}</div>
        <Conditional
          if={
            sliceData.length > 1 && sliceData[0]?.length >= sliceData.length - 1
          }
        >
          <div>
            <button
              className={`carousel-control-prev ${isAtStart ? classes.prev_disabled : classes.prev}`}
              type="button"
              data-bs-target={`#${carouselId}`}
              data-bs-slide="prev"
              onClick={handlePrevClick}
            >
              <span
                className={`carousel-control-prev-icon ${classes['carousel-prev']}`}
                aria-hidden="true"
              ></span>
            </button>
            <button
              className={`carousel-control-next ${isAtEnd ? classes.next_disabled : classes.next}`}
              type="button"
              data-testid="next-btn"
              data-bs-target={`#${carouselId}`}
              data-bs-slide="next"
              onClick={handleNextClick}
            >
              <span
                className={`carousel-control-next-icon`}
                aria-hidden="true"
              ></span>
            </button>
          </div>
        </Conditional>
      </div>
      <div className={`${classes['mobile-slider']} d-lg-none`}>
        <div className={classes['mobile-slider-inner']}>{children}</div>
      </div>
    </>
  );
};

export default Carousel;
