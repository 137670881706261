import Facets from '../../Facets/Facets';
import {
  ICategoryNavRefinementMenuProps,
  IRefinement,
} from '@next-app/interface/PLP/index';
import Subcategory from '../../Facets/Subcategory';

const CategoryNavRefinementMenu = (props: ICategoryNavRefinementMenuProps) => {
  const {
    contentItem,
    setFilterCount,
    setUpdatedUrl,
    getFilterLabel,
    isFilter,
    setIsFilter,
    setFilterLabel,
    filterLabel,
    setSelectedFilter,
    assortmentTitle,
  } = props;
  const hasChild =
    (contentItem?.displayName === 'subcategory' ||
      contentItem?.childDimensionName !== 'subcategory') &&
    contentItem?.refinements?.some(
      (ele: IRefinement) =>
        ele.properties?.hasSubCategory === 'true' ||
        ele.properties?.isParentCategory === 'true',
    );

  return (
    <div>
      {contentItem?.childDimensionName !== 'subcategory' && !hasChild ? (
        <Facets
          item={contentItem}
          setFilterCount={setFilterCount}
          isCheckbox={false}
          setUpdatedUrl={setUpdatedUrl}
          getFilterLabel={getFilterLabel}
          setIsFilter={setIsFilter}
          isFilter={isFilter}
          setFilterLabel={setFilterLabel}
          filterLabel={filterLabel}
          setSelectedFilter={setSelectedFilter}
        />
      ) : (
        <Subcategory
          contentItem={contentItem}
          setFilterCount={setFilterCount}
          setUpdatedUrl={setUpdatedUrl}
          getFilterLabel={getFilterLabel}
          setIsFilter={setIsFilter}
          isFilter={isFilter}
          setFilterLabel={setFilterLabel}
          filterLabel={filterLabel}
          setSelectedFilter={setSelectedFilter}
          assortmentTitle={assortmentTitle}
        />
      )}
    </div>
  );
};

export default CategoryNavRefinementMenu;
