'use client';
import { makeFetchCall } from '@next-app/utils/fetch-data';
import { useEffect, useState, memo } from 'react';
import Modal from '../Modal/Modal';
import classes from './HTMLContentModal.module.scss';
interface ISeeDetailsModal {
  url: string;
  modalTitle: string;
  modalId: string;
}

const HTMLContentModal = (props: ISeeDetailsModal) => {
  const { url, modalTitle, modalId } = props;

  const [modalData, setModalData] = useState('');
  useEffect(() => {
    async function openModal(url: string) {
      const apiData = await makeFetchCall({
        cache: 'force-cache',
        endpoint: `${url}`,
      });

      setModalData(() => {
        try {
          if (apiData?.contents[0]?.mainContent[0]?.content !== undefined) {
            return (
              apiData?.contents[0]?.mainContent[0]?.content[0]?.contents[0]
                ?.content || '<></>'
            );
          } else {
            return (
              apiData?.contents[0]?.mainContent[0]?.contents[0]?.content ||
              '<></>'
            );
          }
        } catch {
          return '<></>';
        }
      });
    }

    if (url !== '' && url !== undefined) {
      openModal(url);
    }
  }, [url]);
  return (
    <Modal
      showHeader={true}
      id={modalId}
      dialogClass={`modal-dialog eyebrow-modal-wrapper modal-dialog-centered align-items-center ${classes['modal-dialog-wrap']}`}
      contentClass={`modal-content`}
      headingTagName="4"
      footerClass="modal-footer"
      modalTitle={modalTitle}
      primaryButtonName="Submit"
      titleClass={classes['modal-title']}
      wrapperClass={classes['eyebrow-modal-main']}
    >
      <div
        className={classes['modal-body-wrap']}
        dangerouslySetInnerHTML={{ __html: modalData }}
      ></div>
    </Modal>
  );
};

export default memo(HTMLContentModal);
