import { findComponent } from '@next-app/utils';
import { HeaderNavigationContent } from '@next-app/interface/Navigation';
import classes from './navigation.module.scss';

interface PageContentNavigationProps {
  contentItem: HeaderNavigationContent;
}

const Navigation = ({ contentItem }: PageContentNavigationProps) => {
  const { navigationLeft, navigationRight } = contentItem;
  const NavigationLeftComponent = findComponent(navigationLeft);
  const NavigationRightComponent = findComponent(navigationRight);

  if (!NavigationLeftComponent || !NavigationRightComponent) {
    return null;
  }
  const attributes = {
    s_regionid: 'Nav-Main',
  };

  return (
    <>
      <div className="mobile-menu">
        {/* Mobile Nav goes here */}
        <nav
          id="mp-menu"
          className={`${classes['mp-menu']} dynamic-mobile-nav mobile-new-nav mp-cover d-block d-lg-none`}
          {...attributes}
        >
          <div
            className={`${classes['mp-menu-level-open']} mp-level mp-level-open`}
            data-level="1"
          >
            <NavigationLeftComponent
              contentItem={navigationLeft}
              navigationRight={navigationRight}
              mobileNavigation={true}
            />
          </div>
        </nav>
      </div>

      <div>
        <nav
          id="nav"
          className={`hover ${classes.navMenu} d-lg-block d-none`}
          {...attributes}
        >
          <div className="container">
            <div className="tight">
              <div className="nav-left d-flex gap-5 justify-content-center">
                <NavigationLeftComponent
                  contentItem={navigationLeft}
                  mobileNavigation={false}
                />
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navigation;
