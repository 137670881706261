'use client';

import classes from './product-card.module.scss';

const MoveToTop = () => {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div
      data-testid="back-to-top-button"
      id="back-to-top"
      className={`${classes['back-to-top-footer']} ${classes['back-to-top']}`}
      onClick={handleClick}
    >
      <i className={`ls-icon ${classes['icn-back-to-top']}`}></i>
      <span className={classes['product-resultList-footer-button']}></span>
    </div>
  );
};

export default MoveToTop;
