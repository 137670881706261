'use client';
import parse from 'html-react-parser';
import Heading from '@next-app/components/elements/Heading/Heading';
import CarouselPricingDisplay from './CarouselPricingDisplay';
import classes from './product-recs-HTML.module.scss';
import constants from '@next-app/constants/constants';
import RatingRecs from './RatingRecs';
import { ProductRecsContentItemData } from '@next-app/interface/PageContent';
import Image from 'next/image';
import ButtonComponent from '../../../PDP/PDPCarousels/PDPCarouselButton';
import { useState } from 'react';
import Conditional from '@next-app/components/Conditional';
import { isPagePDP } from '@next-app/utils/isPagePDP';
import { getUniqueKey } from '@next-app/utils';

interface HTMLRecommendationsProps {
  atcRecsEnabled: boolean;
  gsaUser: boolean;
  sliceData: [ProductRecsContentItemData][];
  sessionId: string;
  page: string;
  s_regionId?: string;
}

const HTMLRecommendations = ({
  atcRecsEnabled,
  gsaUser,
  sliceData,
  page,
  s_regionId,
}: HTMLRecommendationsProps) => {
  const { STORE_PICKUP_ONLY, STORE_SPECIAL_LABEL } = constants.LLConstants;
  const [currentIndex, setCurrentIndex] = useState(0);

  const isAtStart = currentIndex === 0;
  const isAtEnd = currentIndex === sliceData.length - 1;

  const handlePrevClick = () => {
    if (!isAtStart) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (!isAtEnd) {
      setCurrentIndex(currentIndex + 1);
    }
  };
  const carouselId = getUniqueKey(1, 'carouselControls');
  const elementAttribute = {
    s_regionid: s_regionId,
  };

  return (
    <div className={classes['slider-items']}>
      <div
        id={`${carouselId}`}
        className="carousel slide"
        data-bs-ride="false"
        data-bs-wrap="false"
      >
        <div className="carousel-inner">
          {sliceData?.map(
            (elements: [ProductRecsContentItemData], index: number) => {
              return (
                <div
                  className={`carousel-item ${index === 0 ? 'active' : ''}`}
                  key={index}
                  {...elementAttribute}
                >
                  <div className="row justify-content-center">
                    <div className={`${classes.item} col-lg-10`}>
                      {elements?.map(
                        (item: ProductRecsContentItemData, index: number) => (
                          <div
                            className={classes.cardItem}
                            key={`rec-item-${index}`}
                          >
                            <div className={classes['product-content']}>
                              <a
                                href={item?.seoURL}
                                className={classes['product-img']}
                                {...{ s_objectid: item?.name }}
                              >
                                <Image
                                  src={item?.imageURL}
                                  alt={item?.altText}
                                  fill={true}
                                  quality={100}
                                  className="position-static"
                                />
                                {item.storeOnly && (
                                  <div className={classes['new-item-label']}>
                                    {item.isNew && item.storeOnly && (
                                      <span className={classes['new-icon']}>
                                        New
                                      </span>
                                    )}
                                    {!item.storeOnly && (
                                      <span className={classes.blue}>
                                        {item.storeOnly
                                          ? STORE_PICKUP_ONLY
                                          : '&nbsp;'}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </a>
                              <Heading
                                tagName="5"
                                customClass={classes['product-name']}
                              >
                                <a href={item?.seoURL}>
                                  {item?.isNew && !item?.storeOnly && (
                                    <span className={classes['new-icon']}>
                                      New
                                    </span>
                                  )}
                                  {parse(item?.name)}
                                </a>
                              </Heading>
                              <CarouselPricingDisplay
                                hasSpecialPrice={item.hasSpecialPrice}
                                isOnSale={item.isOnSale}
                                hasGSAPrice={item.hasGSAPrice}
                                salePriceRange={item.salePriceRange}
                                listPriceRange={item.listPriceRange}
                                isOnGSA={item.isOnGSA}
                                atcRecsEnabled={atcRecsEnabled}
                              />
                              {item.specialStoreText && !gsaUser && (
                                <div className={classes['spacial-label']}>
                                  <span
                                    className={`${classes.red} ${classes.fs11}`}
                                  >
                                    {STORE_SPECIAL_LABEL}
                                  </span>
                                </div>
                              )}
                            </div>

                            <div className={classes['rating-slider']}>
                              <RatingRecs
                                rating={item.rating}
                                reviewCount={item.reviewCount}
                                seoURL={item.seoURL}
                                atcRecsEnabled={atcRecsEnabled}
                                showReviewCount={isPagePDP(page)}
                              />
                            </div>
                            <Conditional if={isPagePDP(page)}>
                              <ButtonComponent product={item} />
                            </Conditional>
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                </div>
              );
            },
          )}
        </div>
        <Conditional if={sliceData[0]?.length >= 4}>
          <div>
            <button
              className={`carousel-control-prev ${isAtStart ? classes.prev_disabled : classes.prev}`}
              type="button"
              data-bs-target={`#${carouselId}`}
              data-bs-slide="prev"
              onClick={handlePrevClick}
            >
              <span
                className={`carousel-control-prev-icon`}
                aria-hidden="true"
              ></span>
            </button>
            <button
              className={`carousel-control-next ${isAtEnd ? classes.next_disabled : classes.next}`}
              type="button"
              data-bs-target={`#${carouselId}`}
              data-bs-slide="next"
              onClick={handleNextClick}
            >
              <span
                className={`carousel-control-next-icon`}
                aria-hidden="true"
              ></span>
            </button>
          </div>
        </Conditional>
      </div>
    </div>
  );
};

export default HTMLRecommendations;
