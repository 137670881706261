'use client';
import { useState } from 'react';
import HTMLContentModal from '@next-app/components/elements/HTMLContentModal/HTMLContentModal';
import Link from '@next-app/components/elements/Link/Link';
import ResponsiveImageClient from '@next-app/components/elements/ResponsiveImageClient/ResponsiveImageClient';

const BannerSeeDetailsModal = (props: any) => {
  const [modalUrl, setModalUrl] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  function handleClick() {
    setModalTitle(props.title);
    setModalUrl(props.url);
  }

  return (
    <>
      <Link
        className="display-block no-decor"
        href={props.imageLink}
        s_objectID={props.sObjectId}
        {...(props.url && {
          'data-bs-target': '#BannerSeeDetailModal',
          'data-bs-toggle': 'modal',
        })}
        onClick={() => {
          handleClick();
        }}
      >
        <ResponsiveImageClient
          mobileImagePath={props.mobileImageURL}
          tabletImagePath={props.imageURL}
          desktopImagePath={props.imageURL}
          altText={props.imageAlt || ''}
          width={1500}
          height={170}
          isFullUrl={props.isFullUrl}
          lazyload={false}
        />
      </Link>
      {/* <Conditional if={isModalOpen}> */}
      <HTMLContentModal
        url={modalUrl}
        modalTitle={modalTitle}
        modalId="BannerSeeDetailModal"
      />
      {/* </Conditional> */}
    </>
  );
};

export default BannerSeeDetailsModal;
