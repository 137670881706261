import Facets from '../../Facets/Facets';
import { ISimpleRefinementMenuProps } from '@next-app/interface/PLP';

const SimpleRefinementMenu = (props: ISimpleRefinementMenuProps) => {
  const {
    contentItem,
    setFilterCount,
    setUpdatedUrl,
    getFilterLabel,
    isFilter,
    setIsFilter,
    setFilterLabel,
    filterLabel,
    setSelectedFilter,
  } = props;

  return (
    <Facets
      item={contentItem}
      setFilterCount={setFilterCount}
      setUpdatedUrl={setUpdatedUrl}
      getFilterLabel={getFilterLabel}
      setIsFilter={setIsFilter}
      isFilter={isFilter}
      setFilterLabel={setFilterLabel}
      filterLabel={filterLabel}
      setSelectedFilter={setSelectedFilter}
    />
  );
};

export default SimpleRefinementMenu;
