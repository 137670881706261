'use client';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Card from '../AccountMenuLink/StoreCard';
import Button from '@next-app/components/elements/Button/Button';
import classes from './row-image.module.scss';
import Conditional from '@next-app/components/Conditional';
import Link from '@next-app/components/elements/Link/Link';
import { RowImageProps } from '@next-app/interface/PageContent';

const RowImage = (props: RowImageProps) => {
  const { displayBottomTextAsTitle } = props;
  const {
    bottomTextHtml,
    displayButton,
    checkStore,
    ctaBtnText,
    ctaBtnPosition,
    href,
    s_objectId,
    s_regionId,
  } = props.contentItem;

  const { sessionInfo } = useSelector((state: any) => state.init);
  const [isSelectedStore, setIsSelectedStore] = useState(false);
  const [zipCode, setZipcode] = useState('');
  const [data, setData] = useState([]);
  const [locations, setLocation] = useState({});
  const [locationError, setLocationError] = useState(false);
  const [storeUserMessage, setStoreUserMessage] = useState({});
  const [showButton, setShowButton] = useState(false);
  const [cardProps, setCardProps] = useState({
    cartId: '',
    profileId: '',
    storeId: '',
    storeName: '',
    storeState: '',
    zipCode: '',
    country: '',
    data: [],
    showFindStoreModal: false,
    locations: {},
    locationError: false,
    storeUserMessage: {},
  });
  const [show, setShow] = useState<boolean>(false);
  const [showFindStoreModal, setShowFindStoreModal] = useState<boolean>(false);

  let checkStoreVal: boolean = checkStore || false;

  useEffect(() => {
    if (sessionInfo?.sessionInfo) {
      // display button only after session info is resolved
      // session info decides whether button will open modal or redirect
      setShowButton(true);

      const {
        profile: { selectedStore, id, address },
        cartInfo: { cartId },
      } = sessionInfo.sessionInfo;
      let country = '';
      if (address && address?.country) {
        country = address?.country;
      }

      setCardProps({
        cartId,
        profileId: id,
        storeId: '',
        storeName: '',
        storeState: '',
        zipCode,
        country,
        data,
        showFindStoreModal,
        locations,
        locationError,
        storeUserMessage,
      });

      const isStore = Object.keys(selectedStore).length > 0;
      setIsSelectedStore(isStore);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionInfo?.sessionInfo]);

  if (href.indexOf('selectStoreThen:') !== -1) {
    checkStoreVal = true;
    href(href.replace('selectStoreThen:', ''));
  }

  const needsToSelectStore = checkStoreVal && !isSelectedStore;

  let elemAttributes = {
    href: '',
    target: '',
    s_objectid: '',
    s_regionid: s_regionId || '',
  };

  if (!needsToSelectStore && href) {
    elemAttributes.href = href;
    if (href.charAt(0) !== '/') {
      elemAttributes.target = '_blank';
    }
  }

  if (s_objectId) {
    elemAttributes['s_objectid'] = s_objectId;
  }

  function getLocation() {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject(new Error('Geolocation is not supported by your browser'));
      } else {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          },
          (error) => {
            reject(error);
          },
        );
      }
    });
  }

  const handleClose = () => {
    setShow(false);
    setShowFindStoreModal(false);
  };

  const handleShow = async () => {
    try {
      setShow(true);
      setShowFindStoreModal(true);
      const resp: any = await getLocation();
      setLocation({ longitude: resp?.longitude, latitude: resp?.latitude });
    } catch (error: any) {
      setLocationError(true);
    }
  };

  return (
    <div
      className={`position-relative round-border ${bottomTextHtml && !displayBottomTextAsTitle ? 'border' : ''}`}
    >
      <Conditional if={needsToSelectStore}>
        <>
          <span
            className="d-block w-100"
            onClick={handleShow}
            data-bs-toggle="modal"
            data-bs-target="#findAnotherStoreRowImage"
            {...elemAttributes}
          >
            {props.children}
          </span>
          <div className={classes['action-btn-main']}>
            <Button
              data-bs-toggle="modal"
              data-bs-target="#findAnotherStoreRowImage"
              data-testid="handle-show"
              type="button"
              variant="transparent"
              onClick={handleShow}
              customClass={classes['action-btn']}
            >
              {ctaBtnText}
            </Button>
          </div>
        </>
      </Conditional>
      <Conditional if={!needsToSelectStore}>
        <>
          <Conditional if={displayButton}>
            <>
              {ctaBtnText !== '' && (
                <div className="cta-image">
                  <Link {...elemAttributes}> {props.children}</Link>
                  <div
                    className={`${classes['action-btn-main']} ${ctaBtnPosition}`}
                  >
                    <Conditional
                      if={showButton && ctaBtnText !== 'Check Your Balance'}
                    >
                      <Link
                        {...elemAttributes}
                        className={`${classes['action-btn']} ${classes['link-btn']}`}
                      >
                        {ctaBtnText}
                      </Link>
                    </Conditional>
                  </div>
                </div>
              )}
            </>
          </Conditional>

          <Conditional if={!displayButton && href !== ''}>
            <Link className="d-block w-100 h-auto" {...elemAttributes}>
              {props.children}
            </Link>
          </Conditional>

          <Conditional if={!displayButton && href === ''}>
            <span className="d-block w-100 h-auto" {...elemAttributes}>
              {props.children}
            </span>
          </Conditional>
        </>
      </Conditional>

      <Conditional if={needsToSelectStore && show}>
        <Card
          portalId="findAnotherStoreRowImage"
          setShow={setShow}
          setZipcode={setZipcode}
          setData={setData}
          handleClose={handleClose}
          setStoreUserMessage={setStoreUserMessage}
          {...cardProps}
        />
      </Conditional>
    </div>
  );
};

export default RowImage;
