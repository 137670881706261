'use client';

import {
  IMakeStoreDTMEvent,
  IPageLoadDTMEvent,
  ICreateAccountDTMEvent,
  ILoginAccountDTMEvent,
  IEmailSignUpDTMEvent,
  IProductCardDTMEvent,
} from '@next-app/interface/DTMEvent/index';

export function isPLPPageUrl(pUrl: string) {
  if (pUrl) {
    return (
      pUrl == '/sale' ||
      pUrl.indexOf('/sale/') !== -1 ||
      /\/N\/[0-9]+/.test(pUrl)
    );
  }

  return false;
}

export function isSkuPageUrl(pUrl: string) {
  if (pUrl) {
    return /\/s\/[A-Z0-9]+/.test(pUrl);
  }

  return false;
}

export const MakeStoreDTMEvent = async (data: IMakeStoreDTMEvent) => {
  const { id, city, state, internalAPI, ...rest } = data;
  const body = {
    event: 'selectStoreEvent',
    detail: {
      storeId: id,
      storeName: city,
      storeState: state,
      llmAPIVersion: internalAPI,
      ...rest,
    },
  };

  if (window.sendDTMEvent) {
    window.sendDTMEvent(body);
  }

  return null;
};

export const PageLoadDTMEvent = (data: IPageLoadDTMEvent) => {
  const {
    storeSelected,
    llmAPIVersion,
    email,
    profileId,
    isGSAUser,
    userShopFor,
    isFurniture,
    md5Email,
    rewardsUserStatus,
    orderId,
    cartCount,
    recognized,
    securityStatus,
    currentUrl = '/',
    pageTitle,
    pageName,
    ...rest
  } = data;
  const body = {
    event: 'pageLoadData',
    detail: {
      pageUrl: currentUrl,
      md5Email: md5Email,
      storeSelected: storeSelected,
      llmAPIVersion: llmAPIVersion,
      email: email,
      profileId: profileId,
      isGSAUser: isGSAUser,
      isFurniture: isFurniture,
      userShopFor: userShopFor,
      rewardsUserStatus: rewardsUserStatus,
      orderId: orderId,
      cartCount: cartCount,
      recognized: recognized,
      securityStatus: securityStatus,
      findingMethod: isPLPPageUrl(currentUrl) ? 'browse' : '',
      browseFindingMethod:
        currentUrl.indexOf('/l/') !== -1
          ? 'Landing Page'
          : isPLPPageUrl(currentUrl) ||
              currentUrl.indexOf('/featured-assortments/') > -1
            ? 'Global Nav'
            : '',
      // SEO data
      pageTitle,
      pageName,
      ...rest,
    },
  };

  if (window.sendDTMEvent) {
    window.sendDTMEvent(body);
  }

  return null;
};

export const CreateAccountDTMEvent = async (data: ICreateAccountDTMEvent) => {
  const {
    userShopFor,
    lowerEnv,
    email,
    staySignedIn,
    internalAPI,
    furniture,
    md5Email,
    encryptedOrderId,
    orderId,
  } = data;

  const body = {
    event: 'createAccount',
    detail: {
      fromPage: 'global',
      staySignedIn: staySignedIn,
      email: email,
      userShopFor: userShopFor,
      isLowerEnvironment: lowerEnv,
      md5Email: md5Email,
      isFurniture: furniture,
      llmAPIVersion: internalAPI,
      encryptedOrderId: encryptedOrderId,
      orderId,
    },
  };

  if (window.sendDTMEvent) {
    window.sendDTMEvent(body);
  }

  return null;
};

export const LoginAccountDTMEvent = async (data: ILoginAccountDTMEvent) => {
  const {
    userShopFor,
    lowerEnv,
    email,
    staySignedIn,
    md5Email,
    encryptedOrderId,
    isGsaUser,
    lastName,
    dateOfBirth,
    firstName,
    orderId,
  } = data;

  const body = {
    event: 'loginAccount',
    detail: {
      fromPage: 'Global_Header',
      staySignedIn: staySignedIn,
      userShopFor: userShopFor,
      isLowerEnvironment: lowerEnv,
      encryptedOrderId: encryptedOrderId,
      md5Email: md5Email,
      orderId: orderId,
      profileDetails: {
        lastName: lastName,
        email: email,
        dateOfBirth: dateOfBirth,
        firstName: firstName,
        isGsaUser: isGsaUser,
      },
    },
  };

  if (window.sendDTMEvent) {
    window.sendDTMEvent(body);
  }

  return null;
};

export const EmailSignUpDTMEvent = async (data: IEmailSignUpDTMEvent) => {
  const { userShopFor, lowerEnv, email, encryptedOrderId, orderId, pageTitle } =
    data;

  const body = {
    event: 'emailSignUp',
    detail: {
      page: pageTitle,
      email,
      userShopFor,
      isLowerEnvironment: lowerEnv,
      encryptedOrderId,
      orderId,
      fromPage: 'Lightbox',
    },
  };

  if (window.sendDTMEvent) {
    window.sendDTMEvent(body);
  }

  return null;
};

export const ProductCardDTMEvent = async (data: IProductCardDTMEvent) => {
  const body = {
    event: 'browseClick',
    detail: {
      ...data,
    },
  };

  if (window.sendDTMEvent) {
    window.sendDTMEvent(body);
  }

  return null;
};

export const ContactFormDTMEvent = async (data: any) => {
  const body = {
    ...data,
  };

  if (window.sendDTMEvent) {
    window.sendDTMEvent(body);
  }

  return null;
};

export const DynamicDTMEvent = async (data: any) => {
  const body = {
    ...data,
  };

  if (window.sendDTMEvent) {
    window.sendDTMEvent(body);
  }

  return null;
};
