import { ISkeletonUI } from '@next-app/interface/SkeletonUI';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonUI = ({
  width,
  height,
  count,
  classes,
  containerClass,
}: ISkeletonUI) => {
  return (
    <Skeleton
      width={width || 100}
      height={height || 100}
      count={count || 1}
      className={classes || ''}
      containerClassName={containerClass || ''}
    />
  );
};

export default SkeletonUI;
