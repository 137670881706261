interface DY_event {
  eventName?: string;
  type?: string;
  email?: string | undefined;
  properties?: any;
}

const dyEvent = (data: DY_event) => {
  // eslint-disable-next-line no-undef
  if (window && window?.DY && window?.DY?.API) {
    window?.DY?.API('event', {
      name: data?.eventName,
      properties: data?.properties || {
        dyType: data?.type,
        // eslint-disable-next-line no-undef
        hashedEmail: window.DYO.dyhash.sha256(data?.email?.toLowerCase()),
      },
    });
  }
  return;
};

export { dyEvent };
