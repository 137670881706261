'use client';

import Conditional from '@next-app/components/Conditional';
import classes from './product-card.module.scss';
import { useSelector } from 'react-redux';
import SkeletonUI from '@next-app/components/Skeleton/Skeleton';
import { isNotEmptyObject } from '../ProductDetails/helper';
import { IProductStorePickup } from '@next-app/interface/PLP/AssortmentListing';
import constants from '@next-app/constants/constants';

const StorePickup = ({ product, isStoreSku = false }: IProductStorePickup) => {
  const { sessionInfo } = useSelector((state: any) => state.init);
  const {
    sessionInfo: {
      profile: { gsaUser = false, selectedStore: { id = '' } = {} } = {},
    } = {},
  } = sessionInfo || {};
  const isSpecial =
    product?.attributes?.storeIdsWithStock?.includes(id) &&
    product.attributes?.isDoorbusterProduct == '1';
  return (
    <>
      <Conditional if={!isNotEmptyObject(sessionInfo)}>
        <SkeletonUI width={95} height={10} />
      </Conditional>
      <Conditional if={isNotEmptyObject(sessionInfo) && isSpecial}>
        <div className={`${classes['store-pricing']}`}>
          {constants.LLConstants.STORE_SPECIAL_LABEL}
        </div>
      </Conditional>
      <Conditional
        if={isNotEmptyObject(sessionInfo) && isStoreSku && !!id && !gsaUser}
      >
        <div className={`${classes['store-pickup']}`}>
          <strong>FREE </strong> Store Pickup
        </div>
      </Conditional>
    </>
  );
};

export default StorePickup;
