import SelectStore from '../../Facets/SelectStore';
import { ILocationRefinementMenuProps } from '@next-app/interface/PLP';

const LocationRefinementMenu = (props: ILocationRefinementMenuProps) => {
  return (
    <SelectStore contentItem={props?.contentItem} isMobileLocationNav={false} />
  );
};

export default LocationRefinementMenu;
