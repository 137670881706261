import {
  ComponentMap,
  ComponentMapItem,
  IPLPPageOneColumn,
} from '@next-app/interface/PLP';

export const mapDynamicObject = (
  type: string,
  item: ComponentMapItem,
  mapObject: ComponentMap,
) => {
  switch (type) {
    case 'PageSlot':
    case 'PageContent-Slot':
      return processContent(item.contents, mapObject);
    case 'Page-OneColumn':
      return processContent(item.mainContent, mapObject);
    default:
      return null;
  }
};

const processContent = (
  contentArray: IPLPPageOneColumn[],
  mapObject: ComponentMap,
) => {
  contentArray.forEach((item: any) => {
    const val = mapDynamicObject(item['@type'], item, mapObject);
    if (val === null) {
      mapObject[item['@type']] = item;
    }
  });
};
