import classes from './certification-icons.module.scss';
import Image from 'next/image';
import Link from '../elements/Link/Link';
import parse from 'html-react-parser';
import Script from 'next/script';

export default function CertificationIcons(props: any) {
  if (typeof props.contentItem.content === 'string') {
    const jsonContent = JSON.parse(props.contentItem.content);

    return jsonContent?.certification?.map((el: any, key: any) => {
      const linkURLS: any = parse(el.linkURL);

      return !el.id ? (
        <li key={key} className={classes.certificateImage}>
          <Link data-redirect={false} href={linkURLS} target={el.target}>
            <Image
              width={70}
              height={34}
              src={el.imageSource}
              alt={el.altText}
              quality={100}
              loading={'lazy'}
            />
          </Link>
        </li>
      ) : (
        <li className={classes.certificateImage}>
          <div id="DigiCertClickID_68e1Rwhq"></div>
          <Script
            id="digicert-script"
            strategy="afterInteractive"
            type="text/javascript"
          >
            {`
                var __dcid = __dcid || [];
                  __dcid.push({"cid":"DigiCertClickID_68e1Rwhq","tag":"68e1Rwhq","seal_format":"dynamic"});
                  (function(){var cid=document.createElement("script");cid.async=true;cid.src="//seal.digicert.com/seals/cascade/seal.min.js";var s = document.getElementsByTagName("script");var ls = s[(s.length - 1)];ls.parentNode.insertBefore(cid, ls.nextSibling);}());
            `}
          </Script>
        </li>
      );
    });
  }
  return <span className="hidden">{props.contentItem.content}</span>;
}
