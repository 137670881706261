'use client';

import React, { useState } from 'react';
import Input from '@next-app/components/elements/Input/Input';
import Button from '@next-app/components/elements/Button/Button';
import Heading from '@next-app/components/elements/Heading/Heading';
import Modal from '../elements/Modal/Modal';
import GenericImage from '@next-app/components/elements/GenericImage/GenericImage';
import { useSelector } from 'react-redux';
import { checkBalance } from '../../utils/checkBalance';
import classes from './check-balance-modal.module.scss';
import Conditional from '@next-app/components/Conditional';
import giftCardInstructions from '../../../public/assets/images/gift-card-instructions.jpg';
import eGiftCardInstructions from '../../../public/assets/images/e-gift-card-instructions.avif';

const giftCardInitalData = {
  giftCardId: '',
  authorizationId: '',
  balance: '',
};

const initialErrorData = {
  giftCardId: '',
  authorizationId: '',
  errorMessage: '',
};

const ProductCheckBalanceModal = () => {
  const [giftCardData, setGiftCardData] = useState(giftCardInitalData);
  const [errors, setErrors] = useState(initialErrorData);
  const [needHelpSection, setNeedHelpSection] = useState(false);
  const { sessionInfo } = useSelector((state: any) => state.init);

  if (!Object.keys(sessionInfo).length) {
    return;
  }

  const dynSessConfNumber =
    sessionInfo?.sessionInfo?.dynSess?.dynSessConfNumber;

  const handleOnChnage = (value: string, field: string) => {
    const replacedValue = value.replace(/\D/g, '');
    setGiftCardData((prev) => ({
      ...prev,
      [field]: replacedValue,
    }));
  };

  const validate = () => {
    let errors = {
      giftCardId: '',
      authorizationId: '',
      errorMessage: '',
    };

    if (!giftCardData.giftCardId)
      errors.giftCardId = 'Please enter gift card #.';
    if (!giftCardData.authorizationId)
      errors.authorizationId = 'Please enter the auth #.';

    setErrors(errors);

    const giftCardInput = document.getElementById('gift-card-input');
    const authInput = document.getElementById('auth-input');

    if (errors.giftCardId) {
      giftCardInput?.focus();
    } else if (errors.authorizationId) {
      authInput?.focus();
    }

    return Object.values(errors).every((error) => error === '');
  };

  const handleCheckBalance = async () => {
    setNeedHelpSection(false);
    if (validate()) {
      const checkBalanceResponse: any = await checkBalance({
        giftCardId: giftCardData.giftCardId,
        authorizationId: giftCardData.authorizationId,
        sessionConfirmationNumber: dynSessConfNumber,
      });

      if (checkBalanceResponse.errorMessage) {
        setErrors((prev) => ({
          ...prev,
          errorMessage: checkBalanceResponse.errorMessage,
        }));
      }
      if (checkBalanceResponse.errorGiftCardInvalidMessage) {
        setErrors((prev) => ({
          ...prev,
          errorMessage: checkBalanceResponse.errorGiftCardInvalidMessage,
        }));
      } else {
        setGiftCardData((prev) => ({
          ...prev,
          ['balance']: checkBalanceResponse.giftCardBalance,
        }));
      }
    }
  };

  const needHelpSectionHandler = () => {
    setNeedHelpSection(true);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleCheckBalance();
    }
  };

  const restValueHandler = () => {
    setNeedHelpSection(false);
    setErrors(initialErrorData);
    setGiftCardData(giftCardInitalData);
  };

  let checkBalanceModal = document.getElementById('checkBalanceModal');
  checkBalanceModal?.addEventListener('hidden.bs.modal', function () {
    restValueHandler();
  });

  return (
    <Modal
      showHeader={true}
      modalTitle="Check Balance"
      id="checkBalanceModal"
      dialogClass={`modal-dialog ${classes['modal-dialog']}`}
      contentClass="modal-content"
      headingTagName="4"
      closeModalOnClick={() => {
        setNeedHelpSection(false);
        setErrors(initialErrorData);
        setGiftCardData(giftCardInitalData);
      }}
      wrapperClass={`${classes['check-balance-modal']}`}
    >
      <div className="row">
        {errors.errorMessage && (
          <span className={`${classes['top-error-message']}`}>
            {errors.errorMessage}
          </span>
        )}
        <div className={`col-lg-7 mt-2`}>
          <Input
            data-testid="input-gift-card"
            labelText="Gift Card #"
            type="text"
            customClass={`${classes['gift-card-input']} ${errors.giftCardId || errors.errorMessage ? classes['error-msg'] : ''}`}
            onChange={(e) => handleOnChnage(e.target.value, 'giftCardId')}
            value={giftCardData.giftCardId}
            maxLength={16}
            id="gift-card-input"
            onKeyDown={handleKeyDown}
          ></Input>
          {errors.giftCardId && (
            <span className={`${classes['error-message']}`}>
              {errors.giftCardId}
            </span>
          )}
        </div>
        <div className={`col-lg-5 mt-2`}>
          <Input
            datatestid="auth-input"
            labelText="Authorization #"
            type="text"
            id="auth-input"
            customClass={`${classes['authorization-input']} ${errors.authorizationId || errors.errorMessage ? classes['error-msg'] : ''}`}
            onChange={(e) => handleOnChnage(e.target.value, 'authorizationId')}
            value={giftCardData.authorizationId}
            maxLength={4}
            onKeyDown={handleKeyDown}
          ></Input>
          {errors.authorizationId && (
            <span className={`${classes['error-message']}`}>
              {errors.authorizationId}
            </span>
          )}
        </div>
      </div>
      <div className={classes['row']}>
        <div>
          <span
            className={`${classes['need-help']}`}
            onClick={needHelpSectionHandler}
          >
            Need Help?
          </span>
        </div>
        <div>
          <Button
            datatestid="check-balance-button"
            onClick={handleCheckBalance}
          >
            {' '}
            Check Balance
          </Button>
        </div>
      </div>

      {giftCardData.balance && (
        <div className={`${classes['balance-wrap']}`}>
          <Heading customClass="" tagName="3">
            {' '}
            Balance :{' '}
            <span className={`${classes['green']}`}>
              ${giftCardData.balance}{' '}
            </span>{' '}
          </Heading>
        </div>
      )}

      <Conditional if={needHelpSection}>
        <div className={`${classes['product-need-help-wrap']}`}>
          <Heading customClass={`${classes['gift-card-title']}`} tagName="2">
            Classic Gift Card
          </Heading>
          <GenericImage
            src={giftCardInstructions}
            alt="gift-card-instructions"
          />
          <Heading customClass={`${classes['gift-card-title']}`} tagName="2">
            E-Gift Card Email
          </Heading>
          <GenericImage
            src={eGiftCardInstructions}
            alt="eGiftCardInstructions"
          />
        </div>
      </Conditional>
    </Modal>
  );
};

export default ProductCheckBalanceModal;
