interface UIClassNames {
  ulClass: string;
}

export default function getUlClassNames(item: UIClassNames) {
  if (!item.ulClass) {
    return null;
  } else {
    return item.ulClass;
  }
}
