import InjectDynamicYield from '@next-app/script/InjectDynamicYield';
import classes from './strict-category-listing.module.scss';

const StrictCategoryListingTitle = ({ contentItem }: any) => {
  const {
    label = '',
    count = 0,
    properties,
    assortmentTitle = '',
  } = contentItem?.mainDimension || {};

  let page, dyCategory;

  if (assortmentTitle) {
    dyCategory = assortmentTitle.split('|')?.[0]?.trim();
    if (dyCategory === 'Lakeshore Learning') {
      let et = assortmentTitle.split('|')?.slice(0, 2);
      dyCategory = et.join('|').trim();
    }
    dyCategory = 'Assortment: ' + dyCategory;
  } else if (properties?.['category.repositoryId']) {
    page = 'CATEGORY';
    dyCategory = properties?.['category.repositoryId'];
  } else {
    dyCategory = 'Grade-Age: ' + label;
  }

  return (
    <>
      <InjectDynamicYield page={page} data={[dyCategory]} />
      <div className={classes['plp-product-header']}>
        <h1
          dangerouslySetInnerHTML={{
            __html: `${label} — ${count} items`,
          }}
        />
      </div>
    </>
  );
};

export default StrictCategoryListingTitle;
